import { nextTick, isRef } from 'vue';
import { createI18n }      from 'vue-i18n';

function isComposer( instance, mode )
{
    return mode === 'composition' && isRef( instance.locale );
}

export function hasDictionary( i18n, locale )
{
    return i18n.global.messages[ locale ];

    //return i18n.global.availableLocales.includes( locale ); TODO => NEVIEM ODKIAL NACO PRECO
}

export async function loadDictionary( i18n, locale )
{
    await loadLocaleMessages( i18n, locale );

    setI18nLanguage( i18n, locale );
}

export function getLocale( i18n )
{
    return isComposer( i18n.global, i18n.mode ) ? i18n.global.locale.value : i18n.global.locale;
}

export function setLocale( i18n, locale )
{
    return isComposer( i18n.global, i18n.mode ) ? i18n.global.locale.value = locale : i18n.global.locale = locale;
}

export function setupI18n( options = { locale: 'en' } )
{
    const i18n = createI18n( options );

    setI18nLanguage( i18n, options.locale );

    return i18n;
}

export function setI18nLanguage( i18n, locale )
{
    setLocale( i18n, locale )

    /**
    * NOTE:
    * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
    * The following is an example for axios.
    *
    * axios.defaults.headers.common['Accept-Language'] = locale
    */

    document.querySelector('html').setAttribute('lang', locale );
}

export async function loadLocaleMessages( i18n, locale )
{
    const [ dictionary, validations ] = await Promise.all([ import(`./locales/dictionary-${locale}.json`).then( ( r ) => r.default || r ), import(`./locales/validations-${locale}.json`).then( ( r ) => r.default || r ) ]);

    i18n.global.setLocaleMessage( locale, { ...dictionary, ...validations });

    return nextTick();
}
