//import store from "@/store";

import { Helpers } from "../core/utils";

function QuerystringExtend(data, querystring = [], prefix = '' )
{
    if( data === null )
    {
        querystring.push( prefix );
    }
    else if( typeof data === 'boolean' )
    {
        querystring.push( prefix + '=' + ( data ? '1' : '0' ));
    }
    else if( typeof data === 'number' || typeof data === 'string' )
    {
        querystring.push( prefix + '=' + encodeURIComponent( data.toString() ));
    }
    else if( Array.isArray( data ))
    {
        for( let i = 0; i < data.length; ++i )
        {
            QuerystringExtend( data[i], querystring, prefix /*+ '[' + i + ']'*/ );
        }
    }
    else if( typeof data === 'object' )
    {
        for( let key in data )
        {
            QuerystringExtend( data[key], querystring, prefix ? prefix + '[' + key + ']' : key );
        }
    }

    return querystring;
}

function Querystring( data )
{
    return QuerystringExtend( data ).join('&');
}

class EventsService
{

    #lastMsg = '';
    constructor( method, url, options ) 
    {
        this.method = method;
        this.url = url;
        this.options = options;
        this.readyState = 0; // 0: UNSENT
        this.listeners = 
        {
            open: [],
            message: [],
            error: [],
            complete: [],
            headers: []
        };

        this.#open();
    }

    #handleStreamData(responseText) 
    {
        this.#triggerEvent('message', { data: responseText });
    }

    close() 
    {
        if (this.xhr) 
        {
            this.xhr.abort();
            this.readyState = 4; // 4: CLOSED
        }
    }

    on( eventName, callback ) 
    {
        this.listeners[eventName].push( callback );
    }

    abort()
    {
        this.xhr.abort();
    }

    #triggerEvent( eventName, data ) 
    {
        this.listeners[eventName].forEach(callback => 
        {
            callback(data);
        } );
    }

    #open()
    {
        const { method, options } = this;
        
        const $_headers = {};

        const $_user         = Helpers.Storage( undefined, 'get', 'X-AccountID' );
        const $_organization = Helpers.Storage( undefined, 'get', 'X-OrganizationID' );

        $_user         && ( $_headers['X-AccountID'] = $_user );
        $_organization && ( $_headers['X-OrganizationID'] = $_organization );

        let headers =
        {
            'X-Requested-With'  : 'XMLHttpRequest',
            ...$_headers,
            ...options?.headers
        };

        if( options?.query )
        {
            this.url += '?' + Querystring( options.query );
        }

        if( options?.body && typeof options.body !== 'string' && !( options.body instanceof FormData ))
        {
            headers['Content-Type'] = 'application/json';
            options.body = JSON.stringify( options.body );
        }

        this.xhr = new XMLHttpRequest();
        
        this.xhr.onreadystatechange = () =>
        {
            if (this.xhr.readyState === this.xhr.HEADERS_RECEIVED) 
            {
                this.#triggerEvent('headers', this.xhr );
            }
            
            if (this.xhr.readyState === 3 && this.xhr.status === 200) 
            {
                this.#handleStreamData(this.xhr.responseText);
            }
            else if ( this.xhr.readyState === 4 && this.xhr.status === 200)
            {
                this.#handleStreamData(this.xhr.responseText);
                this.#triggerEvent('complete', this.xhr );
            }
            else if (this.xhr.readyState === 4)
            {
                this.readyState = 4; // 4: CLOSED
                this.#triggerEvent('error', this.xhr.responseText);
            }
        };

        this.xhr.open( method || 'GET', this.url, true);
        this.xhr.withCredentials = true;
        this.xhr.setRequestHeader('Accept', 'text/event-stream');

        for( const headerKey in headers )
        {
            this.xhr.setRequestHeader( headerKey, headers[ headerKey ]);
        }

        this.xhr.send( options?.body ?? '' );
    }
}

export default EventsService;
