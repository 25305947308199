<template>
    <div class="Loading-screen">
        <div class="lds-heart">
            <div></div>
        </div>
    </div>
</template>
<style lang="scss">
@keyframes lds-heart
{
    0%   { transform: scale(0.95); }
    5%   { transform: scale(1.1); }
    39%  { transform: scale(0.85); }
    45%  { transform: scale(1); }
    60%  { transform: scale(0.95); }
    100% { transform: scale(0.9); }
}

.Loading-screen
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background-color: #fff;
    z-index: 999999;


    .lds-heart
    {
        display: inline-block;
        position: relative;
        width: px(80);
        height: px(80);

        div
        {
            width: px(80);
            height: px(80);
            background: #fff;
            animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
            background-image: url("https://resources.ramp.global/images/logo/favicon.png");
            background-position: center;
            background-size: cover;
        }
    }
}
</style>