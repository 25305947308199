const INTERACTIVITY = [ 'idle', 'read', 'write' ];
const SESSION_EXPIRATION = 30 * 60 * 1000;

function ID(){ return ( Date.now() / 1000 | 0 ).toString(16) + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)).toLowerCase()}

class PageView
{
    constructor( sessionID, name, options )
    {
        this._options = options;
        this._timeout = undefined;

        this.state = 'read';
        this.started = new Date();
        this.changed = Date.now();
        
        this.sessionID = sessionID;
        this.id = ID();
        this.url = options.url;
        this.name = name || document.title;
        this.timeline = '';
        this.states = { idle: 0, read: 0, write: 0, interactive: 0 };

        this.interaction( 'read', true );
    }

    _getPath( type )
    {
        const newUrl = new URL( this._options.paths[ type ], this._options.rootUrl );
        return newUrl.href;
    }

    interaction( state, force )
    {
        if( this.state !== state && ( force || INTERACTIVITY.indexOf( state ) > INTERACTIVITY.indexOf( this.state )))
        {
            if( this.state !== state )
            {
                let now = Date.now(), duration = ( now - this.changed ) / 1000, notable = duration >= 0.5;

                this.states[ this.state ] += duration;
                this.state !== 'idle' && ( this.states.interactive += duration );

                if( notable )
                {
                    this.timeline += this.state.substring(0,1) + Math.round( duration );

                    navigator.sendBeacon( this._getPath( 'tracker' ), new Blob([JSON.stringify(
                    {
                        sessionID: this.sessionID,
                        id      : this.id,
                        name    : this.name,
                        user    : this._options.user,
                        url     : this.url,
                        referrer: this._options.referrer || document.referrer,
                        device  : { screen : { width: window.innerWidth, height: window.innerHeight } },
                        started : this.started.toISOString(),
                        interaction:
                        {
                            timeline: this.timeline,
                            states  : this.states,
                        }
                    })], { type: 'application/json' }));
                }

                this.changed = now;
                this.state = state;
            }

            sessionStorage.setItem( 'session-tracker-expires', Date.now() + SESSION_EXPIRATION );
        }
        else if( this.state !== 'idle' )
        {
            sessionStorage.setItem( 'session-tracker-expires', Date.now() + SESSION_EXPIRATION );
        }

        this._timeout && clearTimeout( this._timeout );

        if( this.state !== 'idle' )
        {
            this._timeout = setTimeout( this.interaction.bind( this, INTERACTIVITY[ Math.max( 0, INTERACTIVITY.indexOf( state ) - 1 ) ], true ), this._options.timeout[ this.state ] || 1000 );
        }
    }

    end()
    {
        this.interaction( 'idle', true );
    }

    clone( sessionID )
    {
        return new PageView( sessionID, this.name, this._options );
    }
}

class Session
{
    constructor( options = {} )
    {
        this.id = sessionStorage.getItem( 'session-tracker-id' );
        this.options = options;
        this._events = [];
        this._frame = 0;
        this._sequence = Date.now();

        this._worker = new Worker( new URL('../plugins/record/worker.js', import.meta.url ), {
            type: 'module',
        } );
        
        if( !this.id || ( sessionStorage.getItem( 'session-tracker-expires' ) || Infinity ) < Date.now() )
        {
            this.id = ID();
            sessionStorage.setItem( 'session-tracker-id', this.id );
            sessionStorage.setItem( 'session-tracker-expires', Date.now() + SESSION_EXPIRATION );
        }

        this.record();
    }

    _getPath( type )
    {
        const newUrl = new URL( this.options.paths[ type ], this.options.rootUrl );
        return newUrl.href;
    }

    _isExpired()
    {
        if( ( this.pageView && this.pageView.state === 'idle' ? this.pageView.changed + SESSION_EXPIRATION : sessionStorage.getItem( 'session-tracker-expires' ) ) < Date.now() )
        {
            this.id = ID();
            sessionStorage.setItem( 'session-tracker-id', this.id );
            sessionStorage.setItem( 'session-tracker-expires', Date.now() + SESSION_EXPIRATION );

            return true;
        }

        return false;
    }

    record()
    {
        const self = this;
        console.log('Start record');
        try{
        rampUI(
        {
            emit(event)
            {
                self._events.push(event);
            },
            plugins: [rrwebConsoleRecord.getRecordConsolePlugin({
                    level: ["info", "log", "warn", "error"],
                    lengthThreshold: 10000,
                    stringifyOptions: {
                    stringLengthLimit: 1000,
                    numOfKeysLimit: 100,
                    depthOfLimit: 1
                },
                logger: window.console,
            })],
        } );
        }catch(e){ console.log( 'rampUI' ) }
        
        this._worker.onmessage = (event) =>
        {
            const { success, result, error, ready } = event.data;

            if( ready )
            {
                console.log('Start save record');

                this.saveRecord();

            }
        };
    }

    saveRecord()
    {
        if( this._events.length > 0 )
        {
            this._worker.postMessage({
                url: this._getPath( 'record' ) + `?sessionID=${this.id}&sequence=${this._sequence}&frame=${( this._frame++ )}`,
                data: JSON.stringify( this._events )
            } );
            this._events = [];
        }

        setTimeout(() => requestAnimationFrame(() => this.saveRecord() ), 10 * 1000 );
    }

    page( pageName, pageOptions )
    {
        const expired = this._isExpired();

        if( expired || !this.pageView || this.pageView.url !== pageOptions.url )
        {
            !expired && this.pageView && this.pageView.end();
            this.pageView = new PageView( this.id, pageName, { ...this.options, ...pageOptions });
        }

        this.pageView.interaction( document.visibilityState !== 'hidden' ? 'read' : 'idle' );
    }

    interaction( state, force )
    {
        if( !this.pageView ){ return }
        if( this._isExpired() )
        {
            this.pageView = this.pageView.clone( this.id );
        }

        this.pageView.interaction( state, force );
    }
}

class SessionTracker
{
    static _options;
    static _session;

    static init( options = { url: '', paths: { record: '', tracker: '', feedback: '' }, user: {}, timeout: { read: 30000, write: 5000 }})
    {
        SessionTracker._options = options;
        SessionTracker._session = new Session( options );

        function bind( element, event, state, force )
        {
            element.addEventListener( event, () => SessionTracker._session.interaction( state, force ), { capture: true, passive: true });
        }

        bind( document, 'click',        'read' );
        bind( document, 'scroll',       'read' );
        bind( document, 'mousemove',    'read' );
        bind( document, 'keypress',     'write' );
        bind( window,   'blur',         'idle', true );
        bind( window,   'focus',        'read' );
        bind( window,   'beforeunload', 'idle', true );
    }

    static page( pageName, pageOptions = {})
    {
        SessionTracker._session.page( pageName, pageOptions );   
    }

}

export default SessionTracker;