import SwaggerService from "@/services/swagger.service";

import { Filter } from "@/core/filter";

import { Format, Helpers, ObjectLib } from "@/core/utils";

import { Socket }      from "@/core/socket";
import { Permission }  from "@/core/permission";
import { Component }   from "@/core/component";
import { Draft }       from "@/core/draft";
import { Commercials } from "@/core/commercials";
import { Validators }  from "@/core/validators";

export default
{
    install: async ( app, options ) =>
    {
        app.config.globalProperties.$swagger     = SwaggerService;
        app.config.globalProperties.$format      = Format;
        app.config.globalProperties.$helpers     = Helpers;
        app.config.globalProperties.$object      = ObjectLib;
        app.config.globalProperties.$filter      = Filter;
        app.config.globalProperties.$commercials = Commercials;
        app.config.globalProperties.$socket      = new Socket({ store: options.store });
        app.config.globalProperties.$permission  = new Permission({ store: options.store });
        app.config.globalProperties.$component   = new Component({ store: options.store });
        app.config.globalProperties.$draft       = new Draft({ store: options.store });
        app.config.globalProperties.$validators  = new Validators({ i18n: options.i18n });
    }
}