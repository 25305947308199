import { io } from 'socket.io-client';

export class Socket
{
    constructor( ctx )
    {
        this.ctx        = ctx;
        this.socket     = null;
        this.eventQueue = [];
    }

    connect( retries = 0, maxRetries = 10 )
    {
        const currentAccount = this.ctx.store?.state?.auth?.user?.userDetail?.currentAccount;

        if( !currentAccount )
        {
            if( !window.sessionStorage.getItem('X-AccountID') && !window.sessionStorage.getItem('X-OrganizationID') )
            {
                console.warn("User not logged in yet. Could not initialize socket.");
                setTimeout(() => { this.connect( 0, maxRetries ); }, 5000 );
                return null;
            }
            if( retries >= maxRetries )
            {
                console.warn("Max retries reached. Could not initialize socket.");
                return null;
            }

            setTimeout(() => { this.connect( retries + 1, maxRetries ); }, 5000 );

            return null;
        }

        this.socket = io( import.meta.env.VITE_APP_API_URL.replace('https', 'wss'), { path: '/socket.io', transports: ['websocket'], auth: { accountID: currentAccount.id, organizationID: currentAccount.organization.id, flag: currentAccount.flag } });

        this.socket.on('connect', () =>
        {
            console.log('Socket connected');
            this.processEventQueue();
        });

        return this.socket;
    }

    disconnect()
    {
        if( this.socket && this.socket.connected )
        {
            this.socket.disconnect();
        }
    }

    on( event, callback )
    {
        if( this.socket && this.socket.connected )
        {
            this.socket.on( event, callback );
        }
        else
        {
            this.eventQueue.push({ event, callback });
        }
    }

    emit( event, data )
    {
        if( this.socket && this.socket.connected )
        {
            this.socket.emit( event, data );
        }
        else
        {
            console.warn(`Socket not connected. Unable to emit event: ${event}`);
        }
    }

    processEventQueue()
    {
        if( this.socket && this.socket.connected )
        {
            this.eventQueue.forEach( ({ event, callback }) => { this.socket.on( event, callback ); });

            this.eventQueue = [];
        }
    }
}