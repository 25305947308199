import { convertCurrency, calculateSalary, calculateFees } from '@ramp-global/util-finance';

export class Commercials
{
    static calculateSalary( salary, duration, currency )
    {
        return calculateSalary( salary, duration, currency );
    }

    static calculateFees( salary, commercials, type )
    {
        return calculateFees( salary, commercials, type );
    }

    static convertCurrency( amount, from, to )
    {
        return convertCurrency( amount, from, to );
    }
}