import { shallowRef, defineAsyncComponent, reactive } from "vue";

import { Format, Helpers, ObjectLib } from "@/core/utils";

let $_FilterObject =
{
    "sort":
    {
        'ramp.recruitment.programme':
        {
            options:
            [
                { id: 'id_asc', value: { '_id': 1 }, label: 'ID ASC' },
                { id: 'id_desc', value: { 'events.created': -1, '_id': -1 }, label: 'ID DESC', default: true },

                { id: 'title_asc', value: { 'title': 1 }, label: 'Title ASC' },
                { id: 'title_desc', value: { 'title': -1 }, label: 'Title DESC' },

                /*
                { id: 'agencyRate_asc', value: { '_id': 1 }, label: 'Agency rate ASC' }, //TODO
                { id: 'agencyRate_desc', value: { '_id': -1 }, label: 'Agency rate DESC' }, //TODO

                { id: 'agenciesTotal_asc', value: { 'totalAgenciesCount': 1 }, label: 'Agencies total ASC' }, //TODO
                { id: 'agenciesTotal_desc', value: { 'totalAgenciesCount': -1 }, label: 'Agencies total DESC' }, //TODO

                { id: 'jobsTotal_asc', value: { 'totalJobsCount': 1 }, label: 'Jobs total ASC' }, //TODO
                { id: 'jobsTotal_desc', value: { 'totalJobsCount': -1 }, label: 'Jobs total DESC' }, //TODO

                { id: 'applicationsTotal_asc', value: { 'totalApplicationsCount': 1 }, label: 'Applications total ASC' }, //TODO
                { id: 'applicationsTotal_desc', value: { 'totalApplicationsCount': -1 }, label: 'Applications total DESC' }, //TODO

                { id: 'placementsTotal_asc', value: { 'totalPlacementsCount': 1 }, label: 'Placements total ASC' }, //TODO
                { id: 'placementsTotal_desc', value: { 'totalPlacementsCount': -1 }, label: 'Placements total DESC' }, //TODO

                { id: 'contractsTotal_asc', value: { 'totalContractsCount': 1 }, label: 'Contracts total ASC' }, //TODO
                { id: 'contractsTotal_desc', value: { 'totalContractsCount': -1 }, label: 'Contracts total DESC' }, //TODO
                */
            ]
        },

        'ramp.recruitment.agency':
        {
            options:
            [
                { id: 'id_asc', value: { '_id': 1 }, label: 'ID ASC' },
                { id: 'id_desc', value: { 'events.created': -1, '_id': -1 }, label: 'ID DESC', default: true },

                { id: 'title_asc', value: { 'name': 1 }, label: 'Title ASC' },
                { id: 'title_desc', value: { 'name': -1 }, label: 'Title DESC' },

                /*
                { id: 'coc_asc', value: { '_id': 1 }, label: 'CoC signed ASC' }, //TODO
                { id: 'coc_desc', value: { '_id': -1 }, label: 'CoC signed DESC' }, //TODO

                { id: 'locations_asc', value: { '_id': 1 }, label: 'Locations ASC' }, //TODO
                { id: 'locations_desc', value: { '_id': -1 }, label: 'Locations DESC' }, //TODO

                { id: 'agencyFee_asc', value: { '_id': 1 }, label: 'Agency fee ASC' }, //TODO
                { id: 'agencyFee_desc', value: { '_id': -1 }, label: 'Agency fee DESC' }, //TODO

                { id: 'fillRate_asc', value: { '_id': 1 }, label: 'Fill rate ASC' }, //TODO
                { id: 'fillRate_desc', value: { '_id': -1 }, label: 'Fill rate DESC' }, //TODO

                { id: 'recruitersTotal_asc', value: { '_id': 1 }, label: 'Recruiters onboarded ASC' }, //TODO
                { id: 'recruitersTotal_desc', value: { '_id': -1 }, label: 'Recruiters onboarded DESC' }, //TODO
                */

                { id: 'jobsTotal_asc', value: { 'agencyTotalJobs': 1 }, label: 'Jobs total ASC' },
                { id: 'jobsTotal_desc', value: { 'agencyTotalJobs': -1 }, label: 'Jobs total DESC' },

                { id: 'applicationsTotal_asc', value: { 'agencyTotalApplications': 1 }, label: 'Applications total ASC' },
                { id: 'applicationsTotal_desc', value: { 'agencyTotalApplications': -1 }, label: 'Applications total DESC' },

                { id: 'placementsTotal_asc', value: { 'agencyTotalPlacements': 1 }, label: 'Placements total ASC' },
                { id: 'placementsTotal_desc', value: { 'agencyTotalPlacements': -1 }, label: 'Placements total DESC' },

                { id: 'contractorsTotal_asc', value: { 'agencyTotalContractors': 1 }, label: 'Contractors total ASC' },
                { id: 'contractorsTotal_desc', value: { 'agencyTotalContractors': -1 }, label: 'Contractors total DESC' },

                { id: 'createdDate_asc', value: { 'events.created': 1 }, label: 'Created date ASC' },
                { id: 'createdDate_desc', value: { 'events.created': -1 }, label: 'Created date DESC' },
            ]
        },

        'ramp.recruitment.job':
        {
            options:
            [
                { id: 'id_asc', value: { '_id': 1 }, label: 'ID ASC' },
                { id: 'id_desc', value: { 'events.created': -1, '_id': -1 }, label: 'ID DESC', default: true },

                { id: 'openJobsTotal_asc', value: { 'activePositionsCount': 1 }, label: 'Open jobs ASC' },
                { id: 'openJobsTotal_desc', value: { 'activePositionsCount': -1 }, label: 'Open jobs DESC' },

                { id: 'reference_asc', value: { 'reference': 1 }, label: 'Job requisition reference ASC' },
                { id: 'reference_desc', value: { 'reference': -1 }, label: 'Job requisition reference DESC' },

                { id: 'title_asc', value: { 'title': 1 }, label: 'Title ASC' },
                { id: 'title_desc', value: { 'title': -1 }, label: 'Title DESC' },

                { id: 'stage_asc', value: { 'furthestStage': 1 }, label: 'Stage ASC' },
                { id: 'stage_desc', value: { 'furthestStage': -1 }, label: 'Stage DESC' },

                //{ id: 'category_asc', value: { 'category.label': 1 }, label: 'Category ASC' }, //TODO
                //{ id: 'category_desc', value: { 'category.label': -1 }, label: 'Category DESC' }, //TODO

                //{ id: 'skills_asc', value: { 'skills': 1 }, label: 'Skills ASC' }, //TODO
                //{ id: 'skills_desc', value: { 'skills': -1 }, label: 'Skills DESC' }, //TODO

                //{ id: 'locations_asc', value: { 'locations': 1 }, label: 'Locations ASC' }, //TODO
                //{ id: 'locations_desc', value: { 'locations': -1 }, label: 'Locations DESC' }, //TODO

                { id: 'remoteWork_asc', value: { 'remote': 1 }, label: 'Remote work ASC' },
                { id: 'remoteWork_desc', value: { 'remote': -1 }, label: 'Remote work DESC' },

                { id: 'confidentialRole_asc', value: { 'confidential': 1 }, label: 'Confidential role ASC' },
                { id: 'confidentialRole_desc', value: { 'confidential': -1 }, label: 'Confidential role DESC' },

                //{ id: 'programme_asc', value: { '_id': 1 }, label: 'Programme ASC' }, //TODO
                //{ id: 'programme_desc', value: { '_id': -1 }, label: 'Programme DESC' }, //TODO

                //{ id: 'employer_asc', value: { '_id': 1 }, label: 'Employer ASC' }, //TODO
                //{ id: 'employer_desc', value: { '_id': -1 }, label: 'Employer DESC' }, //TODO

                //{ id: 'accountManager_asc', value: { '_id': 1 }, label: 'Account manager ASC' }, //TODO
                //{ id: 'accountManager_desc', value: { '_id': -1 }, label: 'Account manager DESC' }, //TODO

                { id: 'salary_asc', value: { 'normalizedSalary': 1 }, label: 'Salary ASC' },
                { id: 'salary_desc', value: { 'normalizedSalary': -1 }, label: 'Salary DESC' },

                //{ id: 'recruitmentFee_asc', value: { '_id': 1 }, label: 'Agency fee ASC' }, //TODO
                //{ id: 'recruitmentFee_desc', value: { '_id': -1 }, label: 'Agency fee DESC' }, //TODO

                //{ id: 'supplierFee_asc', value: { '_id': 1 }, label: 'Supplier handling fee ASC' }, //TODO
                //{ id: 'supplierFee_desc', value: { '_id': -1 }, label: 'Supplier handling fee DESC' }, //TODO

                //{ id: 'employerFee_asc', value: { '_id': 1 }, label: 'Employer handling fee ASC' }, //TODO
                //{ id: 'employerFee_desc', value: { '_id': -1 }, label: 'Employer handling fee DESC' }, //TODO

                { id: 'positionsTotal_asc', value: { 'totalPositionsCount': 1 }, label: 'Positions total ASC' },
                { id: 'positionsTotal_desc', value: { 'totalPositionsCount': -1 }, label: 'Positions total DESC' },

                { id: 'engagementsActive_asc', value: { 'activeEngagementsCount': 1 }, label: 'Engagements total ASC' },
                { id: 'engagementsActive_desc', value: { 'activeEngagementsCount': -1 }, label: 'Engagements total DESC' },

                { id: 'applicationsActive_asc', value: { 'activeApplicationsCount': 1 }, label: 'Active applications ASC' },
                { id: 'applicationsActive_desc', value: { 'activeApplicationsCount': -1 }, label: 'Active applications DESC' },

                { id: 'latestInteract_asc', value: { 'events.refreshed': 1 }, label: 'Applications total ASC' },
                { id: 'latestInteract_desc', value: { 'events.refreshed': -1 }, label: 'Applications total DESC' },

                { id: 'startDate_asc', value: { 'employment.interval.start': 1 }, label: 'Start date ASC' },
                { id: 'startDate_desc', value: { 'employment.interval.start': -1 }, label: 'Start date DESC' },

                { id: 'endDate_asc', value: { 'employment.interval.end': 1 }, label: 'End date ASC' },
                { id: 'endDate_desc', value: { 'employment.interval.end': -1 }, label: 'End date DESC' },

                { id: 'createdDate_asc', value: { 'events.created': 1 }, label: 'Created date ASC' },
                { id: 'createdDate_desc', value: { 'events.created': -1 }, label: 'Created date DESC' }
            ]
        },

        'ramp.recruitment.job.engagement':
        {
            options:
            [
                { id: 'id_asc', value: { '_id': 1 }, label: 'ID ASC' },
                { id: 'id_desc', value: { 'events.created': -1, '_id': -1 }, label: 'ID DESC', default: true },

                { id: 'title_asc', value: { 'agencyName': 1 }, label: 'Agency name ASC' },
                { id: 'title_desc', value: { 'agencyName': -1 }, label: 'Agency name DESC' },

                { id: 'status_asc', value: { 'status': 1 }, label: 'Status ASC' },
                { id: 'status_desc', value: { 'status': -1 }, label: 'Status DESC' },

                { id: 'recruitmentFee_asc', value: { '_properties.fee.markup': 1 }, label: 'Recruitment fee ASC' },
                { id: 'recruitmentFee_desc', value: { '_properties.fee.markup': -1 }, label: 'Recruitment fee DESC' },

                { id: 'candidates_asc', value: { 'totalApplicationsCount': 1 }, label: 'Total candidates ASC' },
                { id: 'candidates_desc', value: { 'totalApplicationsCount': -1 }, label: 'Total candidates DESC' },

                { id: 'engagementDate_asc', value: { 'events.created': 1 }, label: 'Engagement date ASC' },
                { id: 'engagementDate_desc', value: { 'events.created': -1 }, label: 'Engagement date DESC' },

                { id: 'disengagementDate_asc', value: { 'events.closed': 1 }, label: 'Disengagement date ASC' },
                { id: 'disengagementDate_desc', value: { 'events.closed': -1 }, label: 'Disengagement date DESC' }
            ]
        },

        'ramp.recruitment.invitation':
        {
            options:
            [
                { id: 'title_asc', value: { 'jobTitle': 1 }, label: 'Job title ASC' },
                { id: 'title_desc', value: { 'jobTitle': -1 }, label: 'Job title DESC' },

                { id: 'status_asc', value: { 'status': 1 }, label: 'Job title ASC' },
                { id: 'status_desc', value: { 'status': -1 }, label: 'Job title DESC' },

                //{ id: 'category_asc', value: { '': 1 }, label: 'Category ASC' }, //TODO
                //{ id: 'category_desc', value: { '': -1 }, label: 'Category DESC' }, //TODO

                //{ id: 'salary_asc', value: { '': 1 }, label: 'Salary ASC' }, //TODO FINISH
                //{ id: 'salary_desc', value: { '': -1 }, label: 'Salary DESC' }, //TODO FINISH

                //{ id: 'agencyFee_asc', value: { '': 1 }, label: 'Agency fee ASC' }, //TODO
                //{ id: 'agencyFee_desc', value: { '': -1 }, label: 'Agency fee DESC' }, //TODO

                //{ id: 'engagementPrice_asc', value: { '': 1 }, label: 'Engagement price ASC' }, //TODO
                //{ id: 'engagementPrice_desc', value: { '': -1 }, label: 'Engagement price DESC' }, //TODO

                //{ id: 'inviter_asc', value: { '': 1 }, label: 'Inviter ASC' }, //TODO
                //{ id: 'inviter_desc', value: { '': -1 }, label: 'Inviter DESC' }, //TODO

                //{ id: 'recruiter_asc', value: { '': 1 }, label: 'Recruiter ASC' }, //TODO
                //{ id: 'recruiter_desc', value: { '': -1 }, label: 'Recruiter DESC' }, //TODO

                //{ id: 'programme_asc', value: { '': 1 }, label: 'Programme ASC' }, //TODO
                //{ id: 'programme_desc', value: { '': -1 }, label: 'Programme DESC' }, //TODO

                { id: 'release_asc', value: { 'release': 1 }, label: 'Release type ASC' },
                { id: 'release_desc', value: { 'release': -1 }, label: 'Release type DESC' },

                { id: 'createdDate_asc', value: { 'events.created': 1 }, label: 'Created date ASC' },
                { id: 'createdDate_desc', value: { 'events.created': -1 }, label: 'Created date DESC' },
            ]
        },

        'ramp.recruitment.application':
        {
            options:
            [
                { id: 'id_asc', value: { '_id': 1 }, label: 'ID ASC' },
                { id: 'id_desc', value: { 'events.created': -1, '_id': -1 }, label: 'ID DESC', default: true },

                { id: 'reference_asc', value: { '_root.reference': 1 }, label: 'Job reference ASC' },
                { id: 'reference_desc', value: { '_root.reference': -1 }, label: 'Job reference DESC' },

                { id: 'title_asc', value: { 'candidate.name': 1 }, label: 'Title ASC' },
                { id: 'title_desc', value: { 'candidate.name': -1 }, label: 'Title DESC' },

                { id: 'status_asc', value: { 'status': 1 }, label: 'Status ASC' },
                { id: 'status_desc', value: { 'status': -1 }, label: 'Status DESC' },

                //{ id: 'availability_asc', value: { 'status': 1 }, label: 'Availability ASC' }, //TODO
                //{ id: 'availability_desc', value: { 'status': -1 }, label: 'Availability DESC' }, //TODO

                //{ id: 'category_asc', value: { 'status': 1 }, label: 'Category ASC' }, //TODO
                //{ id: 'category_desc', value: { 'status': -1 }, label: 'Category DESC' }, //TODO

                //{ id: 'skills_asc', value: { 'skills': 1 }, label: 'Skills ASC' }, //TODO
                //{ id: 'skills_desc', value: { 'skills': -1 }, label: 'Skills DESC' }, //TODO

                //{ id: 'locations_asc', value: { 'locations': 1 }, label: 'Locations ASC' }, //TODO
                //{ id: 'locations_desc', value: { 'locations': -1 }, label: 'Locations DESC' }, //TODO

                { id: 'remoteWork_asc', value: { 'remote': 1 }, label: 'Remote work ASC' },
                { id: 'remoteWork_desc', value: { 'remote': -1 }, label: 'Remote work DESC' },

                //{ id: 'programme_asc', value: { '': 1 }, label: 'Programme ASC' }, //TODO
                //{ id: 'programme_desc', value: { '': -1 }, label: 'Programme DESC' }, //TODO

                //{ id: 'recruiter_asc', value: { '': 1 }, label: 'Agency ASC' }, //TODO
                //{ id: 'recruiter_desc', value: { '': -1 }, label: 'Agency DESC' }, //TODO

                //{ id: 'employer_asc', value: { '': 1 }, label: 'Employer ASC' }, //TODO
                //{ id: 'employer_desc', value: { '': -1 }, label: 'Employer DESC' }, //TODO

                //{ id: 'accountManager_asc', value: { '': 1 }, label: 'Account manager ASC' }, //TODO
                //{ id: 'accountManager_desc', value: { '': -1 }, label: 'Account manager DESC' }, //TODO

                //{ id: 'salary_asc', value: { 'employment.salary.range.max': 1 }, label: 'Salary ASC' }, //TODO FINISH
                //{ id: 'salary_desc', value: { 'employment.salary.range.max': -1 }, label: 'Salary DESC' }, //TODO FINISH

                //{ id: 'recruitmentFee_asc', value: { '_id': 1 }, label: 'Agency fee ASC' }, //TODO
                //{ id: 'recruitmentFee_desc', value: { '_id': -1 }, label: 'Agency fee DESC' }, //TODO

                //{ id: 'supplierFee_asc', value: { '_id': 1 }, label: 'Agency handling fee ASC' }, //TODO
                //{ id: 'supplierFee_desc', value: { '_id': -1 }, label: 'Agency handling fee DESC' }, //TODO

                //{ id: 'employerFee_asc', value: { '_id': 1 }, label: 'Employer handling fee ASC' }, //TODO
                //{ id: 'employerFee_desc', value: { '_id': -1 }, label: 'Employer handling fee DESC' }, //TODO

                { id: 'updatedDate_asc', value: { 'events.updated': 1 }, label: 'Updated date ASC' },
                { id: 'updatedDate_desc', value: { 'events.updated': -1 }, label: 'Updated date DESC' },

                { id: 'createdDate_asc', value: { 'events.created': 1 }, label: 'Created date ASC' },
                { id: 'createdDate_desc', value: { 'events.created': -1 }, label: 'Created date DESC' },
            ]
        },

        'ramp.recruitment.placement':
        {
            options:
            [
                { id: 'id_asc', value: { '_id': 1 }, label: 'ID ASC' },
                { id: 'id_desc', value: { 'events.created': -1, '_id': -1 }, label: 'ID DESC', default: true },

                { id: 'reference_asc', value: { 'jobReference': 1 }, label: 'Job reference ASC' },
                { id: 'reference_desc', value: { 'jobReference': -1 }, label: 'Job reference DESC' },

                { id: 'title_asc', value: { 'employee.name': 1 }, label: 'Title ASC' },
                { id: 'title_desc', value: { 'employee.name': -1 }, label: 'Title DESC' },

                { id: 'status_asc', value: { 'status': 1 }, label: 'Status ASC' },
                { id: 'status_desc', value: { 'status': -1 }, label: 'Status DESC' },

                //{ id: 'job_asc', value: { 'title': 1 }, label: 'Job ASC' }, //TODO
                //{ id: 'job_desc', value: { 'title': -1 }, label: 'Job DESC' }, //TODO

                //{ id: 'category_asc', value: { 'category.label': 1 }, label: 'Category ASC' }, //TODO
                //{ id: 'category_desc', value: { 'category.label': -1 }, label: 'Category DESC' }, //TODO

                //{ id: 'skills_asc', value: { 'skills': 1 }, label: 'Skills ASC' }, //TODO
                //{ id: 'skills_desc', value: { 'skills': -1 }, label: 'Skills DESC' }, //TODO

                //{ id: 'location_asc', value: { 'location.name': 1 }, label: 'Location ASC' }, //TODO
                //{ id: 'location_desc', value: { 'location.name': -1 }, label: 'Location DESC' }, //TODO

                { id: 'remoteWork_asc', value: { 'remote': 1 }, label: 'Remote work ASC' },
                { id: 'remoteWork_desc', value: { 'remote': -1 }, label: 'Remote work DESC' },

                //{ id: 'programme_asc', value: { '_id': 1 }, label: 'Programme ASC' }, //TODO
                //{ id: 'programme_desc', value: { '_id': -1 }, label: 'Programme DESC' }, //TODO,

                //{ id: 'confidentialRole_asc', value: { '_id': 1 }, label: 'Confidential role ASC' }, //TODO
                //{ id: 'confidentialRole_desc', value: { '_id': -1 }, label: 'Confidential role DESC' }, //TODO

                //{ id: 'recruiter_asc', value: { '_id': 1 }, label: 'Recruiter ASC' }, //TODO
                //{ id: 'recruiter_desc', value: { '_id': -1 }, label: 'Recruiter DESC' }, //TODO

                //{ id: 'employer_asc', value: { '_id': 1 }, label: 'Employer ASC' }, //TODO
                //{ id: 'employer_desc', value: { '_id': -1 }, label: 'Employer DESC' }, //TODO

                //{ id: 'accountManager_asc', value: { '_id': 1 }, label: 'Account manager ASC' }, //TODO
                //{ id: 'accountManager_desc', value: { '_id': -1 }, label: 'Account manager DESC' }, //TODO

                //{ id: 'salary_asc', value: { '_id': 1 }, label: 'Salary ASC' }, //TODO
                //{ id: 'salary_desc', value: { '_id': -1 }, label: 'Salary DESC' }, //TODO

                //{ id: 'recruitmentFee_asc', value: { '_id': 1 }, label: 'Agency fee ASC' }, //TODO
                //{ id: 'recruitmentFee_desc', value: { '_id': -1 }, label: 'Agency fee DESC' }, //TODO

                //{ id: 'supplierFee_asc', value: { '_id': 1 }, label: 'Supplier handling fee ASC' }, //TODO
                //{ id: 'supplierFee_desc', value: { '_id': -1 }, label: 'Supplier Handling Fee DESC' }, //TODO

                //{ id: 'employerFee_asc', value: { '_id': 1 }, label: 'Employer handling fee ASC' }, //TODO
                //{ id: 'employerFee_desc', value: { '_id': -1 }, label: 'Employer handling fee DESC' }, //TODO

                //{ id: 'rebatePeriod_asc', value: { '_id': 1 }, label: 'Rebate days ASC' }, //TODO
                //{ id: 'rebatePeriod_desc', value: { '_id': -1 }, label: 'Rebate days DESC' }, //TODO

                //{ id: 'employerInvoice_asc', value: { '_id': 1 }, label: 'Employer invoice ASC' }, //TODO
                //{ id: 'employerInvoice_desc', value: { '_id': -1 }, label: 'Employer invoice DESC' }, //TODO

                //{ id: 'employerBillingEntity_asc', value: { '_id': 1 }, label: 'Employer billing entity ASC' }, //TODO
                //{ id: 'employerBillingEntity_desc', value: { '_id': -1 }, label: 'Employer billing entity DESC' }, //TODO

                //{ id: 'agencyInvoice_asc', value: { '_id': 1 }, label: 'Agency invoice ASC' }, //TODO
                //{ id: 'agencyInvoice_desc', value: { '_id': -1 }, label: 'Agency invoice DESC' }, //TODO

                { id: 'startDate_asc', value: { 'employment.interval.start': 1 }, label: 'Start date ASC' },
                { id: 'startDate_desc', value: { 'employment.interval.start': -1 }, label: 'Start date DESC' },

                { id: 'endDate_asc', value: { 'employment.interval.end': 1 }, label: 'End date ASC' },
                { id: 'endDate_desc', value: { 'employment.interval.end': -1 }, label: 'End date DESC' },

                { id: 'updatedDate_asc', value: { 'events.updated': 1 }, label: 'Updated date ASC' },
                { id: 'updatedDate_desc', value: { 'events.updated': -1 }, label: 'Updated date DESC' },

                { id: 'createdDate_asc', value: { 'events.created': 1 }, label: 'Created date ASC' },
                { id: 'createdDate_desc', value: { 'events.created': -1 }, label: 'Created date DESC' }
            ]
        },

        'ramp.recruitment.contract':
        {
            options:
            [
                { id: 'id_asc', value: { '_id': 1 }, label: 'ID ASC' },
                { id: 'id_desc', value: { 'events.created': -1, '_id': -1 }, label: 'ID DESC', default: true },

                { id: 'reference_asc', value: { 'jobReference': 1 }, label: 'Job reference ASC' },
                { id: 'reference_desc', value: { 'jobReference': -1 }, label: 'Job reference DESC' },

                { id: 'title_asc', value: { 'contractor.name': 1 }, label: 'Title ASC' },
                { id: 'title_desc', value: { 'contractor.name': -1 }, label: 'Title DESC' },

                { id: 'status_asc', value: { 'status': 1 }, label: 'Status ASC' },
                { id: 'status_desc', value: { 'status': -1 }, label: 'Status DESC' },

                //{ id: 'job_asc', value: { 'title': 1 }, label: 'Job ASC' }, //TODO
                //{ id: 'job_desc', value: { 'title': -1 }, label: 'Job DESC' }, //TODO

                //{ id: 'category_asc', value: { 'category.label': 1 }, label: 'Category ASC' }, //TODO
                //{ id: 'category_desc', value: { 'category.label': -1 }, label: 'Category DESC' }, //TODO

                //{ id: 'skills_asc', value: { 'skills': 1 }, label: 'Skills ASC' }, //TODO
                //{ id: 'skills_desc', value: { 'skills': -1 }, label: 'Skills DESC' }, //TODO

                //{ id: 'location_asc', value: { 'location.name': 1 }, label: 'Location ASC' }, //TODO
                //{ id: 'location_desc', value: { 'location.name': -1 }, label: 'Location DESC' }, //TODO

                { id: 'remoteWork_asc', value: { 'remote': 1 }, label: 'Remote work ASC' },
                { id: 'remoteWork_desc', value: { 'remote': -1 }, label: 'Remote work DESC' },

                //{ id: 'confidentialRole_asc', value: { '_id': 1 }, label: 'Confidential role ASC' }, //TODO
                //{ id: 'confidentialRole_desc', value: { '_id': -1 }, label: 'Confidential role DESC' }, //TODO

                //{ id: 'programme_asc', value: { '_id': 1 }, label: 'Programme ASC' }, //TODO
                //{ id: 'programme_desc', value: { '_id': -1 }, label: 'Programme DESC' }, //TODO

                //{ id: 'recruiter_asc', value: { '_id': 1 }, label: 'Recruiter ASC' }, //TODO
                //{ id: 'recruiter_desc', value: { '_id': -1 }, label: 'Recruiter DESC' }, //TODO

                //{ id: 'employer_asc', value: { '_id': 1 }, label: 'Employer ASC' }, //TODO
                //{ id: 'employer_desc', value: { '_id': -1 }, label: 'Employer DESC' }, //TODO

                //{ id: 'accountManager_asc', value: { '_id': 1 }, label: 'Account manager ASC' }, //TODO
                //{ id: 'accountManager_desc', value: { '_id': -1 }, label: 'Account manager DESC' }, //TODO

                //{ id: 'salary_asc', value: { '_id': 1 }, label: 'Salary ASC' }, //TODO
                //{ id: 'salary_desc', value: { '_id': -1 }, label: 'Salary DESC' }, //TODO

                //{ id: 'recruitmentFee_asc', value: { '_id': 1 }, label: 'Agency fee ASC' }, //TODO
                //{ id: 'recruitmentFee_desc', value: { '_id': -1 }, label: 'Agency fee DESC' }, //TODO

                //{ id: 'supplierFee_asc', value: { '_id': 1 }, label: 'Supplier handling fee ASC' }, //TODO
                //{ id: 'supplierFee_desc', value: { '_id': -1 }, label: 'Supplier handling fee DESC' }, //TODO

                //{ id: 'employerFee_asc', value: { '_id': 1 }, label: 'Employer handling fee ASC' }, //TODO
                //{ id: 'employerFee_desc', value: { '_id': -1 }, label: 'Employer handling fee DESC' }, //TODO

                //{ id: 'employerBillingEntity_asc', value: { '_id': 1 }, label: 'Employer billing entity ASC' }, //TODO
                //{ id: 'employerBillingEntity_desc', value: { '_id': -1 }, label: 'Employer billing entity DESC' }, //TODO

                //{ id: 'rebatePeriod_asc', value: { '_id': 1 }, label: 'Rebate period ASC' }, //REMOVED
                //{ id: 'rebatePeriod_desc', value: { '_id': -1 }, label: 'Rebate period DESC' }, //REMOVED

                //{ id: 'employerInvoice_asc', value: { '_id': 1 }, label: 'Employer invoice ASC' }, //REMOVED
                //{ id: 'employerInvoice_desc', value: { '_id': -1 }, label: 'Employer invoice DESC' }, //REMOVED

                //{ id: 'agencyInvoice_asc', value: { '_id': 1 }, label: 'Agency invoice ASC' }, //REMOVED
                //{ id: 'agencyInvoice_desc', value: { '_id': -1 }, label: 'Agency invoice DESC' }, //REMOVED

                //{ id: 'invoices_asc', value: { '': 1 }, label: 'Invoices ASC' }, //TODO
                //{ id: 'invoices_desc', value: { '': -1 }, label: 'Invoices DESC' }, //TODO

                //{ id: 'documents_asc', value: { '': 1 }, label: 'Documents ASC' }, //TODO
                //{ id: 'documents_desc', value: { '': -1 }, label: 'Documents DESC' }, //TODO

                { id: 'startDate_asc', value: { 'employment.interval.start': 1 }, label: 'Start date ASC' },
                { id: 'startDate_desc', value: { 'employment.interval.start': -1 }, label: 'Start date DESC' },

                { id: 'endDate_asc', value: { 'employment.interval.end': 1 }, label: 'End date ASC' },
                { id: 'endDate_desc', value: { 'employment.interval.end': -1 }, label: 'End date DESC' },

                { id: 'updatedDate_asc', value: { 'events.updated': 1 }, label: 'Updated date ASC' },
                { id: 'updatedDate_desc', value: { 'events.updated': -1 }, label: 'Updated date DESC' },

                { id: 'createdDate_asc', value: { 'events.created': 1 }, label: 'Created date ASC' },
                { id: 'createdDate_desc', value: { 'events.created': -1 }, label: 'Created date DESC' }
            ]
        },

        'ramp.recruitment.note':
        {
            options:
            [
                { id: 'createdDate_asc', value: { 'events.created': 1, '_id': -1 }, label: 'ID DESC', default: true }
            ]
        },

        'ramp.insights.map': 
        {
            options:
            [
                { id: 'location_asc', value: { 'location.name': 1 }, label: 'Country ASC', default: true},
                { id: 'location_desc', value: { 'location.name': -1 }, label: 'Country DESC' },

                { id: 'junior_asc', value: { 'overall.10.base': 1 }, label: 'Junior percentile ASC' },
                { id: 'junior_desc', value: { 'overall.10.base': -1 }, label: 'Junior percentile DESC' },

                { id: 'intermediate_asc', value: { 'overall.50.base': 1 }, label: 'Intermediate percentile ASC' },
                { id: 'intermediate_desc', value: { 'overall.50.base': -1 }, label: 'Intermediate percentile DESC' },

                { id: 'senior_asc', value: { 'overall.90.base': 1 }, label: 'Senior percentile ASC' },
                { id: 'senior_desc', value: { 'overall.90.base': -1 }, label: 'Senior percentile DESC' },
                /*
                { id: '10%_asc', value: { 'overall.10.base': 1 }, label: '10% percentile ASC' },
                { id: '10%_desc', value: { 'overall.10.base': -1 }, label: '10% percentile DESC' },

                { id: '25%_asc', value: { 'overall.25.base': 1 }, label: '25% percentile ASC' },
                { id: '25%_desc', value: { 'overall.25.base': -1 }, label: '25% percentile DESC' },

                { id: '50%_asc', value: { 'overall.50.base': 1 }, label: '50% percentile ASC' },
                { id: '50%_desc', value: { 'overall.50.base': -1 }, label: '50% percentile DESC' },

                { id: '75%_asc', value: { 'overall.75.base': 1 }, label: '75% percentile ASC' },
                { id: '75%_desc', value: { 'overall.75.base': -1 }, label: '75% percentile DESC' },

                { id: '90%_asc', value: { 'overall.90.base': 1 }, label: '90% percentile ASC' },
                { id: '90%_desc', value: { 'overall.90.base': -1 }, label: '90% percentile DESC' },
                */
            ]
        },

        "ramp.messages.mailbox":
        {
            options:
            [
                { id: 'id_desc', value: { 'events.last': -1 }, label: 'Events lastMessage DESC', default: true }
            ]
        },

        "ramp.messages.livechat":
        {
            options:
            [
                { id: 'id_desc', value: { 'events.last': -1 }, label: 'Events lastMessage DESC', default: true }
            ]
        },

        "default":
        {
            options:
            [
                { id: 'id_desc', value: { '_id': -1 }, label: 'ID DESC', default: true }
            ]
        }
    },

    "search":
    {
        label    : 'Search',
        settings :
        {
            type        : 'input',
            visibility  : 'immediately',
            order       : 0,
            placeholder : 'Start typing...'
        },
        $_transform:
        {
            'default': ( query ) =>
            {
                return query;
            }
        }
    },

    // ------ SECTION IDS START ------
    "programmeID": //AGENCY LIST, JOB LIST, APPLICATION LIST
    {
        label    : 'Program',
        group    : 'settings',
        options  : [],
        settings :
        {
            type         : 'select',
            visibility   : 'modal',
            order        : 2,
            multiple     : true
        },
        $_fetch:
        {
            type : 'programme'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'                : ( ids ) => { return { 'jobProgrammeIDs': ids } },
            'ramp.recruitment.invitation'         : ( ids ) => { return { 'invitationProgrammeIDs': ids } },
            'ramp.recruitment.onboarding'         : ( ids ) => { return { 'onboardingInvitationProgrammeID': ids } },
            'ramp.recruitment.agency'             : ( ids ) => { return { 'organizationProgrammeIDs': ids } },
            'ramp.recruitment.application'        : ( ids ) => { return { 'jobProgrammeIDs': ids } },
            'ramp.recruitment.placement'          : ( ids ) => { return { 'placementProgrammeIDs': ids } },
            'ramp.recruitment.contract'           : ( ids ) => { return { 'contractProgrammeIDs': ids } },
            'ramp.recruitment.account'            : ( ids ) => { return { 'accountProgrammeIDs': ids } },
            'ramp.recruitment.document'           : ( ids ) => { return { 'documentProgrammeID': ids } }, //TODO
            'ramp.recruitment.mailbox'            : ( ids ) => { return { 'conversationProgrammeID': ids } }, //TODO

            //OLD FILTERS
            'ramp.recruitment.analytics.builder' : ( ids ) => { return { 'programmeIDs': ids }},
            'default'                            : ( ids ) => { return { 'programmeID': { '$in': ids.map( id => ({ '$oid': id })) } } }
        }
    },

    "programmeLocationID":
    {
        label    : 'Program location',
        group    : 'settings',
        options  : [],
        settings :
        {
            type       : 'select',
            visibility : 'immediately',
            multiple   : true
        },
        $_fetch:
        {
            type : 'programmeLocation'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'               : ( ids ) => { return { 'jobProgrammeLocationIDs': ids } },
            'ramp.recruitment.agency'            : ( ids ) => { return { 'organizationProgrammeLocationIDs': ids } }, //TODO ZATIAL IBA NA PROGRAMME
            'ramp.recruitment.application'       : ( ids ) => { return { 'jobProgrammeLocationIDs': ids } },
            'ramp.recruitment.placement'         : ( ids ) => { return { 'placementProgrammeLocationIDs': ids } },
            'ramp.recruitment.contract'          : ( ids ) => { return { 'contractProgrammeLocationIDs': ids } },
            'ramp.recruitment.document'          : ( ids ) => { return { 'documentProgrammeLocationIDs': ids } },
            'ramp.recruitment.mailbox'           : ( ids ) => { return { 'conversationProgrammeLocationIDs': ids } },

            //OLD FILTERS
            'ramp.recruitment.analytics.builder' : ( ids ) => { return { 'programmeLocationIDs': ids }},
            'default'                            : ( ids ) => { return { 'programmeLocationID' : { '$in': ids.map( id => ({ '$oid': id })) } } }
        },
        $_component:
        {
            option: () => import( '@/templates/components/SelectOptions/ProgrammeLocationOption.vue' )
        }
    },

    "agencyID": //JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label    : 'Agency',
        group    : 'settings',
        options  : [],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            multiple   : true,
            searchable : true
        },
        $_search:
        {
            type : 'organization'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'                   : ( ids ) => { return { 'jobAgencyIDs': ids } },
            'ramp.recruitment.invitation'            : ( ids ) => { return { 'invitationAgencyIDs': ids } },
            'ramp.recruitment.application'           : ( ids ) => { return { 'applicationAgencyIDs': ids } },
            'ramp.recruitment.placement'             : ( ids ) => { return { 'placementAgencyIDs': ids } },
            'ramp.recruitment.contract'              : ( ids ) => { return { 'contractAgencyIDs': ids } },
            'ramp.recruitment.recruiter'             : ( ids ) => { return { 'accountOrganizationIDs': ids } },
            'ramp.recruitment.document'              : ( ids ) => { return { 'documentOrganizationIDs': ids } },
            'ramp.recruitment.mailbox'               : ( ids ) => { return { 'conversationOrganizationIDs': ids } },
            'ramp.recruitment.organization.document' : ( ids ) => { return { 'documentOrganizationIDs': ids, documentExcludeTypes: ['cv'] } },

            //OLD FILTERS
            'ramp.recruitment.analytics.builder': ( ids ) => { return { 'agencyIDs': ids } },
            'default'                           : ( ids ) => { return { 'agencyID': { '$in': ids.map( id => ({ '$oid': id })) } } }
        }
    },

    "jobID":
    {
        label    : 'Job',
        group    : 'settings',
        options  : [],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            multiple   : true
        },
        $_search:
        {
            type : 'job'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job.invitation' : ( ids ) => { return { 'invitationJobIDs': ids } },
            'ramp.recruitment.job.engagement' : ( ids ) => { return { 'jobIDs': ids } },
            'ramp.recruitment.application'    : ( ids ) => { return { 'jobIDs': ids } },
            'ramp.recruitment.placement'      : ( ids ) => { return { 'placementJobIDs': ids } },
            'ramp.recruitment.contract'       : ( ids ) => { return { 'contractJobIDs': ids } },
            'ramp.recruitment.document'       : ( ids ) => { return { 'documentJobIDs': ids } },
            'ramp.recruitment.mailbox'        : ( ids ) => { return { 'conversationJobIDs': ids } },

            //OLD FILTERS
            //'ramp.recruitment.job.recruitment': ( ids ) => { return { '_root': { '_id' : { '$in': ids.map( id => ({ '$oid': id })) } } } },
            //'ramp.recruitment.agency'         : ( ids ) => { return { '_root': { '_id' : { '$in': ids.map( id => ({ '$oid': id })) } } } },
            'default'                         : ( ids ) => { return { 'jobID': { '$in': ids } } }
        }
    },

    "applicationID":
    {
        label    : 'Application',
        group    : 'settings',
        options  : [],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            multiple   : true
        },
        $_search:
        {
            type : 'application'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.placement' : ( ids ) => { return { 'placementApplicationIDs': ids } },
            'ramp.recruitment.contract'  : ( ids ) => { return { 'contractApplicationIDs': ids } },
            'ramp.recruitment.document'  : ( ids ) => { return { 'documentApplicationIDs': ids } },
            'ramp.recruitment.mailbox'   : ( ids ) => { return { 'conversationApplicationIDs': ids } },

            //OLD FILTERS
            'default'                    : ( ids ) => { return { 'applicationID': { '$in': ids } } }
        }
    },

    "placementID":
    {
        label    : 'Placement',
        group    : 'placement',
        options  : [],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            multiple   : true
        },
        $_search:
        {
            type : 'placement'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.placement.document' : ( ids ) => { return { 'documentPlacementIDs': ids } },
            'ramp.recruitment.placement.invoice'  : ( ids ) => { return { 'invoicePlacementIDs': ids } },
            'ramp.recruitment.mailbox'            : ( ids ) => { return { 'conversationPlacementIDs': ids } },

            //OLD FILTERS
            'default'                             : ( ids ) => { return { 'contractID': { '$in': ids } } }
        }
    },

    "contractID":
    {
        label    : 'Contract',
        group    : 'contract',
        options  : [],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            multiple   : true
        },
        $_search:
        {
            type : 'contract'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.contract.document' : ( ids ) => { return { 'documentContractIDs': ids } },
            'ramp.recruitment.contract.invoice'  : ( ids ) => { return { 'invoiceContractIDs': ids } },
            'ramp.recruitment.mailbox'           : ( ids ) => { return { 'conversationContractIDs': ids } },

            //OLD FILTERS
            'default'                            : ( ids ) => { return { 'contractID': { '$in': ids } } }
        }
    },
    // ------ SECTION IDS END ------


    // ------ OTHER IDS START ------
    "personID": // APPLICATION USER ( PERSON API )
    {
        label    : 'User',
        group    : 'settings',
        options  : [],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            multiple   : true
        },
        $_search:
        {
            type : 'account'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.application' : ( ids ) => { return { 'applicationCandidateIDs': ids } },
            'ramp.recruitment.placement'   : ( ids ) => { return { 'placementEmployeeIDs': ids } },
            'ramp.recruitment.contract'    : ( ids ) => { return { 'contractContractorIDs': ids } },

            //OLD FILTERS
            'default'                             : ( ids ) => { return  { '_root': { '_id': { '$in': ids.map( id => ({ '$oid': id })) } } } },
        }
    },

    "accountManagerID": // RAMP ACCOUNT MANAGER ( ACCOUNT API )
    {
        label    : 'Account manager',
        group    : 'settings',
        options  : [],
        query    : { type: ['admin'] },
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            order      : 2,
            multiple   : true
        },
        $_search:
        {
            type : 'account'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'         : ( ids ) => { return { 'jobAccountManagerIDs': ids } },
            'ramp.recruitment.application' : ( ids ) => { return { 'jobAccountManagerIDs': ids } },
            'ramp.recruitment.placement'   : ( ids ) => { return { 'placementAccountManagerIDs': ids } },
            'ramp.recruitment.contract'    : ( ids ) => { return { 'contractAccountManagerIDs': ids } },

            //OLD FILTERS
            'ramp.recruitment.analytics.builder'  : ( ids ) => { return { 'accountManagerIDs': ids }},
            'default'                             : ( ids ) => { return { 'contacts.accountManagerIDs': { '$in': ids.map( id => ({ '$oid': id })) } } },
        }
    },

    "recruiterID": // RECRUITER USER ( ACCOUNT API )
    {
        label    : 'Recruiter',
        group    : 'settings',
        options  : [],
        query    : { type: ['recruiter'] },
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            multiple   : true
        },
        $_search:
        {
            type : 'account'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'         : ( ids ) => { return { 'jobRecruiterAccountIDs': ids } },
            'ramp.recruitment.application' : ( ids ) => { return { 'applicationRecruiterIDs': ids } },
            'ramp.recruitment.placement'   : ( ids ) => { return { 'placementRecruiterAccountIDs': ids } },
            'ramp.recruitment.contract'    : ( ids ) => { return { 'contractRecruiterAccountIDs': ids } },

            //OLD FILTERS
            'ramp.recruitment.analytics.builder'  : ( ids ) => { return { 'recruiterIDs': ids }},
            'default'                             : ( ids ) => { return { 'recruiterID': { '$in': ids.map( id => ({ '$oid': id })) } } },
        }
    },

    "employerID": // EMPLOYER USER ( ACCOUNT API )
    {
        label    : 'Job manager',
        group    : 'settings',
        options  : [],
        query    : { type: ['employer'] },
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            multiple   : true
        },
        $_search:
        {
            type : 'account'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'         : ( ids ) => { return { 'jobEmployerAccountIDs': ids } },
            'ramp.recruitment.application' : ( ids ) => { return { 'jobEmployerAccountIDs': ids } },
            'ramp.recruitment.placement'   : ( ids ) => { return { 'placementEmployerAccountIDs': ids } },
            'ramp.recruitment.contract'    : ( ids ) => { return { 'contractEmployerAccountIDs': ids } },

            //OLD FILTERS
            'ramp.recruitment.analytics.builder'  : ( ids ) => { return { 'userIDs': ids }},
            'default'                             : ( ids ) => { return { 'employerID': { '$in': ids.map( id => ({ '$oid': id })) } } },
        }
    },
    
    "userID": //JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST ...... ( LIST GLOBAL USER ( ACCOUNT API ) => EMPLOYER, RECRUITER, ACCOUNT MANAGER )
    {
        label    : 'User',
        group    : 'settings',
        options  : [],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            multiple   : true
        },
        $_search:
        {
            type : 'account'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'               : ( ids ) => { return { 'jobEmployerAccountIDs': ids } },
            'ramp.recruitment.invitation'        : ( ids ) => { return { 'invitationRecruiterAccountIDs': ids } },
            'ramp.recruitment.application'       : ( ids ) => { return { 'applicationRecruiterIDs': ids } },
            'ramp.recruitment.placement'         : ( ids ) => { return { 'placementRecruiterIDs': ids } },
            'ramp.recruitment.contract'          : ( ids ) => { return { 'contractRecruiterIDs': ids } },

            //OLD FILTERS
            'ramp.recruitment.analytics.builder' : ( ids ) => { return { 'userIDs': ids }},
            'default'                            : ( ids ) => { return  { '_root': { '_id': { '$in': ids.map( id => ({ '$oid': id })) } } } },
        }
    },

    "categoryID": //AGENCY LIST, JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label    : 'Category',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'category'
        },
        $_validator: ( ids ) =>
        {
            if( Array.isArray( ids ) )
            {
                return ids.map( id => parseInt( id ) );
            }
            else
            {
                return parseInt( ids );
            }
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'              : ( ids ) => { return { 'jobCategoryIDs': ids } },
            'ramp.recruitment.invitation'       : ( ids ) => { return { 'invitationJobCategoryIDs': ids } },
            'ramp.recruitment.agency'           : ( ids ) => { return { 'organizationJobCategoryIDs': ids } },
            'ramp.recruitment.application'      : ( ids ) => { return { 'jobCategoryIDs': ids } },
            'ramp.recruitment.placement'        : ( ids ) => { return { 'placementCategoryIDs': ids } },
            'ramp.recruitment.contract'         : ( ids ) => { return { 'contractCategoryIDs': ids } },

            //OLD FILTERS
            'ramp.recruitment.analytics.builder': ( ids ) => { return { 'categoryIDs': ids } },
            'default'                           : ( ids ) => { return { 'categoryID': { '$in': ids } } }
        }
    },

    "skillID": //AGENCY LIST, JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label    : 'Skill',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            multiple    : true
        },
        $_search:
        {
            type : 'skill'
        },
        $_validator: ( ids ) =>
        {
            if( Array.isArray( ids ) )
            {
                return ids.map( id => parseInt( id ) );
            }
            else
            {
                return parseInt( ids );
            }
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'              : ( ids ) => { return { 'jobSkillIDs': ids } },
            'ramp.recruitment.invitation'       : ( ids ) => { return { 'invitationJobSkillIDs': ids } },
            'ramp.recruitment.agency'           : ( ids ) => { return { 'organizationSkillIDs': ids } },
            'ramp.recruitment.application'      : ( ids ) => { return { 'applicationSkillIDs': ids } },
            'ramp.recruitment.placement'        : ( ids ) => { return { 'placementSkillIDs': ids } },
            'ramp.recruitment.contract'         : ( ids ) => { return { 'contractSkillIDs': ids } },

            //OLD FILTERS
            'ramp.recruitment.analytics.builder': ( ids ) => { return { 'skillIDs': ids } },
            'default'                           : ( ids ) => { return { 'skillID': { '$in': ids } } }
        }
    },

    "locationID": //JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label    : 'Location',
        group    : 'settings',
        options  : [],
        query    : { precision: 'any' },
        settings :
        {
            class       : 'tree',
            type        : 'select',
            visibility  : 'modal',
            multiple    : true
        },
        $_search:
        {
            type : 'location'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'              : ( ids ) => { return { 'jobLocationIDs': ids } },
            'ramp.recruitment.invitation'       : ( ids ) => { return { 'invitationJobLocationIDs': ids } },
            'ramp.recruitment.application'      : ( ids ) => { return { 'applicationLocationIDs': ids } },
            'ramp.recruitment.placement'        : ( ids ) => { return { 'placementLocationIDs': ids } },
            'ramp.recruitment.contract'         : ( ids ) => { return { 'contractLocationIDs': ids } },

            //OLD FILTERS
            'ramp.insights.map'                 : ( ids ) => { return { 'locationID': { '$in': ids } } },
            'ramp.recruitment.analytics.builder': ( ids ) => { return { 'locationIDs': ids } },
            'default'                           : ( ids ) => { return { 'locationHierarchyIDs': { '$in': ids } }}
        },
        $_component:
        {
            option: () => import( '@/templates/components/SelectOptions/LocationOption.vue' )
        }
    },

    "countryID": //ANALYTICS
    {
        label    : 'Area & Country',
        group    : 'settings',
        options  : [],
        query    : { precision: 'areas' },
        settings :
        {
            class       : 'tree',
            type        : 'select',
            visibility  : 'modal',
            multiple    : true
        },
        $_search:
        {
            type : 'location'
        },
        $_validator: ( ids ) =>
        {
            if( Array.isArray( ids ) )
            {
                return ids.map( id => id );
            }
            else
            {
                return ids;
            }
        },
        $_transform:
        {
            //OLD FILTERS
            'ramp.insights.map'                 : ( ids ) => { return { 'locationID': { '$in': ids } } },
            'ramp.recruitment.analytics.builder': ( ids ) => { return { 'locationIDs': ids } },
            'default'                           : ( ids ) => { return { 'locationHierarchyIDs': { '$in': ids } }}
        },
        $_component:
        {
            option: () => import( '@/templates/components/SelectOptions/LocationOption.vue' )
        }
    },

    "locationBasedID": //AGENCY LIST
    {
        label    : 'Based in',
        group    : 'settings',
        options  : [],
        query    : { precision: 'areas' },
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            multiple    : true
        },
        $_search:
        {
            type : 'location'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.agency' : ( ids ) => { return { 'organizationLocationBasedIDs': ids } },

            //OLD FILTERS
            'default': ( ids ) => { return { 'locationID': { '$in': ids } } }
        },
        $_component:
        {
            option: () => import( '@/templates/components/SelectOptions/LocationOption.vue' )
        }
    },

    "locationCoverageID": //AGENCY LIST
    {
        label    : 'Location coverage',
        group    : 'settings',
        options  : [],
        query    : { precision: 'areas' },
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            multiple    : true,
        },
        $_search:
        {
            type : 'location'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.agency' : ( ids ) => { return { 'organizationLocationCoverageIDs': ids } },

            //OLD FILTERS
            'default': ( ids ) => { return { 'specialism.locationIDs': { '$in': ids } } }
        },
        $_component:
        {
            option: () => import( '@/templates/components/SelectOptions/LocationOption.vue' )
        }
    },

    "nationalityID": //APPLICATION LIST
    {
        label    : 'Nationality',
        group    : 'settings',
        options  : [],
        query    : { precision: 'countries' },
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            multiple    : true
        },
        $_search:
        {
            type : 'location'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.application': ( ids ) => { return { 'applicationNationalityIDs': ids } },
        },
        $_component:
        {
            option: () => import( '@/templates/components/SelectOptions/LocationOption.vue' )
        }
    },

    "languageID": //JOB LIST
    {
        label    : 'Language',
        group    : 'settings',
        options  : [],
        query    : {},
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            multiple    : true
        },
        $_search:
        {
            type : 'language'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'        : ( ids ) => { return { 'jobLanguageIDs': ids } },
            'ramp.recruitment.invitation' : ( ids ) => { return { 'invitationJobLanguageIDs': ids } },
        }
    },

    "jobTitleID":
    {
        label    : 'Job title',
        group    : 'settings',
        options  : [],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            searchable : true,
            multiple   : false
        },
        $_search:
        {
            type : 'insightsJobs'
        },
        $_transform:
        {
            //OLD FILTERS
            'default' : ( ids ) => { return { 'jobTitleID': parseInt( ids[0] ) } }
        }
    },

    "tagID": //AGENCY LIST
    {
        label    : 'Tag',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            multiple    : true
        },
        $_search:
        {
            type : 'tag'
        },
        $_transform:
        {
            'default': ( ids ) => { return { 'organizationTagIDs': ids } }
        }
    },
    // ------ OTHER IDS END ------


    // ------ TYPES START ------
    "contractType": //AGENCY LIST, JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label    : 'Contract type',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'contractType'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'         : ( ids ) => { return { 'jobContractTypes': ids } },
            'ramp.recruitment.invitation'  : ( ids ) => { return { 'invitationJobContractTypes': ids } },
            'ramp.recruitment.agency'      : ( ids ) => { return { 'organizationContractTypes': ids } },
            'ramp.recruitment.application' : ( ids ) => { return { 'applicationContractTypes': ids } },
            'ramp.recruitment.placement'   : ( ids ) => { return { 'placementContractTypes': ids } },
            'ramp.recruitment.contract'    : ( ids ) => { return { 'contractContractTypes': ids } },

            //OLD FILTERS
            //'ramp.recruitment.placement'   : ( ids ) => { return { 'employment.type': { '$in': ids }  } },
            'default'                      : ( ids ) => { return { 'contractType': { '$in': ids } } }
        }
    },

    "organisationType":
    {
        label    : 'Organisation type',
        group    : 'settings',
        options  : [ { id: 'employer', label: 'Employer' }, { id: 'agency', label: 'Agency' } ],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : false,
            multiple    : true,
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( ids ) => { return { 'organizationTypes': ids } }
        }
    },

    "accountType":
    {
        label    : 'Account type',
        group    : 'settings',
        options  : [{ id: 'admin', label: 'Account manager' }, { id: 'recruiter', label: 'Recruiter' }, { id: 'employer', label: 'Employer' }],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : false,
            multiple    : true,
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( ids ) => { return { 'accountTypes': ids } }
        }
    },

    "engagementType":
    {
        label    : 'Engagement type',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'engagementType'
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( ids ) => { return { 'jobEngagementStatuses': ids } }
        }
    },
    // ------ TYPES END ------


    // ------ FIELDS START ------
    "signedCoC": //AGENCY LIST
    {
        label    : 'Signed Code of Conduct',
        group    : 'settings',
        options  : [{ id: true, label: 'Yes' }, { id: false, label: 'No' }],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : false,
            multiple    : false,
            deselectable: true
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.agency': ( ids ) => { return { 'organizationSignedCoC': ids } },

            //OLD FILTERS
            'default': ( value ) => { return { 'CoC': value } } //TODO
        }
    },

    "remoteWork": //JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label    : 'Is working remotely',
        group    : 'settings',
        options  : [{ id: true, label: "Yes" }, { id: false, label: "No" }],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : false,
            multiple    : false,
            deselectable: true
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'         : ( ids ) => { return { 'jobRemoteWork': ids } },
            'ramp.recruitment.invitation'  : ( ids ) => { return { 'invitationJobRemoteWork': ids } },
            'ramp.recruitment.application' : ( ids ) => { return { 'jobRemoteWork': ids } },
            'ramp.recruitment.placement'   : ( ids ) => { return { 'placementRemoteWork': ids } },
            'ramp.recruitment.contract'    : ( ids ) => { return { 'contractRemoteWork': ids } },

            //OLD FILTERS
            'default'               : ( ids ) => { return { 'remote': { '$in': ids } } }
        }
    },

    "independentContractor":
    {
        label    : 'Independent contractor',
        group    : 'settings',
        options  : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : false,
            multiple    : false,
            deselectable: true
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( value ) =>
            {
                if( Array.isArray( value ) )
                { value = value[0]; }

                return { 'contractIndependentContractor': value !== 'yes' }
            }
        }
    },

    "experience":
    {
        label    : 'Experience',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true,
        },
        $_fetch:
        {
            type : 'experience'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.application' : ( ids ) => { return { 'applicationExperiences': ids } },
            'ramp.recruitment.placement'   : ( ids ) => { return { 'placementExperiences': ids } },
            'ramp.recruitment.contract'    : ( ids ) => { return { 'contractExperiences': ids } },

            //OLD FILTERS
            'default': ( ids ) => { return { 'experience': { '$in': ids } } }
        }
    },

    "gender": //APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label    : 'Gender',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'gender'
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.application' : ( ids ) => { return { 'applicationGenders': ids } },
            'ramp.recruitment.placement'   : ( ids ) => { return { 'placementGenders': ids } },
            'ramp.recruitment.contract'    : ( ids ) => { return { 'contractGenders': ids } },

            //OLD FILTERS
            'default': ( ids ) =>
            {
                let result = {};

                let genderMap =
                {
                    'male'   : { $in: ['mr'] },
                    'female' : { $in: [ 'mrs', 'miss', 'ms' ] },
                    'other'  : { $in: [ 'mx' ] },
                    'unknown': { $not: { $in: [ 'mr', 'mrs', 'miss', 'ms', 'mx' ] } }
                }

                if( !Array.isArray( ids ) )
                {
                    ids = [ ids ];
                }

                if( ids.length > 1 )
                {
                    result = { 'candidate.title' : { $or: [] } };

                    for( let id of ids ) { result['candidate.title'].$or.push( genderMap[ id ] ); }
                }
                else
                {
                    result = { 'candidate.title': genderMap[ ids[0] ] };
                }

                return result;
            }
        }
    },
    // ------ FIELDS END ------


    // ------ STATUSES START ------
    "jobStatus": //JOB LIST
    {
        label    : 'Requisition status',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'jobStatus'
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( ids ) => { return { 'jobStatuses': ids } }
        }
    },

    "jobStageStatus": //JOB LIST
    {
        label    : 'Stage',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'jobStageStatus'
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( ids ) => { return { 'jobFurthestStages': ids } }
        }
    },

    "invitationStatus":
    {
        label    : 'Status',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'invitationStatus'
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( ids ) => { return { 'invitationStatuses': ids } }
        }
    },

    "applicationStatus": //APPLICATION LIST
    {
        label    : 'Application status',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'applicationStatus'
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( ids ) => { return { 'applicationStatuses': ids } },
        }
    },

    "placementStatus": //PLACEMENT LIST
    {
        label    : 'Placement status',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'placementStatus'
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( ids ) => { return { 'placementStatuses': ids } }
        }
    },

    "contractStatus": //CONTRACT LIST
    {
        label    : 'Contract status',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'select',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true
        },
        $_fetch:
        {
            type : 'contractStatus'
        },
        $_transform:
        {
            //SMART FILTERS
            'default': ( ids ) => { return { 'contractStatuses': ids } }
        }
    },
    // ------ STATUSES END ------


    // ------ DATEPICKERS START ------
    "createdDate": //AGENCY LIST, JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label     : 'Created date',
        group     : 'date',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal',
            removeable  : true
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'          : ( range ) => { return { 'jobCreatedBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.invitation'   : ( range ) => { return { 'invitationCreatedBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.agency'       : ( range ) => { return { 'organizationCreatedBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.application'  : ( range ) => { return { 'applicationCreatedBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.placement'    : ( range ) => { return { 'placementCreatedBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.contract'     : ( range ) => { return { 'contractCreatedBetween': { from: range[0], to: range[1] } } },

            //OLD FILTERS
            'default'                 : ( range ) => { return { '$activeBetween': { from: range[0], to: range[1] } } }
        }
    },

    "updatedDate": //APPLICATION LIST, JOB LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label     : 'Updated date',
        group     : 'date',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal',
            removeable  : true
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'          : ( range ) => { return { 'jobUpdatedBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.invitation'   : ( range ) => { return { 'invitationUpdatedBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.application'  : ( range ) => { return { 'applicationUpdatedBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.placement'    : ( range ) => { return { 'placementUpdatedBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.contract'     : ( range ) => { return { 'contractUpdatedBetween': { from: range[0], to: range[1] } } },


            //OLD FILTERS
            'default': ( range ) => { return { '$activeBetween': { from: range[0], to: range[1] } } } //TODO
        }
    },

    "startDate": //JOB LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label     : 'Start date',
        group     : 'date',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal',
            removeable  : true
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'        : ( range ) => { return { 'jobEmploymentStartBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.placement'  : ( range ) => { return { 'placementStartBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.contract'   : ( range ) => { return { 'contractStartBetween': { from: range[0], to: range[1] } } },

            //OLD FILTERS
            'default'               : ( range ) => { return { '$activeBetween': { from: range[0], to: range[1] } } } //TODO
        }
    },

    "endDate": //JOB LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label     : 'End date',
        group     : 'date',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal',
            removeable  : true
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'        : ( range ) => { return { 'jobEmploymentEndBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.placement'  : ( range ) => { return { 'placementEndBetween': { from: range[0], to: range[1] } } },
            'ramp.recruitment.contract'   : ( range ) => { return { 'contractEndBetween': { from: range[0], to: range[1] } } },

            //OLD FILTERS
            'default'               : ( range ) => { return { '$activeBetween': { from: range[0], to: range[1] } } } //TODO
        }
    },

    // ------ DATEPICKERS END ------


    // ------ RANGES START ------
    "agencyFeeRange": //AGENCY LIST, JOB LIST, CONTRACT LIST
    {
        label     : 'Agency fee',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'        : ( range ) => { return { 'jobAgencyFeeCountRange'          : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.invitation' : ( range ) => { return { 'invitationAgencyFeeCountRange'   : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.agency'     : ( range ) => { return { 'organizationAgencyFeeCountRange' : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.contract'   : ( range ) => { return { 'contractAgencyFeeCountRange'     : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "agencyHandlingFeeRange": //JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label     : 'Agency handling fee',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'         : ( range ) => { return { 'jobAgencyHandlingFeeCountRange'         : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.application' : ( range ) => { return { 'applicationAgencyHandlingFeeCountRange' : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.placement'   : ( range ) => { return { 'placementAgencyHandlingFeeCountRange'   : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.contract'    : ( range ) => { return { 'contractAgencyHandlingFeeCountRange'    : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "employerHandlingFeeRange": //JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label     : 'Employer handling fee',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'          : ( range ) => { return { 'jobEmployerHandlingFeeCountRange'         : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.application'  : ( range ) => { return { 'applicationEmployerHandlingFeeCountRange' : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.placement'    : ( range ) => { return { 'placementEmployerHandlingFeeCountRange'   : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.contract'     : ( range ) => { return { 'contractEmployerHandlingFeeCountRange'    : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "fillRateRange": //AGENCY LIST
    {
        label     : 'Fill rate',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.agency'  : ( range ) => { return { 'organizationFillRateCountRange': { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "recruitersOnboardedRange": //AGENCY LIST
    {
        label     : 'Recruiters Onboarded',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.agency': ( range ) => { return { 'organizationRecruitersCountRange': { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "totalJobsRange": //AGENCY LIST
    {
        label     : 'Total jobs',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.agency': ( range ) => { return { 'organizationTotalJobsCountRange': { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "totalApplicationsRange": //AGENCY LIST, JOB LIST
    {
        label     : 'Total applications',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'  : ( range ) => { return { 'jobTotalApplicationsCountRange'           : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.agency': ( range ) => { return { 'organizationTotalApplicationsCountRange' : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "totalPlacementsRange": //AGENCY LIST
    {
        label     : 'Total placements',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.agency': ( range ) => { return { 'organizationTotalPlacementsCountRange': { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "totalContractorsRange": //AGENCY LIST
    {
        label     : 'Total contractors',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.agency': ( range ) => { return { 'organizationTotalContractorsCountRange': { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "totalPositionsRange": //JOB LIST
    {
        label     : 'Total positions',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'  : ( range ) => { return { 'jobTotalPositionsCountRange': { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "totalEngagementsRange": //JOB LIST
    {
        label     : 'Total engagements',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'  : ( range ) => { return { 'jobTotalEngagementsCountRange': { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "rebatePeriodRange": //PLACEMENT LIST
    {
        label     : 'Rebate period',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.placement'  : ( range ) => { return { 'placementRebatePeriodCountRange' : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.contract'   : ( range ) => { return { 'contractRebatePeriodCountRange'  : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            //OLD FILTERS
            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },

    "salaryRange": //JOB LIST, APPLICATION LIST, PLACEMENT LIST, CONTRACT LIST
    {
        label     : 'Salary range',
        group     : 'range',
        range     : { min: 0, max: 100, step: 1 },
        settings  :
        {
            type        : 'range',
            visibility  : 'modal'
        },
        $_formatter: ( value ) =>
        {
            return value + '%';
        },
        $_transform:
        {
            //SMART FILTERS
            'ramp.recruitment.job'          : ( range ) => { return { 'jobSalaryCountRange'         : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.invitation'   : ( range ) => { return { 'invitationSalaryCountRange'  : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.application'  : ( range ) => { return { 'applicationSalaryCountRange' : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.placement'    : ( range ) => { return { 'placementSalaryCountRange'   : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },
            'ramp.recruitment.contract'     : ( range ) => { return { 'contractSalaryCountRange'    : { min: parseFloat(range[0]), max: parseFloat(range[1]) } } },

            'default': ( range ) => { return { 'salary': { $gte: range[0], $lte: range[1] } } } //TODO
        }
    },
    // ------ RANGES END ------


    // ------ ANALYTICS START ------
    "dateRange":
    {
        label     : 'Date range',
        group     : 'settings',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal',
            removeable  : false
        },
        $_transform:
        {
            //OLD FILTERS
            'ramp.recruitment.analytics.builder': ( range ) => { return { 'dateRange': range } },
            'default': ( range ) => { return { '$activeBetween': { from: range[0], to: range[1] } } }
        }
    },
    // ------ ANALYTICS END ------

    // ------ INSIGHTS MAP START ------
    "employmentTypeStatic":
    {
        label    : 'Employment type',
        group    : 'settings',
        options  : [{ id: 'permanent', label : 'Permanent' }, { id: 'contractor', label : 'Contractor' }],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            searchable : false,
            multiple   : false
        },
        $_transform:
        {
            //OLD FILTERS
            'default' : ( ids ) => { return { 'employmentType': ids[0] } }
        }
    },

    "intervalStatic":
    {
        label    : 'Interval',
        group    : 'settings',
        options  : [{ id: 'annual', label : 'Annual' }, { id: 'monthly', label : 'Monthly' }, { id: 'daily', label : 'Daily' }, { id: 'hourly', label: 'Hourly'}],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            searchable : false,
            multiple   : false
        },
        $_transform:
        {
            //OLD FILTERS
            'default' : ( ids ) => { return { 'interval': ids[0] } }
        }
    },

    "seniority":
    {
        label    : 'Seniority',
        group    : 'settings',
        options  : [{ id: 'junior', label : 'Junior' }, { id: 'intermediate', label : 'Intermediate' }, { id: 'senior', label : 'Senior' }],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            searchable : false,
            multiple   : false
        },
        $_transform:
        {
            //OLD FILTERS
            'default' : ( ids ) => { return { 'seniority': ids[0] } }
        }
    },
    // ------ INSIGHTS MAP END ------

    "companyJobTracker":
    {
        label    : 'Company',
        group    : 'settings',
        options  : [
            { id: 'PPG', label : 'PPG' }, 
            { id: 'PanasonicAvionics', label : 'Panasonic' },
            { id: 'AIG', label : 'AIG' },
            { id: 'Canon', label : 'Canon' },
            { id: 'Alight', label : 'Alight' },
            { id: 'AlightStrada', label : 'Strada' },
            { id: 'SoftwareOne', label : 'SoftwareOne' },
            { id: 'SiemensGlobal', label : 'Siemens' },
            { id: 'SopraSteria', label : 'SopraSteria' },
            { id: 'YunexTraffic', label : 'Yunex Traffic' },
            { id: 'CGI', label : 'CGI' },

            { id: 'saltchuk', label : 'Saltchuk' },
            { id: 'grab', label : 'Grab' },
            { id: 'github', label : 'GitHub' },
            
            { id: 'balsambrands', label : 'Balsam Brands' },
            { id: 'littleCaesars', label : 'Little Caesars' },

            { id: 'euroapi', label : 'Euroapi' },
            { id: 'integraladscience', label : 'Integral Ad Science' },
            { id: 'ironclad', label : 'Ironclad' },
            { id: 'Glaukos', label : 'Glaukos' },
            { id: 'Nozomi', label : 'Nozomi' },
            { id: 'EthosGroup', label : 'EthosGroup' },

            { id: 'GamesWorkshop', label: 'Games Workshop' },
            { id: 'GammaCommunications', label: 'Gamma Communications' },
            { id: 'Kainos', label: 'Kainos' },
            { id: 'Qinetiq', label: 'Qinetiq' },
            { id: 'Genus', label: 'Genus' },
            { id: 'rotork', label: 'Rotork' },
            { id: 'future', label: 'Future' },
            { id: 'clarksons', label: 'Clarksons' },
            { id: 'hunting', label: 'Hunting' },
            { id: 'harbourEnergy', label: 'Harbour Energy' },
            { id: 'unipharMedtech', label: 'Uniphar Medtech' },

            { id: 'recipharm', label: 'Recipharm' },
            { id: 'simcorp', label: 'SimCorp' },
            { id: 'cint', label: 'Cint' },
            { id: 'nexerGroup', label: 'Nexer Group' },
            { id: 'arjo', label: 'Arjo' },
            { id: 'ikm', label: 'IKM Consultants' },
            { id: 'tpicap', label: 'TP ICAP' },

            { id: 'pieInsurance', label: 'Pie Insurance' },
            { id: 'twilio', label: 'Twilio' },
            { id: 'inizio', label: 'Inizio' },

            { id: 'accommodationsPlusInternational', label : 'API' },
            { id: 'blacklineSafety', label : 'Blackline Safety' },
            { id: 'zensa', label : 'Zensa' },

            { id: 'kiteworks', label : 'Kiteworks' },
            { id: 'wellBoss', label : 'WellBoss' },
            { id: 'evreka', label : 'Evreka' }
        ],
        settings :
        {
            type       : 'select',
            visibility : 'modal',
            searchable : false,
            multiple   : false,
            clearable  : false
        },
        $_transform:
        {
            'default' : ( status ) => { return { 'companies': status } }
        }
    },
    
    //SMART FILTERS
    /*
    "contractStartDate":
    {
        label     : 'Started within',
        group     : 'date',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal',
            removeable  : true
        },
        $_transform:
        {
            //OLD FILTERS
            'default': ( range ) => { return { 'employment.interval.start': { $gte: Format.stringToDate( range[0], 'dd/mm/yyyy', '/' ), $lt: Format.stringToDate( range[1], 'dd/mm/yyyy', '/' ) } } },
        }
    },

    "contractEndDate":
    {
        label     : 'Ended within',
        group     : 'date',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal',
            removeable  : true
        },
        $_transform:
        {
            //OLD FILTERS
            'default': ( range ) => { return { 'employment.interval.end': { $gte: Format.stringToDate( range[0], 'dd/mm/yyyy', '/' ), $lt: Format.stringToDate( range[1], 'dd/mm/yyyy', '/' ) } } },
        }
    },

    "createdBetween":
    {
        label     : 'Created date',
        group     : 'date',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal',
            removeable  : true
        },
        $_transform:
        {
            //OLD FILTERS
            'default': ( range ) =>
            {
                let $_range = { startDate: Format.stringToDate( range[0], 'dd/mm/yyyy', '/' ), endDate: Format.stringToDate( range[1], 'dd/mm/yyyy', '/' ) };

                return ({ 'events.created': { $gte: $_range.startDate, $lt: $_range.endDate } });
            }
        }
    },

    "positionBetween":
    {
        label     : 'Open date',
        group     : 'date',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal',
            removeable  : true
        },
        $_transform:
        {
            //OLD FILTERS
            'ramp.recruitment.analytics.builder': ( range ) => { return { 'dateRange': range } },

            'default': ( range ) =>
            {
                let $_range = { startDate: range[0], endDate: range[1] };

                return (
                {
                    $or:
                    [
                        { 'positions.events.opened'  : { $gte: $_range.startDate, $lt: $_range.endDate }},
                        { 'positions.events.closed'  : { $gte: $_range.startDate, $lt: $_range.endDate }},
                        { 'positions.events.opened'  : { $lt: $_range.startDate }, 'positions.events.closed' : { $gt: $_range.startDate }}, //TODO elem Match
                        { 'positions.events.opened'  : { $lt: $_range.startDate }, 'positions.events.closed' : { $exists: false }},
                    ]
                });
            }
        }
    },

    "$activeBetween":
    {
        label     : 'Active between',
        group     : 'settings',
        settings  :
        {
            type        : 'datepicker',
            visibility  : 'modal'
        },
        $_transform:
        {
            'ramp.recruitment.contract': ( range ) =>
            {
                return (
                {
                    'employment.interval.start' : { $lte: Format.stringToDate( range[1], 'dd/mm/yyyy', '/' ) },
                    'employment.interval.end'   : { $gte: Format.stringToDate( range[0], 'dd/mm/yyyy', '/' ) }
                })
            },
            'default': ( range ) =>
            {
                return { '$activeBetween': { from: Format.stringToDate( range[0], 'dd/mm/yyyy', '/' ), to: Format.stringToDate( range[1], 'dd/mm/yyyy', '/' ) } }
            }
        }
    },

    "$jobStatusAt":
    {
        label    : 'Status at',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'tag',
            visibility  : 'read-only',
            removeable  : true
        },
        $_fetch:
        {
            type : 'jobStatus'
        },
        $_transform:
        {
            'default': ( data ) => { return { '$statusAt': { values: data[0].split(','), date: Format.stringToDate( data[1], 'dd/mm/yyyy', '/' ) } } }
        }
    },

    "$applicationStatusAt":
    {
        label    : 'Status at',
        group    : 'settings',
        options  : [],
        settings :
        {
            type        : 'input',
            visibility  : 'modal',
            searchable  : true,
            multiple    : true,
        },
        $_fetch:
        {
            type : 'jobStatus'
        },
        $_transform:
        {
            'default': ( ids ) => { return { '$statusAt': { values: data[0].split(','), date: data[1] } } }
        }
    },
    */
}

export const FilterGroups =
[
    { id: 'settings', label: 'Basic filters', icon: 'isax-filter5' },
    { id: 'range', label: 'Range filters', icon: 'isax-arrange-circle-25' },
    { id: 'date', label: 'Date filters', icon: 'isax-calendar5' }
];

const $_updateFilter = ( settings, ctx ) =>
{
    let $_filter = {}, activeFilter = settings.filters.find( p => p.active );

    if( activeFilter )
    {
        for( let [ label, value ] of Object.entries( activeFilter.filters ) )
        {
            if( ctx.ListOptions.filter[label] && value !== undefined && value !== null )
            {
                let $_value = ctx.ListOptions.filter[ label ].settings.multiple && !Array.isArray( value ) ? [ value ] : value;

                if( ctx.ListOptions.filter[label].hasOwnProperty('validator') )
                {
                    $_value = ctx.ListOptions.filter[label].validator( $_value );
                }

                $_filter[label] = $_value;
            }
        }
    }

    Object.assign( Helpers.ObjectClear( ctx.ListData.filter ), $_filter );
}

const $_combineFilters = ( ...filters ) =>
{
    let mergedFilter = {};

    filters.forEach( ( filter ) => { mergedFilter = ObjectLib.merge( mergedFilter, filter ); });

    return mergedFilter;
}

export const FilterPreset =
{
    create( settings, ctx )
    {
        if( !settings.filters ){ settings.filters = []; }

        for( let [ label, value ] of Object.entries( ctx.filter.filters ) )
        {
            if( Array.isArray( value ) && !value.length )
            {
                delete ctx.filter.filters[ label ];
            }
            else if( typeof value === "string" && !value.length )
            {
                delete ctx.filter.filters[ label ];
            }
        }

        if( Object.keys( ctx.filter.filters ).length )
        {
            settings.filters.push( ctx.filter );

            Helpers.Storage( undefined, 'set', ctx.storagePath, JSON.stringify( settings ) );
        }
    },

    update( settings, ctx )
    {
        let currPresetIndex = settings.filters.findIndex( p => p.id === ctx.filter.id );

        for( let [ label, value ] of Object.entries( ctx.filter.filters ) )
        {
            if( Array.isArray( value ) && !value.length )
            {
                delete ctx.filter.filters[ label ];
            }
            else if( typeof value === "string" && !value.length )
            {
                delete ctx.filter.filters[ label ];
            }
        }

        if( Object.keys( ctx.filter.filters ).length )
        {
            settings.filters[currPresetIndex] = ctx.filter;
        }
        else
        {
            settings.filters.splice( currPresetIndex, 1 );
        }

        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));
    },

    delete( settings, ctx )
    {
        let currPresetIndex = settings.filters.findIndex( p => p.id === ctx.filter.id );

        settings.filters.splice( currPresetIndex, 1 );

        ctx.filter.active && $_updateFilter( settings, ctx );

        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));
    },

    change( settings, ctx )
    {
        for( let filter of settings.filters )
        {
            filter.active = filter.id === ctx.id;
        }

        let customPreset = settings.filters.find( p => p.id === 'custom' );

        if( customPreset && !customPreset.active )
        {
            settings.filters = settings.filters.filter( p => p.id !== 'custom' );
        }

        $_updateFilter( settings, ctx );

        Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));
    },

    extend( settings, ctx )
    {
        if( !settings.filters ){ settings.filters = []; }

        let shouldUpdate = false, currentPreset = settings.filters.find( p => p.active );

        if( !currentPreset )
        {
            currentPreset = { id: 'custom', name: 'Custom', active: true, filters: ctx.filter };

            settings.filters.push( currentPreset );

            shouldUpdate = true;
        }
        else
        {
            let diff = Helpers.ObjectDiff( currentPreset.filters, ctx.filter ); //TODO ked current filter ma pole s viac ako jednym prvkom, tak to nezoberie ako rozdiel

            if( Object.keys( diff ).length )
            {
                let $_filters = {};

                for( let [ label, value ] of Object.entries( currentPreset.filters ) )
                {
                    if( !ctx.filter.hasOwnProperty( label ) ){ $_filters[label] = value; continue; }

                    if( ctx.filter.hasOwnProperty( label ) && ctx.ignorePreviousFilters.includes( label ) ){ continue; }

                    if( Array.isArray( value ) || Array.isArray( ctx.filter[label] ) )
                    {
                        !Array.isArray( value )             && ( value = [ value ] );
                        !Array.isArray( ctx.filter[label] ) && ( ctx.filter[label] = [ ctx.filter[label] ] );

                        $_filters[label] = [ ...( new Set([ ...value, ...ctx.filter[label] ] ) ) ];
                    }
                    else if( typeof value === "string" || typeof value === "number" )
                    {
                        if( ( typeof ctx.filter[label] === "number" ) || ( typeof ctx.filter[label] === "string" && ctx.filter[label].length ) )
                        {
                            $_filters[label] = ctx.filter[label];
                        }
                    }
                }

                for( let [ label, value ] of Object.entries( ctx.filter ) )
                {
                    if( !$_filters.hasOwnProperty( label ) )
                    {
                        if( ( typeof value === "number" ) || ( typeof value === "string" && value.length ) || ( Array.isArray( value ) && value.length ) )
                        {
                            $_filters[label] = value;
                        }
                    }
                }

                if( currentPreset.id === 'custom' )
                {
                    currentPreset.filters = $_filters;
                }
                else
                {
                    let newPreset = { id: 'custom', name: 'Custom', active: true, filters: $_filters };

                    for( let preset of settings.filters ) { preset.active = false; }

                    settings.filters.push( newPreset );
                }

                shouldUpdate = true;
            }
        }

        if( shouldUpdate )
        {
            Helpers.Storage(undefined, 'set', ctx.storagePath, JSON.stringify( settings ));

            $_updateFilter( settings, ctx );

            if( ctx.allowFetch )
            {
                for( let [ label, value ] of Object.entries( ctx.filter ) )
                {
                    if( [ 'sort', 'cursor' ].includes( label ) ){ continue; }

                    let $_value = ctx.ListOptions.filter[label].settings.multiple && !Array.isArray( value ) ? [ value ] : value;

                    if( ctx.ListOptions.filter[label].hasOwnProperty('options') ) //TOTOK ASI REFAKTOR BO NAPIKACUUU
                    {
                        if( Array.isArray( $_value ) && !ctx.ListOptions.filter[label].options.find( option => $_value.includes( option.id ) ) )
                        {
                            if( ctx.ListOptions.filter[label]?.settings?.fetch )
                            {
                                ctx.ListOptions.filter[label].settings.fetch( ctx.store );
                            }
                            else if( ctx.ListOptions.filter[label]?.settings?.search )
                            {
                                ctx.ListOptions.filter[label].settings.search( ctx.store, '', $_value, () => {} ).then( options => ctx.ListOptions.filter[label].options = options );
                            }
                        }
                        else if( !Array.isArray( $_value ) && !ctx.ListOptions.filter[label].options.find( option => option.id === $_value ) )
                        {
                            if( ctx.ListOptions.filter[label]?.settings?.fetch )
                            {
                                ctx.ListOptions.filter[label].settings.fetch( ctx.store );
                            }
                            else if( ctx.ListOptions.filter[label]?.settings?.search )
                            {
                                ctx.ListOptions.filter[label].settings.search( ctx.store, '', [ $_value ], () => {} ).then( options => ctx.ListOptions.filter[label].options = options );
                            }
                        }
                    }
                }
            }
        }
    }
}

export const Filter =
{
    $_FilterObject: { ...$_FilterObject },

    extendFilterObject( filters ) //NOW USED ONLY WITHIN CUSTOM FILTERS
    {
        let $_fiterInputSetting =
        {
            label      : 'Example',
            group      : 'settings',
            options    : [],
            query      : {},
            settings   : { type: 'select', visibility: 'modal', searchable: true, multiple: true },
            $_fetch    : { type : 'custom' },
            //$_search   : { type : 'custom' },
            $_transform: {}
        }

        for( let filter of filters )
        {
            $_fiterInputSetting = JSON.parse( JSON.stringify( $_fiterInputSetting ) );

            $_fiterInputSetting.label               = filter.label;
            $_fiterInputSetting.query.property      = filter.id;
            $_fiterInputSetting.$_fetch.type        = filter.id;
            //$_fiterInputSetting.$_search.type       = filter.id;

            $_fiterInputSetting.$_transform['default']  = ( ids ) => { return { [ filter.parentPath ]: { [ filter.path ]: ids } } } // CUSTOM FILTERS ( parentPath, path is not existing on filters )

            Object.assign( this.$_FilterObject, { [ filter.id ]: $_fiterInputSetting } );
        }
    },

    resolveFilterObject( ListOptions, filter )
    {
        let $_filter = {};

        for( let [ label, value ] of Object.entries( filter ) )
        {
            if( ListOptions.filter[label] && typeof value !== 'undefined' && value !== null )
            {
                let $_value = ListOptions.filter[label].settings.multiple && !Array.isArray( value ) ? [ value ] : value;

                if( ListOptions.filter[label].hasOwnProperty('validator') )
                {
                    $_value = ListOptions.filter[label].validator( $_value );
                }

                if( $_value === 'true' || $_value === 'false' )
                {
                    $_value = $_value === 'true';
                }

                $_filter[label] = $_value;
            }
        }

        return $_filter;
    },

    getFilterQuery( filter, transformUID, transformToSmartFilter = true )
    {
        let $_query = { query: '', sort: {}, filter: [] };

        for( let [ key, value ] of Object.entries( filter ) )
        {
            if( key === 'sort' )
            {
                $_query.sort = value;
            }
            else if( key === 'search' )
            {
                $_query.query = value;
            }
            else if( this.$_FilterObject.hasOwnProperty( key ) )
            {
                let $_transform = this.$_FilterObject?.[key]?.['$_transform']?.[transformUID] || this.$_FilterObject[key]['$_transform']['default'];

                //Helpers.ObjectMerge( $_query.filter, $_transform( Array.isArray( value ) ? value : [ value ] ) );

                $_query.filter.push( $_transform( Array.isArray( value ) ? value : [ value ] ) );
            }
        }

        if( !$_query.query.length )
        {
            delete $_query.query;
        }

        if( !Object.keys( $_query.sort ).length )
        {
            delete $_query.sort;
        }

        if( $_query.filter.length )
        {
            $_query.filter = $_combineFilters( ...$_query.filter );
        }
        else
        {
            $_query.filter = {};
        }

        if( transformToSmartFilter )
        {
            $_query.smartFilter = JSON.parse( JSON.stringify( $_query.filter ) );

            delete $_query.filter;
        }

        //$_query.filter = JSON.stringify( $_query.filter );

        return $_query;
    },

    getHiddenFilter( uid, filter )
    {
        if( this.$_FilterObject.hasOwnProperty( filter ) )
        {
            let $_filters = JSON.parse(JSON.stringify(this.$_FilterObject[ filter ]));

            if( this.$_FilterObject[filter].hasOwnProperty('$_transform') )
            {
                $_filters.transform = this.$_FilterObject[filter]['$_transform'][`${uid}`] || this.$_FilterObject[filter]['$_transform']['default'];

                delete $_filters['$_transform'];
            }

            return $_filters;
        }
    },

    createObject( uid, list, options, filterList )
    {
        let $_sort = {}, $_filters = {};

        let $_ranges = async( uid, filter, type ) =>
        {
            //TODO
        }

        let $_fetch = async( store, filter, type ) =>
        {
            options.filter[filter].options = await store.dispatch( 'ramp/base/select/resolve', [ type ]) .then( r => r[type] );
        }

        let $_search = async( store, query, selectedItems, loading, filter, type ) =>
        {
            //TOTO NEVIEM CI DOBRE SOM ZROBIL
            if( selectedItems && !Array.isArray( selectedItems ) )
            {
                selectedItems = [ selectedItems ];
            }

            if( list.filter[filter] && !Array.isArray( list.filter[filter] ) )
            {
                list.filter[filter] = [ list.filter[filter] ];
            }

            if( query.length || list?.filter?.[filter]?.length )
            {
                loading( true );

                options.filter[filter].options = await store.dispatch( 'ramp/base/select/options',
                {
                    type   : type,
                    filter : { include: { ids: [ ...new Set([ ...( list.filter[filter] || [] ), ...( selectedItems || [] ) ]) ], query, ...( options.filter[filter]?.query || {} ) } }
                });

                loading( false );
            }

            return options.filter[filter].options;
        }

        for( let filter of filterList )
        {
            if( filter === 'sort' && this.$_FilterObject.hasOwnProperty( filter ) )
            {
                Object.assign( $_sort,
                {
                    label   : this.$_FilterObject[ filter ].label,
                    settings: this.$_FilterObject[ filter ].settings,
                    options : this.$_FilterObject[ filter ]?.[uid]?.options || this.$_FilterObject[ filter ].default.options
                })
            }
            else if( this.$_FilterObject.hasOwnProperty( filter ) )
            {
                $_filters[filter] = JSON.parse(JSON.stringify(this.$_FilterObject[ filter ]));

                if( options?.overrideFilters?.hasOwnProperty( filter ) )
                {
                    if( options.overrideFilters[filter]?.settings )
                    {
                        Object.assign( $_filters[filter].settings, options.overrideFilters[filter].settings );
                    }
                }

                if( options?.initialFilters?.includes( filter ) )
                {
                    $_filters[filter].settings.visibility = 'immediately';
                }

                if( this.$_FilterObject[filter].hasOwnProperty('$_search') )
                {
                    $_filters[filter].settings.search = async ( store, query, selectedItems, loading ) => await $_search( store, query, selectedItems, loading, filter, this.$_FilterObject[filter]['$_search'].type );

                    delete $_filters[filter]['$_search'];
                }

                if( this.$_FilterObject[filter].hasOwnProperty('$_fetch') )
                {
                    $_filters[filter].settings.fetch = async ( store ) => await $_fetch( store, filter, this.$_FilterObject[filter]['$_fetch'].type );

                    delete $_filters[filter]['$_fetch'];
                }

                if( this.$_FilterObject[filter].hasOwnProperty('$_component') )
                {
                    let $_component = {};

                    if( this.$_FilterObject[filter]['$_component'].hasOwnProperty('option') )
                    {
                        $_component.option = shallowRef( defineAsyncComponent( async () => await this.$_FilterObject[filter]['$_component']['option']() ) );
                    }

                    if( this.$_FilterObject[filter]['$_component'].hasOwnProperty('props') )
                    {
                        $_component.props = this.$_FilterObject[filter]['$_component']['props'];
                    }

                    $_filters[filter].component = $_component;

                    delete $_filters[filter]['$_component'];
                }

                if( this.$_FilterObject[filter].hasOwnProperty('$_transform') )
                {
                    $_filters[filter].transform = this.$_FilterObject[filter]['$_transform'][`${uid}`] || this.$_FilterObject[filter]['$_transform']['default'];

                    delete $_filters[filter]['$_transform'];
                }

                if( this.$_FilterObject[filter].hasOwnProperty('$_validator') )
                {
                    $_filters[filter].validator = this.$_FilterObject[filter]['$_validator'];

                    delete $_filters[filter]['$_validator'];
                }

                if( this.$_FilterObject[filter].hasOwnProperty('$_validator') )
                {
                    $_filters[filter].validator = this.$_FilterObject[filter]['$_validator'];

                    delete $_filters[filter]['$_validator'];
                }
            }
            else
            {
                console.error( `Filter ${filter} does not exist!` );
            }
        }

        return reactive({ sort: $_sort, filter: $_filters });
    }
}