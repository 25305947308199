import BaseModule from "@/store/modules/ramp_modules/base/base.module";

export default
{
    namespaced : true,
    state      : {},
    mutations  : {},
    actions    : {},
    getters    : {},
    modules    :
    {
        base: BaseModule
    }
};