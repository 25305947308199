import { createApp }    from "vue";

import DOMPurify        from 'dompurify';

import kit              from "@ramp-global/vue-kit";

import Notifications    from '@kyvg/vue3-notification';
import Version          from "@/core/version";

import App              from "./templates/App.vue";
import store            from "./store";

import { setupRouter }  from "./router";
import { setupI18n }    from "./i18n";

import globalProperties from "./plugins/globalProperties";

import Floating         from "floating-vue";
import                       "floating-vue/dist/style.css";

import Vue3Lottie       from 'vue3-lottie';

import * as Sentry        from "@sentry/vue";
import { CaptureConsole } from "@sentry/integrations";

import '@ramp-global/charts/src/pieElement.js';
import '@ramp-global/charts/src/guageElement.js';
import '@ramp-global/charts/src/funnelElement.js';

const version = new Version();

const i18n    = setupI18n({ legacy: false, globalInjection: true, locale: 'en', fallbackLocale: 'en' });
const router  = setupRouter( store, i18n, version );

const rampApp = createApp( App );

Sentry.init(
{
    app                 : rampApp,
    environment         : import.meta.env.MODE,
    release             : version.get(),
    integrations        : [ new CaptureConsole({ levels: [ "error" ] }) ],
    ignoreErrors        : [ 'AbortError' ],
    autoSessionTracking : true,
    dsn                 : "https://7d948cf5f39653e932b1307aafef360c@o4508918496952320.ingest.de.sentry.io/4508963584868432"
});

rampApp.use( store )
    .use( router )
    .use( i18n )
    .use( globalProperties, { store, i18n })
    .use( kit )
    .use( Notifications )
    .use( Floating, { themes: { 'tooltip-label': { $extend: 'tooltip' } } } )
    .use( Vue3Lottie )
    .directive('sanitize-html',
    {
        beforeMount( el, binding ) { el.innerHTML = DOMPurify.sanitize( binding.value ); },
        updated( el, binding )     { el.innerHTML = DOMPurify.sanitize( binding.value ); },
    })
    .mount( 'body' );