import { version } from '@/../package.json';
import { useNotification } from "@kyvg/vue3-notification";

export default class Version
{
    timeoutTime = 5 * 60 * 1000;
    timeout = null;
    version;

    constructor()
    {
        this.version = version;

        /*
        const oldVersionSession = sessionStorage.getItem( 'ui-version' );
        const oldVersionLocal = localStorage.getItem( 'ui-version' );
        if( ( oldVersionSession && Version.isNotSameVersion( oldVersionSession, this.version ) ) 
            || 
            ( oldVersionLocal && Version.isNotSameVersion( oldVersionLocal, this.version ) ) )
        {
            Version.clearSettingStorage();
            console.log( 'Reload page new version in init');
            if( location.search.indexOf( 'authToken' ) > -1 )
            {
                location.reload();
            }
            else
            {
                location.href = location.pathname;
            }
        }
        */
        this.check();
    }

    get()
    {
        return version;
    }

    async checkVersion()
    {
        
        const newVersion = await fetch( '/assets/version?v=' + Date.now() )
            .then( t => t.text() )
            .catch( e => 
            { 
                console.error('Failed to fetch version:', e);
                return '';
            } );
        
        if( newVersion && Version.isNotSameVersion( this.version, newVersion ) )
        {
            const { notify }  = useNotification()
            notify(
            {
                id               : 'new-version',
                group            : 'system',
                type             : "info",
                duration         : -1,
                ignoreDuplicates : true,
                closeOnClick     : false,
                title            : `A new version is available!`,
                text             : 'Refresh to get the latest version. <div class="mt-16 Button primary md" onclick="Version.reloadPage();">Refresh now</div>',
            });
        }
    }

    async check( force = false )
    {
        window.clearTimeout( this.timeout );
        this.timeout = null;

        if( force )
        {
            await this.checkVersion();
    
            this.check();
        }
        else
        {
            this.timeout = setTimeout( async () => 
            {
                window.requestAnimationFrame( async () => 
                {
                    await this.checkVersion();
    
                    this.check();
                } );
            }, this.timeoutTime );
        }
    }

    static compareVersions(v1, v2) {
        if( !v1 || !v2 ) { return 0; }

        const [main1, pre1 = ''] = v1.split('-');
        const [main2, pre2 = ''] = v2.split('-');
    
        const compareMain = main1.split('.').map(Number).reduce((acc, num, i) => {
            const num2 = Number(main2.split('.')[i] || 0);
            return acc !== 0 ? acc : num - num2;
        }, 0);
    
        if (compareMain !== 0) return compareMain;
    
        return pre1 === pre2 ? 0 : pre1 > pre2 ? 1 : -1;
    }

    static isNotSameVersion( oldVersion, newVersion )
    {
        return Version.compareVersions( newVersion?.trim(), oldVersion?.trim() ) === 0 ? false : true;
    }

    static reloadPage()
    {
        //Version.clearSettingStorage();

        console.log( 'Reload page new version');
        location.href = location.pathname;
    }

    /*
    static clearSettingStorage()
    {
        const keys = [];
        for( let i = 0; i < localStorage.length; i++ )
        {
            const key = localStorage.key(i);
            if( key.endsWith('_settings') )
            {
                keys.push(key);
            }
        }
        keys.forEach( key => localStorage.removeItem( key ) );

        const keySessions = [];
        for( let i = 0; i < sessionStorage.length; i++ )
        {
            const key = sessionStorage.key(i);
            if( key.endsWith('_settings') )
            {
                keySessions.push(key);
            }
        }
        keySessions.forEach( key => sessionStorage.removeItem( key ) );

        localStorage.setItem( 'ui-version', version );
        sessionStorage.setItem( 'ui-version', version );
    }
    */

}

window.Version = Version;