import { ref, reactive, getCurrentInstance, onBeforeMount, watch, nextTick } from "vue";

import { onBeforeRouteLeave } from "vue-router";

import { Helpers, ObjectLib } from "@/core/utils";

import { Filter, FilterPreset } from "@/core/filter";
import { Table, TablePreset }  from "@/core/table";

const settingsStorageValidator = ( storage, options ) =>
{
    // CUSTOM FILTER PRESETS VALIDATOR START
    let availableFilters = Object.keys( options?.filter || {} );

    if( storage?.filters?.length )
    {
        for( let preset of storage.filters )
        {
            if( preset?.filters?.length )
            {
                let filters = [];

                for( let filterID of Object.keys( preset.filters ) )
                {
                    if( availableFilters.includes( filterID ) )
                    {
                        filters.push( preset.filters[ filterID ] );
                    }
                }

                preset.filters = filters;
            }
        }
    }
    // CUSTOM FILTER PRESETS VALIDATOR END


    // CUSTOM COLUMNS PRESETS VALIDATOR START
    let availableColumns = options?.settings?.table?.thead?.map( c => c.id ) || [];

    if( storage?.presets?.length )
    {
        for( let preset of storage.presets )
        {
            if( preset?.columns?.length )
            {
                let columns = [];

                for( let column of preset.columns )
                {
                    if( availableColumns.includes( column.id ) || storage?.customFields?.find( f => f.id === column.id ) )
                    {
                        columns.push( column );
                    }
                }

                preset.columns = columns;
            }
        }
    }
    // CUSTOM COLUMNS PRESETS VALIDATOR END

    return storage;
}

const getItemClickPath = ( item, options ) =>
{
    let $_url   = options.path.replace( ':key', Helpers.ObjectGet( item, options.key ) );
    let $_query = '';

    if( typeof options.query === "object" && Object.keys( options.query ).length )
    {
        for( let [ key, value ] of Object.entries( options.query ) )
        {
            !$_query.length ? ( $_query += `?${key}=${value}` ) : ( $_query += `&${key}=${value}` );
        }
    }

    return $_url + $_query;
}

//THIS IS CURRENT ROUTER QUERY FROM URL
const getCurrentRouterQuery = ( router, options ) =>
{
    let $_query = JSON.parse( JSON.stringify( router.currentRoute.value.query ) );

    let $_sort   = undefined,
        $_cursor = undefined,
        $_filter = {};

    for( let queryKey of Object.keys( $_query ) )
    {
        if( queryKey === 'cursor' )
        {
            $_cursor = $_query.cursor;
        }

        if( queryKey === 'sort' )
        {
            //let $_defaultSort = options.sort.options.find( s => s.default );

            $_sort = Helpers.toArray( $_query.sort ); /*.filter( f => f !== $_defaultSort?.id );*/

            if( !$_sort.length )
            {
                $_sort = undefined;
            }
        }

        if( options?.filter )
        {
            if( Object.keys( options?.filter ).includes( queryKey ) )
            {
                $_filter[queryKey] = $_query[queryKey];
            }
        }
    }

    if( Object.keys( $_filter ).length )
    {
        $_filter = Filter.resolveFilterObject( options, $_filter );
    }

    let response = { sort: $_sort, cursor: $_cursor, ...$_filter };

    !response.sort   && ( delete response.sort );
    !response.cursor && ( delete response.cursor );

    //console.log( 'Router_query =>', response );

    return response;
}

//THIS IS CURRENT FILTER QUERY FROM ACTIVE FILTER PRESET
const getCurrentFilterQuery = ( data, options ) =>
{
    let $_activeFilterPreset = data?.settings?.filters?.find( f => f.active );
    let $_activeSorts        = data?.settings?.sorts;

    let $_sort   = undefined,
        $_cursor = undefined,
        $_filter = {};

    if( data?.pagination?.cursor )
    {
        $_cursor = data.pagination.cursor;
    }

    if( $_activeSorts?.length || data?.sort?.length )
    {
        //let $_defaultSort = options.sort.options.find( s => s.default );

        $_sort = JSON.parse( JSON.stringify( $_activeSorts?.length ? $_activeSorts : data.sort ) ); /*.filter( f => f !== $_defaultSort?.id );*/

        if( $_sort.length === 0 )
        {
            $_sort = undefined;
        }
    }

    if( $_activeFilterPreset )
    {
        $_filter = Filter.resolveFilterObject( options, $_activeFilterPreset.filters );
    }

    let response = { sort: $_sort, cursor: $_cursor, ...$_filter };

    !response.sort   && ( delete response.sort );
    !response.cursor && ( delete response.cursor );

    //console.log( 'Filter_query =>', { data, response });

    return response;
}

//THIS IS GENERATED FETCH REQUEST QUERY
const getFetchItemRequestQuery = ( data, options, ctx, requestOptions ) =>
{
    let $_filterQuery = JSON.parse( JSON.stringify( getCurrentFilterQuery( data, options ) ));

    let $_requestHeaders = ctx.request?.headers || {};
    let $_requestBody    = { query: '', cursor: '', sort: {}, filter: {} };
    let $_requestData    = {};

    //CURSOR SETTINGS START
    if( requestOptions?.direction )
    {
        $_requestBody.cursor = requestOptions.direction + ':' + requestOptions.cursor;
    }
    else if( data?.pagination?.cursor )
    {
        $_requestBody.cursor = data.pagination.cursor;
    }
    //CURSOR SETTINGS END


    //SEARCH QUERY SETTINGS START
    if( $_filterQuery.search )
    {
        $_requestBody.query = $_filterQuery.search;

        delete $_filterQuery.search;
    }
    //SEARCH QUERY SETTINGS END


    //SORT SETTINGS START
    if( $_filterQuery.sort )
    {
        if( options?.sort?.options?.length )
        {
            for( let sort of $_filterQuery.sort )
            {
                const optionsSort = options.sort.options.find( option => option.id === sort );

                if( optionsSort )
                {
                    Object.assign( $_requestBody.sort, optionsSort.value );
                }
                else
                {
                    console.warn(`Sort option with id "${sort}" is not defined in data options.`);
                }
            }
        }

        delete $_filterQuery.sort;
    }

    if( !Object.keys( $_requestBody.sort ).length )
    {
        //let $_defaultSort = options?.sort?.options?.find( o => o.default === true );

        let $_defaultSorts = options?.sort?.options?.filter( s => s?.default === true );

        if( $_defaultSorts?.length )
        {
            for( let $_defaultSort of $_defaultSorts )
            {
                Object.assign( $_requestBody.sort, $_defaultSort.value );
            }
        }
    }
    //SORT SETTINGS END


    //FILTER SETTINGS START
    for( let [ key, value ] of Object.entries( $_filterQuery ) )
    {
        if( options.filter.hasOwnProperty( key ) )
        {
            $_requestBody.filter[ key ] = value;

            //Object.assign( $_requestBody.filter, options.filter[ key ].transform( Array.isArray( value ) ? value : [ value ] ) );
        }
    }

    for( let [ key, value ] of Object.entries( ( ctx?.request?.query || {} ) ) )
    {
        let $_filter = Filter.getHiddenFilter( ctx.uid, key );

        if( $_filter )
        {
            $_requestBody.filter[ key ] = value;

            //Object.assign( $_requestBody.filter, $_filter.transform( Array.isArray( value ) ? value : [ value ] ) );
        }
    }

    if( Object.keys( $_requestBody.filter ).length )
    {
        $_requestBody.filter = Filter.getFilterQuery( $_requestBody.filter, ctx.uid, false ).filter;
    }
    //FILTER SETTINGS END

    //REQUEST DATA START
    ( ctx?.request?.body )        && ( $_requestBody = ObjectLib.merge( $_requestBody, ctx.request.body )/*Object.assign( $_requestBody, ctx.request.body )*/ ); // TODO OBJECT MERGE
    ( requestOptions?.direction ) && ( Object.assign( $_requestData, { direction: requestOptions.direction, onBeforeUpdate: requestOptions.onBeforeUpdate } ) );
    //REQUEST DATA END

    return { request: { headers: $_requestHeaders, body: { ...$_requestBody } }, ...$_requestData };
}

//THIS IS GENERATED QUERY FROM FILTERS INCLUDED IGNORED PARAMETERS FROM ROUTER QUERY
const replaceRouterQuery = async ( data, options, router, ctx ) =>
{
    let $_query = {};

    let $_filterQuery = getCurrentFilterQuery( data, options );

    for( let [ key, value ] of Object.entries( JSON.parse( JSON.stringify( $_filterQuery ) ) ) )
    {
        if( key === 'sort' ) //TODO DAKEDY POZRI CI DOBRE BO LENIVY KOKOT SOM BOL
        {
            if( !options?.sort?.options?.length ) { continue; }

            /*
            let defaultIDs = options.sort.options.filter( o => o?.default === true ).map( o => o.id );

            for( let key = 0; key < value.length; key++ )
            {
                if( defaultIDs.includes( value[key] ) )
                {
                    value.splice( key, 1 ); key--;
                }
            }
            */

            if( !value.length ) { continue; }
        }

        $_query[key] = value;
    }

    if( ctx?.router?.query?.ignore?.length )
    {
        for( let key of ctx.router.query.ignore )
        {
            if( router.currentRoute.value.query[key] )
            {
                $_query[key] = router.currentRoute.value.query[key];
            }
        }
    }

    await router.replace({ query: $_query });
}

const resolveInitialFilter = ( data, options, router, ctx ) =>
{
    let $_shouldReplaceRouterQuery = false;

    let routerQuery = getCurrentRouterQuery( router, options );
    let filterQuery = getCurrentFilterQuery( data, options );

    let $_diff = Helpers.ObjectDiff( routerQuery, filterQuery );

    //console.log('resolveInitialFilter =>', { routerQuery, filterQuery, diff: $_diff });

    if( $_diff.hasOwnProperty( 'cursor' ) )
    {
        data.pagination.cursor = $_diff.cursor;

        delete $_diff.cursor;
    }

    if( $_diff.hasOwnProperty( 'sort' ) && options?.sort?.options?.length )
    {
        //let $_defaultSorts = options?.sort?.options?.filter( s => s?.default === true );

        data.sort = [ ...$_diff.sort ].filter( Boolean );

        /*
        if( !data.sort.length && $_defaultSorts )
        {
            data.sort = $_defaultSorts.map( s => s.id );
        }
        */

        data.settings.sorts = data.sort;

        Helpers.Storage( undefined, 'set', `${ctx.storagePath}_settings`, JSON.stringify( data.settings ) );

        $_shouldReplaceRouterQuery = true;

        delete $_diff.sort;
    }
    else
    {
        if( filterQuery?.sort?.length )
        {
            data.sort = filterQuery.sort;
        }

        /*
        else if( options?.sort?.options?.length )
        {
            let $_defaultSorts = options.sort.options.filter( s => s?.default === true );

            data.sort           = $_defaultSorts.map( s => s.id ).filter( Boolean );
            data.settings.sorts = data.sort;

            Helpers.Storage( undefined, 'set', `${ctx.storagePath}_settings`, JSON.stringify( data.settings ) );
        }
        */
    }

    let $_activeFilterPreset = data?.settings?.filters?.find( f => f.active );

    if( Object.keys( routerQuery ).length || Object.keys( filterQuery ).length || Object.keys( ctx?.router?.query?.default || {} ).length )
    {
        let $_filter = {};

        if( Object.keys( $_diff ).length )
        {
            $_shouldReplaceRouterQuery = true;

            routerQuery.sort   && ( delete routerQuery.sort );
            routerQuery.cursor && ( delete routerQuery.cursor );

            if( Object.keys( ctx?.router?.query?.default || {} ).length )
            {
                for( let [ key, value ] of Object.entries( ctx.router.query.default ) )
                {
                    if( !Object.keys( routerQuery ).length && $_activeFilterPreset )
                    {
                        if( !$_activeFilterPreset.filters.hasOwnProperty( key ) )
                        {
                            Object.assign( $_activeFilterPreset.filters, Filter.resolveFilterObject( options, { [key]: value } ) );
                        }
                    }
                    else
                    {
                        if( !routerQuery.hasOwnProperty( key ) && !options.filter[key]?.settings?.clearable )
                        {
                            Object.assign( routerQuery, Filter.resolveFilterObject( options, { [key]: value } ) );
                        }
                    }
                }
            }

            if( !$_activeFilterPreset || $_activeFilterPreset.id !== 'custom' )
            {
                if( Object.keys( routerQuery ).length )
                {
                    Object.assign( $_filter, { id: 'custom', name: 'Custom', isCustom: true, filters: routerQuery } );

                    FilterPreset.create( data.settings, { storagePath: `${ctx.storagePath}_settings`, filter: $_filter });
                }
                else if( $_activeFilterPreset )
                {
                    Object.assign( $_filter, JSON.parse( JSON.stringify( $_activeFilterPreset ) ) );
                }
            }
            else if( $_activeFilterPreset && $_activeFilterPreset.id === 'custom' )
            {
                if( Object.keys( routerQuery ).length )
                {
                    Object.assign( $_filter, { ...JSON.parse( JSON.stringify( $_activeFilterPreset ) ), filters: routerQuery });

                    FilterPreset.update( data.settings, { storagePath: `${ctx.storagePath}_settings`, filter: $_filter });
                }
                else if( $_activeFilterPreset )
                {
                    Object.assign( $_filter, JSON.parse( JSON.stringify( $_activeFilterPreset ) ) );
                }
            }
        }
        else if( $_activeFilterPreset )
        {
            if( Object.keys( ctx?.router?.query?.default || {} ).length )
            {
                for( let [ key, value ] of Object.entries( ctx.router.query.default ) )
                {
                    if( !$_activeFilterPreset.filters.hasOwnProperty( key ) && !options.filter[key]?.settings?.clearable )
                    {
                        Object.assign( $_activeFilterPreset.filters, Filter.resolveFilterObject( options, { [key]: value } ) );

                        if( !$_shouldReplaceRouterQuery )
                        {
                            $_shouldReplaceRouterQuery = true;
                        }
                    }
                }
            }

            $_filter = $_activeFilterPreset;
        }
        else
        {
            if( Object.keys( ctx?.router?.query?.default || {} ).length )
            {
                $_shouldReplaceRouterQuery = true;

                let $_defaultFilter = {};

                for( let [ key, value ] of Object.entries( ctx.router.query.default ) )
                {
                    $_defaultFilter[key] = value;
                }

                $_defaultFilter = Filter.resolveFilterObject( options, $_defaultFilter );

                Object.assign( $_filter, { id: 'custom', name: 'Custom', isCustom: true, filters: $_defaultFilter } );

                FilterPreset.create( data.settings, { storagePath: `${ctx.storagePath}_settings`, filter: $_filter });
            }
        }

        if( Object.keys( $_filter?.filters || {} ).length )
        {
            FilterPreset.change( data.settings, { id: $_filter.id, storagePath: `${ctx.storagePath}_settings`, ListData: data, ListOptions: options });
        }
    }

    return $_shouldReplaceRouterQuery;
}

//this.store, list, options, ctx, filter, ignorePreviousFilters

const updateListFilter = ( store, ListData, ListOptions, ctx, filter, ignorePreviousFilters = [] ) =>
{
    FilterPreset.extend( ListData.settings,
    {
        store       : store,
        ListData    : ListData,
        ListOptions : ListOptions,
        filter      : Filter.resolveFilterObject( ListOptions, filter ),
        storagePath : `${ctx.storagePath}_settings`,
        allowFetch  : true,
        ignorePreviousFilters
    });
}

const fetchRequest = async ( data, options, ctx, requestOptions ) =>
{
    let fetchItemRequestQuery = getFetchItemRequestQuery( data, options, ctx, requestOptions );

    Helpers.ObjectMerge( fetchItemRequestQuery.request, requestOptions );

    //console.log('fetchRequest =>', { requestOptions, fetchItemRequestQuery });

    fetchItemRequestQuery.request.body.smartFilter = fetchItemRequestQuery.request.body.filter;
    delete fetchItemRequestQuery.request.body.filter;

    fetchItemRequestQuery.request.body.filter = fetchItemRequestQuery.request.body.$filter;
    delete fetchItemRequestQuery.request.body.$filter;

    return await ctx.request.url( fetchItemRequestQuery.request );
}

const fetchItemList = async ( data, options, router, isLoaded, statusCode, ctx, shouldReplaceRouterQuery = false, requestOptions = {} ) =>
{
    //console.log('fetchItemList =>', { data, options, router, ctx  });

    shouldReplaceRouterQuery && replaceRouterQuery( data, options, router, ctx );

    let fetchItemRequestQuery = getFetchItemRequestQuery( data, options, ctx, requestOptions );

    if( fetchItemRequestQuery.hasOwnProperty('direction') )
    {
        fetchItemRequestQuery.request.body.smartFilter = fetchItemRequestQuery.request.body.filter;
        delete fetchItemRequestQuery.request.body.filter;

        fetchItemRequestQuery.request.body.filter = fetchItemRequestQuery.request.body.$filter;
        delete fetchItemRequestQuery.request.body.$filter;

        let $_data = await ctx.request.url( fetchItemRequestQuery.request );

        if( $_data.items.length > 0 && $_data.items.length < 25 )
        {
            if( fetchItemRequestQuery.direction === 'prev' )
            {
                delete $_data.items[0].$cursor;
            }
            if( fetchItemRequestQuery.direction === 'next' )
            {
                delete $_data.items[$_data.items.length - 1].$cursor;
            }
        }
        else if( !$_data.items.length )
        {
            if( fetchItemRequestQuery.direction === 'prev' )
            {
                delete data.items[0].$cursor;
            }
            if( fetchItemRequestQuery.direction === 'next' )
            {
                delete data.items[data.items.length - 1].$cursor;
            }
        }

        fetchItemRequestQuery?.onBeforeUpdate();

        $_data.items = Helpers.ArrayPush( data.items, $_data.items, fetchItemRequestQuery.direction );

        if( typeof data.items === 'object' )
        {
            Object.assign( Helpers.ObjectClear( data.items ), $_data.items );

            delete data.items;
        }

        if( ctx.request?.postprocess )
        {
            $_data = ctx.request.postprocess( $_data );
        }

        const total = ( $_data.hasOwnProperty('total') ? data.total : 0 );

        Helpers.ObjectMerge( data, $_data );

        if( $_data.hasOwnProperty('total') && typeof $_data.total === 'number' )
        {
            data.total = Math.max( total, $_data.total );
        }
    }
    else
    {
        if( !options.hasOwnProperty('showLoading') || options.showLoading )
        {
            isLoaded.value = false;
        }

        try
        {
            fetchItemRequestQuery.request.body.smartFilter = fetchItemRequestQuery.request.body.filter;
            delete fetchItemRequestQuery.request.body.filter;

            fetchItemRequestQuery.request.body.filter = fetchItemRequestQuery.request.body.$filter;
            delete fetchItemRequestQuery.request.body.$filter;

            let $_data = await ctx.request.url( fetchItemRequestQuery.request );

            if( $_data.items.length > 0 )
            {
                if( !router.currentRoute.value.query.cursor )
                {
                    delete $_data.items[0].$cursor;
                }

                if( $_data.items.length < 25 )
                {
                    delete $_data.items[$_data.items.length - 1].$cursor;
                }
            }

            if( typeof data.items === 'object' )
            {
                Object.assign( Helpers.ObjectClear( data.items ), $_data.items );

                delete data.items;
            }

            if( ctx.request?.postprocess )
            {
                $_data = ctx.request.postprocess( $_data );
            }

            Helpers.ObjectMerge( data, $_data );

            statusCode.value = 200;

            if( !options.hasOwnProperty('showLoading') || options.showLoading )
            {
                isLoaded.value = true;
            }
        }
        catch( e )
        {
            if( e?.name === 'AbortError' )
            {
                return;
            }

            statusCode.value = e?.statusCode || e?.status || 500;

            isLoaded.value = true;
        }
    }
}

export class Component
{
    constructor( ctx )
    {
        this.store = ctx.store;
    }

    createLoadState( ctx )
    {
        return ref( false );
    }

    createStatusCode( ctx )
    {
        return ref( 200 );
    }

    createOptions( ctx )
    {
        let hasMapView      = ctx?.options?.hasOwnProperty('mapView') ? ctx.options.mapView : false;
        let hasGridView     = ctx?.options?.hasOwnProperty('gridView') ? ctx.options.gridView : false;
        let hasMultiSelect  = ctx?.options?.hasOwnProperty('multiSelect') ? ctx.options.multiSelect : true;
        let hasQuickActions = ctx?.options?.hasOwnProperty('quickActions') ? ctx.options.quickActions : true;

        let allowDownload   = ctx?.options?.hasOwnProperty('download') ? ctx.options.download : false;
        let allowPrint      = ctx?.options?.hasOwnProperty('print') ? ctx.options.print : false;

        let isSimplified    = ctx?.options?.hasOwnProperty('simplify') ? ctx.options.simplify : false;

        return (
        {
            setup           : { mapView: hasMapView, gridView: hasGridView, download: allowDownload, print: allowPrint, simplify: isSimplified },
            settings        : Table.createObject( ctx.listPath, { table: { multiSelect: hasMultiSelect, quickActions: hasQuickActions } }, `${ctx.storagePath}_settings` ),
            quickActions    : ctx?.actions?.quick || [],
            bulkActions     : ctx?.actions?.bulk || [],
            actionButtons   : ctx?.actions?.header || [],
            initialFilters  : ctx?.initialFilters || [],
            defaultFilters  : ctx?.router?.query?.default || {},
            overrideFilters : ctx?.overrideFilters || {}
        });
    }

    createList( ctx, options )
    {
        return reactive(
        {
            mapView         : JSON.parse(Helpers.Storage(undefined, 'get', `${ctx.storagePath}_mapView`) || ( ctx?.defaultView === 'map' ? 'true' : 'false' ) ),
            gridView        : JSON.parse(Helpers.Storage(undefined, 'get', `${ctx.storagePath}_gridView`) || ( ctx?.defaultView === 'grid' ? 'true' : 'false' ) ),
            items           : ctx.listType === 'object' ? {} : [],
            total           : 0,
            selectedItems   : JSON.parse(Helpers.Storage(undefined, 'get', `${ctx.storagePath}_selectedItems`) || JSON.stringify({ allSelected: false, selected: [] })),
            sort            : [],
            filter          : {},
            pagination      : { count: 0, cursor: null },
            settings        : settingsStorageValidator( JSON.parse( Helpers.Storage( undefined, 'get', `${ctx.storagePath}_settings` ) || TablePreset.default( ctx.uid, ctx.listPath, this.store ) ), options ),
        });
    }

    initListComponent( ctx )
    {
        if( this.store.getters['ramp/base/select/customFilter']?.fields?.length )
        {
            ctx.filter         && ( ctx.filter = [ ...ctx.filter, ...( ctx.includeCustomFilters ? this.store.getters['ramp/base/select/customFilter'].fields : [] ) ] );
            ctx.initialFilters && ( ctx.initialFilters = [ ...ctx.initialFilters, ...( ctx.includeCustomFilters ? this.store.getters['ramp/base/select/customFilter'].fields : [] ) ] );
        }

        const app = getCurrentInstance();

        const { $dialogs, $router, $dynamicComponent } = app.appContext.config.globalProperties;

        const isLoaded   = this.createLoadState( ctx );
        const statusCode = this.createStatusCode( ctx );
        const options    = this.createOptions( ctx );
        const list       = this.createList( ctx, options );

        //console.log('initListComponent =>', JSON.parse(JSON.stringify({ options, list })));

        Object.assign( options, Filter.createObject( ctx.uid, list, options, ctx.filter ) );

        const startWatch = () =>
        {
            watch( () => [ list?.sort, list?.filter ], () =>
            {
                list.pagination.cursor = null;

                fetchItemList( list, options, $router, isLoaded, statusCode, ctx, true );

            }, { deep: true });
        }

        onBeforeMount(() =>
        {
            let shouldReplaceRouterQuery = resolveInitialFilter( list, options, $router, ctx );

            fetchItemList( list, options, $router, isLoaded, statusCode, ctx, shouldReplaceRouterQuery );

            nextTick( () => startWatch() );
        });

        onBeforeRouteLeave(async ( to, from, next ) =>
        {
            if( list.hasOwnProperty('selectedItems') && ( list.selectedItems.allSelected || ( !list.selectedItems.allSelected && list.selectedItems.selected.length ) ) )
            {
                await $dynamicComponent.load( 'RouterLeaveModal', () => import('@/templates/components/Dialogs/RouterLeaveDialog'), () => $dialogs['RouterLeaveModal'].open({ ListData: list, storagePath: `${ctx.storagePath}_selectedItems`, to }) );

                next( false );
            }
            else
            {
                next();
            }
        });

        return (
        {
            isLoaded         : isLoaded,
            statusCode       : statusCode,

            listPath         : ctx.listPath,
            storagePath      : ctx.storagePath,

            ListOptions      : options,
            ListData         : list,

            itemOptions      : ctx.item,

            fetchRequest     : async ( requestOptions ) => await fetchRequest( list, options, ctx, requestOptions ), //METHOD WITH CONTEXT
            fetchItemList    : async ( shouldReplaceRouterQuery = false, requestOptions = {} ) => await fetchItemList( list, options, $router, isLoaded, statusCode, ctx, shouldReplaceRouterQuery, requestOptions ), //METHOD WITH CONTEXT
            updateListFilter : ( filter, ignorePreviousFilters = [] ) => updateListFilter( this.store, list, options, ctx, filter, ignorePreviousFilters ), //METHOD WITH CONTEXT

            getRequestQuery  : ( requestOptions ) => getFetchItemRequestQuery( list, options, ctx, requestOptions ), //METHOD WITH CONTEXT
            getItemClickPath : getItemClickPath, //HELPER FUNCTION
        });
    }

    initDetailComponent( ctx )
    {
        if( this.store.getters['ramp/base/select/customFilter']?.fields?.length )
        {
            ctx.filter         && ( ctx.filter = [ ...ctx.filter, ...( ctx.includeCustomFilters ? this.store.getters['ramp/base/select/customFilter'].fields : [] ) ] );
            ctx.initialFilters && ( ctx.initialFilters = [ ...ctx.initialFilters, ...( ctx.includeCustomFilters ? this.store.getters['ramp/base/select/customFilter'].fields : [] ) ] );
        }

        const app = getCurrentInstance();

        const { $router } = app.appContext.config.globalProperties;

        const isLoaded = this.createLoadState( ctx );
        const options  = { initialFilters: ctx?.initialFilters || [], defaultFilters: ctx?.router?.query?.default || {}, overrideFilters: ctx?.overrideFilters || {} };
        const detail   = reactive({ filter: {}, settings: settingsStorageValidator( JSON.parse(Helpers.Storage(undefined, 'get', `${ctx.storagePath}_settings`) || '{}' ), {} ) });

        Object.assign( options, Filter.createObject( ctx.uid, detail, options, ctx.filter ));

        delete detail?.settings?.presets;
        delete options?.sort;

        //console.log('initDetailComponent =>', { isLoaded, options, detail });

        const startWatch = () =>
        {
            watch( () => [ detail?.filter ], () => { replaceRouterQuery( detail, options, $router, ctx ); }, { deep: true });
        }

        onBeforeMount(() =>
        {
            if( resolveInitialFilter( detail, options, $router, ctx ) )
            {
                replaceRouterQuery( detail, options, $router, ctx );
            }

            nextTick(() => startWatch() );
        });

        return (
        {
            isLoaded         : isLoaded,

            storagePath      : ctx.storagePath,

            ListOptions      : options,
            ListData         : detail,

            getRequestQuery  : ( requestOptions ) => getFetchItemRequestQuery( detail, options, ctx, requestOptions ), //METHOD WITH CONTEXT

            updateListFilter : ( filter, ignorePreviousFilters = [] ) => updateListFilter( this.store, detail, options, ctx, filter, ignorePreviousFilters ), //METHOD WITH CONTEXT
        });
    }
}