import { Helpers } from "@/core/utils";

export class Draft
{
    constructor( ctx )
    {
        this.store = ctx.store;
    }

    get( uid )
    {
        let draft = Helpers.Storage( 'local', 'get', 'draft_' + uid );

        if( draft )
        {
            return JSON.parse( draft );
        }
    }

    set( uid, data )
    {
        return Helpers.Storage( 'local', 'set', 'draft_' + uid, JSON.stringify( data ) );
    }

    remove( uid )
    {
        return Helpers.Storage( 'local', 'remove', 'draft_' + uid );
    }
}