import { Helpers } from "@/core/utils";
import EventsService from "./events.service";

function Headers( headers = {})
{
    return Object.fromEntries( Object.entries( headers ).map(([ key, value ]) => [ key.toLowerCase(), value ]));
}

function Querystring( data, querystring = [], prefix = '' )
{
    if( data === null )
    {
        querystring.push( prefix );
    }
    else if( typeof data === 'boolean' )
    {
        querystring.push( prefix + '=' + ( data ? '1' : '0' ));
    }
    else if( typeof data === 'number' || typeof data === 'string' )
    {
        querystring.push( prefix + '=' + data.toString() );//encodeURIComponent( data.toString() ));
    }
    else if( Array.isArray( data ))
    {
        for( let i = 0; i < data.length; ++i )
        {
            Querystring( data[i], querystring, prefix /*+ '[' + i + ']'*/ );
        }
    }
    else if( typeof data === 'object' )
    {
        for( let [ key, value ] of Object.entries( data ))
        {
            Querystring( value, querystring, prefix ? prefix + '[' + key + ']' : key );
        }
    }

    return querystring;
}

class Client
{
    constructor( options = {} )
    {
        this.options = options;

        this.headers =
        {
            'Content-Type'    : 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
    }

    url      ( url, options ){ return new URL( url, this.options.baseURL ).href }
    get      ( url, options ){ return this.request( 'GET', url, options )}
    post     ( url, options ){ return this.request( 'POST', url, options )}
    put      ( url, options ){ return this.request( 'PUT', url, options )}
    patch    ( url, options ){ return this.request( 'PATCH', url, options )}
    delete   ( url, options ){ return this.request( 'DELETE', url, options )}

    async request( method, url, options = {} )
    {
        //console.log( 'request', method, url, options )

        const originalURL = url;

        let X_AccountID      = Helpers.Storage( undefined, 'get', 'X-AccountID' );
        let X_OrganizationID = Helpers.Storage( undefined, 'get', 'X-OrganizationID' );

        X_AccountID      && ( this.headers['X-AccountID'] = X_AccountID );
        X_OrganizationID && ( this.headers['X-OrganizationID'] = X_OrganizationID );

        let headers = Headers({ ...this.headers, ...options.headers }), body = options.body;

        if( !url?.startsWith('http://') && !url?.startsWith('https://') )
        {
            try
            {
                url = new URL( url, this.options.baseURL ).href
            }
            catch( e )
            {
                const error = new Error("Invalid URL");
                      error.code    = e?.code || 500;
                      error.details = { ...( e?.details || {} ), url };

                throw error;
            }
        }

        if( options.params )
        {
            url = url.replace(/{([^}]+)}/g, ( _, key ) => options.params[key])
        }

        if( options.query )
        {
            url += ( url.includes('?') ? '&' : '?' ) + Querystring( options.query ).join('&')
        }

        if( body && typeof body !== 'string' )
        {
            if( !headers['content-type'] || headers['content-type'].startsWith('application/json') )
            {
                !headers['content-type'] && ( headers['content-type'] = 'application/json' );

                body = JSON.stringify( body );
            }
            else if( headers['content-type'].startsWith('application/x-www-form-urlencoded'))
            {
                body = Querystring( body );
            }
            else if( headers['content-type'].startsWith('multipart/form-data'))
            {
                delete headers['content-type'];
            }
        }

        let response;

        try
        {
            response = await fetch( url, { method, cors: 'cors', signal: options.signal, credentials: 'include', cache: 'no-cache', redirect: 'follow', headers, body });
        }
        catch( error )
        {
            if( error.name === 'AbortError' )
            {
                throw error;
            }
            else if( options.retries && options.retries >= 20 )
            {
                throw new Error( 'Request failed, maximum retries reached' );
            }
            else
            {
                options.retries = options.retries ? options.retries + 1 : 1;

                await Helpers.Sleep( Math.min( 5000, options.retries * 500 ) );

                return this.request( method, originalURL, options );

                //return new Promise(( resolve, reject ) => setTimeout(() => this.request( method, originalURL, options ).then( resolve ).catch( reject ), 1000 ));
            }
        }

        if( response.ok )
        {
            if( response.headers.get('content-type').startsWith('application/pdf') )
            {
                const arrayBuffer = await response.arrayBuffer();

                return arrayBuffer;
            }
                
            let responseData = await response.text(); //WORKAROUND FOR FETCH API BUG

            try
            {
                return JSON.parse( responseData );
            }
            catch( error )
            {
                return responseData;
            }
        }
        else
        {
            let error, responseData = await response.text();

            try
            {
                error = JSON.parse( responseData );
            }
            catch( error )
            {
                error = new Error( response.statusText );
            }

            if( response.status )
            {
                error.statusCode = response.status;
            }

            if( sessionStorage.getItem('selectAccountURL') && error.statusCode === 404 )
            {
                sessionStorage.removeItem('selectAccountURL');

                location.href = '/';
            }

            if( error?.code === 'unauthorized' )
            {
                if( url.includes('v1/me') )
                {
                    Helpers.Storage( undefined, 'remove', 'X-UserID' );
                    Helpers.Storage( undefined, 'remove', 'X-AccountID' );
                    Helpers.Storage( undefined, 'remove', 'X-OrganizationID' );

                    location.href = import.meta.env.VITE_APP_AUTH_URL + 'login';
                }
                else
                {
                    await apiClient['iam'].request( 'GET', '/v1/me' );
                }
            }

            if( !error?.status || !error?.statusCode )
            {
                error.statusCode = response.status;
            }

            throw error;
        }
    }
}

const apiClient =
{
    'app'        : new Client({ baseURL: import.meta.env.VITE_APP_API_URL }),
    'iam'        : new Client({ baseURL: import.meta.env.VITE_APP_IAM_API_URL }),
    'admin'      : new Client({ baseURL: import.meta.env.VITE_APP_ADMIN_API_URL }),
    'tracker'    : new Client({ baseURL: import.meta.env.VITE_APP_TRACKER_API_URL }),
    'events'     : EventsService
};

let controllers = {};

const API = async ( service, method, path, data = {} ) =>
{
    let controllerUID = `${service}.${method}.${path}.${JSON.stringify(data)}`;

    try
    {
        if( !path.includes('select-options')
            && !path.includes('/me')
            && !path.includes('/chat')
            && !path.includes('/conversation')
            && !path.includes('/notifications')
            && controllers[controllerUID]
        )
        {
            controllers[controllerUID].abort();
        }

        controllers[controllerUID] = new AbortController();

        let $_query   = data?.query   ? data.query   : {};
        let $_params  = data?.params  ? data.params  : {};
        let $_body    = data?.body    ? data.body    : null;
        let $_headers = data?.headers ? data.headers : {};

        //console.log( 'BEFORE API REQUEST: '+ path +' =>', JSON.stringify({ $_headers, $_query, $_body }));

        let requestData = await apiClient[service].request( method, path, { query: $_query, params: $_params, body: $_body, headers: $_headers, signal: controllers[controllerUID].signal });

        delete controllers[ controllerUID ];

        return requestData;
    }
    catch( error )
    {
        if( error.name === 'AbortError' )
        {
            console.warn( 'SWAGGER API ABORTED => ', { service, path, data, error } );
        }
        else
        {
            console.error( 'SWAGGER API ERROR => ', { service, path, data, error } );
        }

        delete controllers[ controllerUID ];

        throw error;
    }
}

export default class SwaggerService
{
    /* ------------------------------ SWAGGER APP API SERVICE START ------------------------------ */

    static get iam()        { return apiClient['iam']; }
    static get app()        { return apiClient['app']; }
    static get admin()      { return apiClient['admin']; }
    static get events()     { return apiClient['events']; }

    // ------ AUTH START ------
    static async get_me()                                   { return await API('iam', 'GET', '/v1/me' ); }

    static async validate_organization_crn( request )       { return await API('iam', 'POST', 'v1/crn/validate', request ); }

    static async invite_organization_to_job( request )      { return await API('iam', 'POST', '/v1/organization/invite-on-job', request ); }
    static async invite_organization_to_register( request ) { return await API('iam', 'POST', '/v1/organization/invite', request ); }
    // ------ AUTH END ------

    // ------ STRIPE START ------
    static async create_stripe_checkout_session( request )     { return await API('app', 'POST', '/v1/payment/create-checkout-session', request ); }
    static async retrieve_stripe_checkout_session( sessionID ) { return await API('app', 'GET', `/v1/payment/retrieve-checkout-session/${sessionID}`); }
    // ------ STRIPE END ------


    // ------ CUSTOM FILTERS START ------
    static async custom_filters() { return await API('app', 'GET', '/v1/custom-filters' ); }
    // ------ CUSTOM FILTERS END ------


    // ------ USER START ------
    static async get_user( userID )             { return await API('app', 'GET', `/v1/user/${userID}` ); }
    static async update_user( userID, request ) { return await API('app', 'PATCH', `/v1/user/${userID}`, request ); }

    static async get_users( request )           { return await API('app', 'POST', '/v1/users', request ); }

    static async accept_agreement( request )    { return await API('app', 'GET', '/v1/accept-agreement', request ); }
    static async accept_eula()                  { return await API('iam', 'POST', 'v1/user/acceptEula' ); }
    // ------ USER END ------


    // ------ ACCOUNT START ------
    static async get_account( accountID, request )    { return await API('app','GET', `/v1/account/${accountID}`, request ); }
    static async get_accounts( request )              { return await API('app','POST', '/v1/accounts', request ); }

    static async update_account( accountID, request ) { return await API('app','PATCH', `/v1/account/${accountID}`, request ); }
    static async delete_account( accountID )          { return await API('iam', 'DELETE', `/v1/account/${accountID}` ); }
    // ------ ACCOUNT END ------


    // ------ OVERVIEW START ------
    static async get_jobs_overview( request )         { return await API('app','GET', '/v1/overview/jobs', request ); }
    static async get_engagements_overview( request )  { return await API('app','GET', '/v1/overview/engagements', request ); }
    static async get_applications_overview( request ) { return await API('app','GET', '/v1/overview/candidates', request ); }
    // ------ OVERVIEW END ------


    // ------ TAG START ------
    static async get_tags( request )          { return await API('app', 'POST', '/v1/tags', request ); }
    static async create_tag( request )        { return await API('app', 'PUT', '/v1/tag', request ); }
    static async update_tag( tagID, request ) { return await API('app', 'PATCH', `/v1/tag/${tagID}`, request ); }
    static async delete_tag( tagID )          { return await API('app', 'DELETE', `/v1/tag/${tagID}` ); }
    // ------ TAG END ------



    // ------ PROGRAMME START ------
    static async get_programme( programmeID )             { return await API('app','GET', `/v1/programme/${programmeID}` ); }
    static async get_programmes( request )                { return await API('app','POST', '/v1/programmes', request ); }

    static async create_programme( request )              { return await API('app','PUT', '/v1/programme', request ); }

    static async update_programme( programmeID, request ) { return await API('app','PATCH', `/v1/programme/${programmeID}`, request ); }

    static async delete_programme( programmeID )          { return await API('app','DELETE', `/v1/programme/${programmeID}` ); }
    // ------ PROGRAMME END ------


    // ------ PROGRAMME LOCATION START ------
    static async create_programme_location( programmeID, request )                      { return await API('app','PUT', `/v1/programme/${programmeID}/location`, request ); }

    static async update_programme_location( programmeID, programmeLocationID, request ) { return await API('app','PATCH', `/v1/programme/${programmeID}/location/${programmeLocationID}`, request ); }

    static async delete_programme_location( programmeID, programmeLocationID )          { return await API('app','DELETE', `/v1/programme/${programmeID}/location/${programmeLocationID}` ); } //NOT IMPLEMENTED ON BACKEND
    // ------ PROGRAMME LOCATION END ------


    // ------ JOB START ------
    static async get_job( jobID, request )  { return await API('app','GET', `/v1/job/${jobID}`, request ); }
    static async get_job_terms( jobID )     { return await API('app','GET', `/v1/job/${jobID}/terms` ); }
    static async get_jobs( request )        { return await API('app','POST', '/v1/jobs', request ); }
    static async get_jobs_map( request )    { return await API('app','POST', '/v1/job/map', request ); }

    static async create_job( request )        { return await API('app','PUT', '/v1/job', request ); }

    static async update_job( jobID, request )         { return await API('app','PATCH', `/v1/job/${jobID}`, request ); }
    static async update_partial_job( jobID, request ) { return await API('app','PATCH', `/v1/job/${jobID}/partial`, request ); }

    static async delete_job( jobID )          { return await API('app','DELETE', `/v1/job/${jobID}` ); }

    static async get_briefings( request )                { return await API('app','POST', '/v1/briefings', request ); }
    static async create_briefing( request )              { return await API('app','PUT', '/v1/briefing', request ); }
    static async update_briefing( briefingID, request )  { return await API('app','PATCH', `/v1/briefing/${briefingID}`, request ); }

    static async get_insights_job_salary( request ) { return await API('app', 'POST', '/v1/job-salaries-location', request ); }
    static async get_jobs_salary( request )         { return await API('app', 'POST', '/v1/job-salaries', request ); }
    static async get_insights_agency_fee( request ) { return await API('app', 'POST', '/v1/insights/agency-fee', request ); }
    static async get_insights_countries_ti( request ) { return await API('app', 'POST', '/v1/insights/countries-ti-v9', request ); }
    // ------ JOB END ------


    // ------ ONBOARDING INVITATION START ------
    static async get_onboarding_invitation( invitationID )             { return await API('app','GET', `/v1/onboarding/invitation/${invitationID}` ); }
    static async get_onboarding_invitations( request )                 { return await API('app','POST', '/v1/onboarding/invitations', request ); }

    static async create_onboarding_invitation( request )               { return await API('app','PUT', '/v1/onboarding/invitation', request ); }

    static async accept_onboarding_invitation( invitationID )          { return await API('app','PATCH', `/v1/onboarding/invitation/${invitationID}/accepted` ); }
    static async reject_onboarding_invitation( invitationID )          { return await API('app','PATCH', `/v1/onboarding/invitation/${invitationID}/rejected` ); }
    static async withdraw_onboarding_invitation( invitationID )        { return await API('app','PATCH', `/v1/onboarding/invitation/${invitationID}/withdrawn` ); }

    static async get_agencies_to_onboard( request )                    { return await API('app','POST', '/v1/organization/agenciesToOnboard', request ); }
    // ------ ONBOARDING INVITATION END ------


    // ------ INVITATION START ------
    static async get_invitation( invitationID )                { return await API('app','GET', `/v1/invitation/${invitationID}` ); }
    static async get_invitations( request )                    { return await API('app','POST', '/v1/invitations', request ); }

    static async send_invitations( request )                   { return await API('app','PUT', `/v1/invitations`, request ); }
    static async update_invitation( invitationID, request )    { return await API('app','PATCH', `/v1/invitation/${invitationID}`, request ); }
    static async negotiate_invitation( invitationID, request ) { return await API('app','PATCH', `/v1/invitation/${invitationID}/negotiate`, request ); }

    static async send_invitation_reminder( invitationID )      { return await API('app','POST', `/v1/invitation/${invitationID}/reminder` ); }

    static async get_agencies_to_release( request )            { return await API('app','POST', '/v1/organization/releaseAgencies', request ); }
    static async get_agency_stats( organizationID, request )   { return await API('app','POST', `/v1/organization/${organizationID}/stats`, request ); }
    // ------ INVITATION END ------


    // ------ ENGAGEMENT START ------
    static async get_engagement( engagementID )                 { return await API('app','GET', `/v1/engagement/${engagementID}` ); }
    static async get_engagements( request )                     { return await API('app','POST', '/v1/engagements', request ); }

    static async create_engagement( request )                   { return await API('app','PUT', '/v1/engagement', request ); }

    static async update_engagement( engagementID, request )     { return await API('app','PATCH', `/v1/engagement/${engagementID}`, request ); }
    static async update_engagement_cvs( engagementID, request ) { return await API('app','PATCH', `/v1/engagement/${engagementID}/increaseCVs`, request ); }
    static async update_engagement_fee( engagementID, request ) { return await API('app','PATCH', `/v1/engagement/${engagementID}/change_fee`, request ); }

    static async delete_engagement( engagementID, request )     { return await API('app','DELETE', `/v1/engagement/${engagementID}`, request ); }
    // ------ ENGAGEMENT END ------


    // ------ APPLICATION START ------
    static async get_application( applicationID )                       { return await API('app','GET', `/v1/application/${applicationID}` ); }
    static async get_applications( request )                            { return await API('app','POST', '/v1/applications', request ); }
    static async get_application_cv( applicationID )                    { return await API('app','GET', `/v1/application/${applicationID}/cv` ); }

    static async confirm_application_interview( applicationID, request ){ return await API('app','PATCH', `/v1/application/${applicationID}/confirm-interview`, request ); }


    static async create_application( request )                          { return await API('app','PUT', '/v1/application', request ); }
    static async create_application_offer( applicationID, request )     { return await API('app','PUT', `/v1/application/${applicationID}/offer`, request ); }
    static async confirm_application_offer( applicationID )             { return await API('app','POST', `/v1/application/${applicationID}/offer/confirm` ); }
    static async reject_application_offer( applicationID, request )     { return await API('app','POST', `/v1/application/${applicationID}/offer/reject`, request ); }
    static async submit_application_placement( applicationID, request ) { return await API('app','POST', `/v1/application/${applicationID}/placement`, request ); }

    static async update_application( applicationID, request )           { return await API('app','PATCH', `/v1/application/${applicationID}`, request ); }
    static async update_partial_application( applicationID, request )   { return await API('app','PATCH', `/v1/application/${applicationID}/partial`, request ); }

    static async delete_application( applicationID )                    { return await API('app','DELETE', `/v1/application/${applicationID}` ); } //NOT IMPLEMENTED ON BACKEND
    // ------ APPLICATION END ------


    // ------ PLACEMENT START ------
    static async get_placement( placementID )             { return await API('app','GET', `/v1/placement/${placementID}` ); }
    static async get_placements( request )                { return await API('app','POST', '/v1/placements', request ); }
    static async get_placements_map( request )            { return await API('app','POST', '/v1/placement/map', request ); }

    static async create_placement( request )              { return await API('app','PUT', '/v1/placement', request ); }

    static async update_placement( placementID, request ) { return await API('app','PATCH', `/v1/placement/${placementID}`, request ); }

    static async dropout_placement( placementID )         { return await API('app','POST', `/v1/placement/${placementID}/dropout` ); }

    static async delete_placement( placementID )          { return await API('app','DELETE', `/v1/placement/${placementID}` ); }
    // ------ PLACEMENT END ------


    // ------ CONTRACT START ------
    static async get_contract( contractID )                                    { return await API('app','GET', `/v1/contract/${contractID}` ); }
    static async get_contracts( request )                                      { return await API('app','POST', '/v1/contracts', request ); }
    static async get_contracts_map( request )                                  { return await API('app','POST', '/v1/contract/map', request ); }

    static async create_contract( request )                                    { return await API('app','PUT', '/v1/contract', request ); }
    static async create_contract_timesheet( contractID, request )              { return await API('app','PUT', `/v1/contract/${contractID}/timesheet`, request ); }

    static async update_contract( contractID, request )                        { return await API('app','PATCH', `/v1/contract/${contractID}`, request ); }
    static async update_contract_timesheet( contractID, timesheetID, request ) { return await API('app','PATCH', `/v1/contract/${contractID}/timesheet/${timesheetID}`, request ); }

    static async extend_contract( contractID, request )                        { return await API('app','PUT', `/v1/contract/${contractID}/extend`, request ); }
    static async terminate_contract( contractID )                              { return await API('app','POST', `/v1/contract/${contractID}/terminate` ); }
    static async dropout_contract( contractID )                                { return await API('app','POST', `/v1/contract/${contractID}/dropout` ); }

    static async delete_contract( contractID )                                 { return await API('app','DELETE', `/v1/contract/${contractID}` ); }
    static async delete_contract_timesheet( contractID, timesheetID )          { return await API('app','DELETE', `/v1/contract/${contractID}/timesheet/${timesheetID}` ); } //NOT IMPLEMENTED ON BACKEND
    // ------ CONTRACT END ------


    // ------ EXCEPTION START ------
    static async get_exception( exceptionID )             { return await API('app','GET', `/v1/exception/${exceptionID}` ); }
    static async get_exceptions( request )                { return await API('app','POST', `/v1/exceptions`, request ); }

    static async create_exception( request )              { return await API('app','PUT', '/v1/exception', request ); }

    static async update_exception( exceptionID, request ) { return await API('app','PATCH', `/v1/exception/${exceptionID}`, request ); }
    // ------ EXCEPTION END ------


    // ------ BILLING ENTITY START ------
    static async get_billing_entity( billingEntityID )              { return await API('app','GET', `/v1/billing/entity/${billingEntityID}` ); }
    static async get_billing_entities( request )                    { return await API('app','POST', '/v1/billing/entities', request ); }

    static async create_billing_entity( request )                   { return await API('app','PUT', '/v1/billing/entity', request ); }

    static async update_billing_entity( billingEntityID, request )  { return await API('app','PATCH', `/v1/billing/entity/${billingEntityID}`, request ); }

    static async delete_billing_entity( billingEntityID )           { return await API('app','DELETE', `/v1/billing/entity/${billingEntityID}` ); }
    // ------ BILLING ENTITY END ------


    // ------ INVOICING START ------
    static async get_invoice( invoiceID ) { return await API('app','GET', `/v1/invoice/${invoiceID}` ); }
    static async get_invoices( request )  { return await API('app','POST', '/v1/invoices', request ); }
    // ------ INVOICING END ------


    // ------ INVOICING CONTACT START ------
    static async get_invoicing_contact( invoicingContactID )             { return await API('app','GET', `/v1/invoicing/contact/${invoicingContactID}` ); }
    static async get_invoicing_contacts( request )                       { return await API('app','POST', '/v1/invoicing/contacts', request ); }

    static async create_invoicing_contact( request )                     { return await API('app','PUT', '/v1/invoicing/contact', request ); }

    static async update_invoicing_contact( invoicingContactID, request ) { return await API('app','PATCH', `/v1/invoicing/contact/${invoicingContactID}`, request ); }

    static async delete_invoicing_contact( invoicingContactID )          { return await API('app','DELETE', `/v1/invoicing/contact/${invoicingContactID}` ); }
    // ------ INVOICING CONTACT END ------


    // ------ ORGANIZATION START ------
    static async get_organization( organizationID, request )    { return await API('app','GET', `/v1/organization/${organizationID}`, request ); }
    static async get_agency_top_industries( organizationID )    { return await API('app','POST', '/v1/analytics/job/top-categories', { query: { type: 'table', globalScope: true }, body: { filter: { agencyIDs: [ organizationID ], dateRange: [ new Date(new Date().getFullYear() - 1, 0, 1).toISOString(), new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59, 999).toISOString() ] } } } ); }

    static async get_organizations( request )                   { return await API('app','POST', '/v1/organizations', request ); }
    static async get_organizations_map( request )               { return await API('app','POST', '/v1/organization/map', request ); }

    static async update_organization( organizationID, request ) { return await API('app','PATCH', `/v1/organization/${organizationID}`, request ); }
    // ------ ORGANIZATION END ------


    // ------ PERSON START ------
    static async get_person( personID ) { return await API('app','GET', `/v1/person/${personID}` ); }
    static async get_people( request )  { return await API('app','POST', '/v1/people', request ); }
    // ------ PERSON END ------


    // ------ DOCUMENT START ------
    static async get_documents( request ) { return await API('app','POST', '/v1/documents', request ); }
    // ------ DOCUMENT END ------


    // ------ CONVERSATION START ------
    static async unread_conversations()                         { return await API('app','GET', '/v1/conversation-unread' ); }

    static async get_conversations( request )                   { return await API('app','POST', '/v1/conversations', request ); }

    static async create_conversation( request )                 { return await API('app','PUT', '/v1/conversation', request ); }

    static async seen_conversation( conversationID )            { return await API('app','POST', `/v1/conversation/${conversationID}/seen` ); }
    static async update_conversation( conversationID, request ) { return await API('app','PATCH', `/v1/conversation/${conversationID}`, request ); }

    static async delete_conversation( conversationID )          { return await API('app','DELETE', `/v1/conversation/${conversationID}` ); }
    // ------ CONVERSATION END ------


    // ------ MESSAGE START ------
    static async get_messages( request )   { return await API('app','POST', '/v1/messages', request ); }
    static async create_message( request ) { return await API('app','PUT', '/v1/message', request ); }
    // ------ MESSAGE END ------


    // ------ LIVECHAT CONVERSATION START ------
    static async get_livechat_active_admins()                             { return await API('app','GET', '/v1/chat/active-admins' ); }

    static async get_livechat_conversations( request )                     { return await API('app','POST', '/v1/chat/threads', request ); }

    static async create_livechat_conversation()                            { return await API('app','PUT', '/v1/chat/thread' ); }
    static async join_livechat_conversation( threadID )                    { return await API('app','POST', `/v1/chat/thread/${threadID}/join` ); }
    static async solve_livechat_conversation( threadID )                   { return await API('app','POST', `/v1/chat/thread/${threadID}/solved` ); }

    static async unread_livechat_conversations()                           { return await API('app','GET', '/v1/chat/thread/unread' ); }
    static async seen_livechat_conversation( conversationID )              { return await API('app','POST', `/v1/chat/thread/${conversationID}/seen` ); }
    //static async update_livechat_conversation( conversationID, request ) { return await API('app','PATCH', `/v1/conversation/${conversationID}`, request ); }

    //static async delete_livechat_conversation( conversationID )          { return await API('app','DELETE', `/v1/conversation/${conversationID}` ); }
    // ------ LIVECHAT CONVERSATION END ------


    // ------ LIVECHAT MESSAGE START ------
    static async get_livechat_messages( request )   { return await API('app','POST', '/v1/chat/messages', request ); }
    static async create_livechat_message( request ) { return await API('app','PUT', '/v1/chat/message', request ); }
    // ------ LIVECHAT MESSAGE END ------


    // ------ TASK START ------
    static async get_tasks( request ) { return await API('app','GET', '/v1/tasks', request ); }
    // ------ TASK END ------


    // ------ OPENAI START ------
    static async OpenAI_time_to_hire( request )             { return await API('app','POST', '/v1/analytics/application/time-to-hire-histogram', request ); }
    static async OpenAI_worldwide_suppliers( request )      { return await API('app','POST', '/v1/analytics/agency/map', request ); }

    static async OpenAI_potentional_candidates( query )     { return await API('app','GET', '/v1/openai/potential-candidates', { query }); }
    static async OpenAI_salary_distribution( query )        { return await API('app','GET', '/v1/openai/salary-distribution', { query }); }
    static async OpenAI_ai_jobs( request )                  { return await API('app','POST', '/v1/openai/job-ai', request ); }
    static async openAI_chat_history( request )             { return await API('app','POST', '/v1/openai/chat-history', request ); }
    static async openAI_chat_historyDetail( conversationID ){ return await API('app','GET', `/v1/openai/chat-history/${conversationID}` ); }
    // ------ OPENAI END ------


    // ------ BASE START ------
    static async select_options( type, request ) { return await API('app', 'GET', `/v1/select-options/${ type.charAt(0).toLowerCase() + type.slice(1) }`, request ); }
    static async get_upload_url( query )         { return await API('app','GET', '/v1/uploadURL', { query }); }
    static async get_upload_logo_url( query )    { return await API('app','GET', '/v1/uploadLogoURL', { query }); }
    // ------ BASE END ------


    // ------ BASE START ------
    static async get_notification( notificationID )                        { return await API('app','GET', `/v1/notification/${notificationID}`); }
    static async get_notifications( request )                              { return await API('app','POST', '/v1/notifications', request ); }
    static async seen_notifications( request )                             { return await API('app','POST', '/v1/notifications/seen', request ); }
    static async open_notifications( request )                             { return await API('app','PATCH', `/v1/notifications/opened`, request ); }
    static async open_notification( notificationID, request )              { return await API('app','PATCH', `/v1/notification/${notificationID}/opened`, request ); }
    static async unseen_notifications( request )                           { return await API('app','GET', '/v1/notifications/unseen', request ); }

    static async get_notes( request )                                      { return await API('app','POST', '/v1/notes', request ); }

    static async create_note( request )                                    { return await API('app','PUT', '/v1/note', request ); }
    static async update_note( noteID, request )                            { return await API('app','PATCH', `/v1/note/${noteID}`, request ); }
    static async delete_note( noteID )                                     { return await API('app','DELETE', `/v1/note/${noteID}` ); }

    static async get_agency_onboarding( accountID )                        { return await API('app','GET', `/v1/account/specialisms-renewal/${accountID}` ); }
    static async update_agency_onboarding( accountID, request )            { return await API('app','PUT', `/v1/account/specialisms-renewal/${accountID}`, request ); }

    static async get_custom_fields( organizationID, programmeID )          { return await API('app','GET', `/v1/organization/${organizationID}/customFields/${programmeID}` ); }
    static async get_list_custom_fields( organizationID )                  { return await API('app','GET', `/v1/organization/${organizationID}/customFields` ); }
    // ------ BASE END ------

    /* ------------------------------ SWAGGER APP API SERVICE END ------------------------------ */





    /* ------------------------------ SWAGGER IAM API SERVICE START ------------------------------ */


    // ------ LOGIN START ------
    static async login()                  { return location.href = import.meta.env.VITE_APP_AUTH_URL + 'login'; }
    static async loginAs( accountID )     { return await API('iam', 'POST', `/v1/account/${accountID}/loginAs` ); }
    static async loginWithToken( token )  { return await API('iam', 'POST', '/auth/login/token', { body: token } ); }
    static async logout()                 { return await API('iam', 'POST', '/auth/logout' ).then( r => true ).catch( e => false ); }
    // ------ LOGIN END ------


    // ------ ACCOUNT START ------
    static async iam_get_account( accountID )                      { return await API('iam', 'GET', `/v1/account/${accountID}` ); }
    static async iam_update_account( accountID, request )          { return await API('iam', 'PATCH', `/v1/account/${accountID}`, request ); }
    //static async iam_update_account( accountID, request )          { return await API('iam', 'PATCH', `/v1/account/${accountID}/updateProfile`, request ); }
    static async iam_update_account_password( request )            { return await API('iam', 'POST', `/v1/account/changePassword`, request ); }
    static async iam_set_new_account_password( request )           { return await API('iam', 'POST', `/v1/account/setNewPassword`, request ); }
    // ------ ACCOUNT END ------


    // ------ ACCOUNT INVITATIONS START ------
    static async iam_get_my_invitations( request )                      { return await API('iam', 'GET', `/v1/my-invitations` ); }
    static async iam_get_account_invitations( request )                 { return await API('iam', 'POST', `/v1/accountInvitations`, request ); }

    static async iam_create_account_invitation( request )               { return await API('iam', 'PUT', `/v1/accountInvitation`, request ); }

    static async iam_update_account_invitation( invitationID, request ) { return await API('iam', 'PATCH', `/v1/accountInvitation/${invitationID}/changeStatus`, request ); }
    // ------ ACCOUNT INVITATIONS END ------


    // ------ ACCOUNT ROLE START ------
    static async iam_get_account_role( accountID, roleID )             { return await API('iam', 'GET', `/v1/account/${accountID}/role/${roleID}` ); }
    static async iam_get_account_roles( accountID, request )           { return await API('iam', 'POST', `/v1/account/${accountID}/roles`, request ); }

    static async iam_create_account_role( accountID, request )         { return await API('iam', 'PUT', `v1/account/${accountID}/role`, request ); }

    static async iam_update_account_role( accountID, roleID, request ) { return await API('iam','PATCH', `/v1/account/${accountID}/role/${roleID}`, request ); }

    static async iam_delete_account_role( accountID, roleID )          { return await API('iam', 'DELETE', `/v1/account/${accountID}/role/${roleID}` ); }
    // ------ ACCOUNT ROLE END ------


    // ------ APPLICATION START ------
    static async iam_get_application( appID )              { return await API('iam', 'GET', `/v1/application/${appID}` ); }
    static async iam_get_applications( request )           { return await API('iam', 'POST', '/v1/applications', request ); }

    static async iam_create_application( request )         { return await API('iam','PUT', '/v1/application', request ); }

    static async iam_update_application( appID, request )  { return await API('iam','PATCH', `/v1/application/${appID}`, request ); }

    static async iam_delete_application( appID )           { return await API('iam','DELETE', `/v1/application/${appID}` ); } //NOT IMPLEMENTED ON BACKEND
    // ------ APPLICATION END ------


    // ------ ORGANIZATION START ------
    static async iam_get_organization( organizationID )             { return await API('iam', 'GET', `/v1/organization/${organizationID}` ); }
    static async iam_get_organization_hierarchy()                   { return await API('iam', 'GET', '/v1/organization/hierarchy' ); }
    static async iam_get_organization_permissions()                 { return await API('iam','GET', '/v1/organization/permissions' ); }
    static async iam_get_organizations( request )                   { return await API('iam','POST', '/v1/organizations', request ); }

    static async iam_create_organization( request )                 { return await API('iam', 'PUT', '/v1/organization', request ); }

    static async iam_update_organization( organizationID, request ) { return await API('iam','PATCH', `/v1/organization/${organizationID}`, request ); }

    static async iam_delete_organization( organizationID )          { return await API('iam', 'DELETE', `/v1/organization/${organizationID}` ); }
    // ------ ORGANIZATION END ------


    // ------ ORGANIZATION ROLE START ------
    static async iam_get_organization_role( roleID )             { return await API('iam', 'GET', `/v1/organization/role/${roleID}` ); }
    static async iam_get_organization_roles( request )           { return await API('iam', 'POST', '/v1/organization/roles', request ); }

    static async iam_create_organization_role( request )         { return await API('iam', 'PUT', '/v1/organization/role', request ); }

    static async iam_update_organization_role( roleID, request ) { return await API('iam', 'PATCH', `/v1/organization/role/${roleID}`, request ); }

    static async iam_delete_organization_role( roleID )          { return await API('iam','DELETE', `/v1/organization/role/${roleID}` ); }
    // ------ ORGANIZATION ROLE END ------


    // ------ API START ------
    static async iam_get_api( apiID )             { return await API('iam', 'GET', `/v1/api/${apiID}` ); }
    static async iam_get_apis( request )          { return await API('iam', 'POST', '/v1/apis', request ); }

    static async iam_create_api( request )        { return await API('iam', 'PUT', '/v1/api', request ); }

    static async iam_update_api( apiID, request ) { return await API('iam', 'PATCH', `/v1/api/${apiID}`, request ); }
    // ------ API END ------


    // ------ BASE START ------
    static async iam_select_options( type, request ) { return await API('iam', 'GET', `/v1/select-options/${ type.charAt(0).toLowerCase() + type.slice(1) }`, request ); }

    static async submit_feedback( request )          { return await API('tracker', 'POST', '/feedback', request ); }
    // ------ BASE END ------



    /* ------------------------------ SWAGGER IAM API SERVICE END ------------------------------ */
}
