import Time from "@ramp-global/time";
import DOMPurify from 'dompurify';

export const capitalize = String.prototype.capitalize = function()
{
    return this.substr(0, 1).toUpperCase() + this.substring(1).toLowerCase();
}

export const sanitize = String.prototype.sanitize = function()
{
    return DOMPurify.sanitize( this );
}

export const sanitizeURL = String.prototype.sanitizeURL = function()
{
    const safeProtocols = ['http:', 'https:', 'mailto:', 'tel:'];

    try
    {
        let parsed = new URL( this );

        return safeProtocols.includes( parsed.protocol ) ? parsed.href : window.location.href;
    }
    catch( e )
    {
        return window.location.href;
    }
}

export const toUTCDate = Date.prototype.toUTCDate = function()
{
    return this.getUTCFullYear() + '-' + ('0'+ (this.getUTCMonth()+1)).slice(-2) + '-' + ('0'+ this.getUTCDate()).slice(-2);
}

export class DateTime
{
    static _nonLeapLadder = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
    static _leapLadder = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335];

    static _isLeapYear( year )
    {
        return year % 4 === 0 && ( year % 100 !== 0 || year % 400 === 0 );
    }

    static _weeksInWeekYear( weekYear )
    {
        const p1 =
                (weekYear +
                    Math.floor( weekYear / 4 ) -
                    Math.floor( weekYear / 100 ) +
                    Math.floor( weekYear / 400 ) ) %
                7,
            last = weekYear - 1,
            p2 = ( last + Math.floor( last / 4 ) - Math.floor( last / 100 ) + Math.floor( last / 400 ) ) % 7;

        return ( p1 === 4 || p2 === 3 ? 53 : 52 );
    }

    static _dayOfWeek(year, month, day)
    {
        const d = new Date( Date.UTC( year, month - 1, day ) );

        if( year < 100 && year >= 0 )
        {
            d.setUTCFullYear( d.getUTCFullYear() - 1900 );
        }

        const js = d.getUTCDay();

        return js === 0 ? 7 : js;
    }

    static _computeOrdinal(year, month, day)
    {
        return day + ( this._isLeapYear( year ) ? this._leapLadder : this._nonLeapLadder )[ month - 1 ];
    }

    static weekNumber( year, month, day )
    {
        const ordinal = this._computeOrdinal( year, month, day ),
            weekday = this._dayOfWeek( year, month, day );
        let weekNumber = Math.floor( ( ordinal - weekday + 10 ) / 7 ),
            weekYear;

        if( weekNumber < 1 )
        {
            weekYear = year - 1;
            weekNumber = this._weeksInWeekYear( weekYear );
        }
        else if ( weekNumber > this._weeksInWeekYear( year ) )
        {
            weekYear = year + 1;
            weekNumber = 1;
        }
        else
        {
            weekYear = year;
        }

        return weekNumber;
    }

    static getDateFromPeriod( timePeriod )
    {
        const time = new Time( new Date() );
        const endTime = new Time( new Date() );

        time.time.setHours( 0, 0, 0, 0 );
        endTime.time.setHours( 23, 59, 59, 999 );

        if( timePeriod === 'quarter' )
        {
            return { from : time.startOf( 'quarter' ), to : endTime.endOf( 'quarter' ).offset( { ms: -1 } ) }
        }
        else if( timePeriod === 'lastQuarter' )
        {
            const quarter = time.startOf( 'quarter' ).offset( { M: -1 } );
            return { from : quarter.startOf('quarter'), to : quarter.endOf( 'quarter' ).offset( { ms: -1 } ) };
        }
        else if( timePeriod === 'yearToDate' )
        {
            return { from : time.startOf( 'year' ), to : endTime };
        }
        else if( timePeriod === 'lastYear' )
        {
            const year = time.startOf( 'year' ).offset( { M: -1 } );
            return { from : year.startOf('year'), to : year.endOf( 'year' ).offset( { ms: -1 } ) };
        }
        else if( timePeriod === '3months' )
        {
            return { from : time.offset( { D : -90 } ), to : endTime };
        }
        else if( timePeriod === '6months' )
        {
            return { from : time.offset( { D : -180 } ), to : endTime };
        }
        else if( timePeriod === '12months' )
        {
            return { from : time.offset( { D : -360 } ), to : endTime };
        }
        else if( timePeriod === 'week' )
        {
            const endWeek = time.endOf('week');
            return { from : time.startOf('week'), to : endWeek.offset( { ms: -1 } ) };
        }
        else
        {
            return { from : time.offset( { D : -90 } ), to : endTime };
        }
    }
}

export class Format
{
    /**
     * Format bytes as human-readable text.
     *
     * @param bytes Number of bytes.
     * @param si True to use metric (SI) units, aka powers of 1000. False to use
     *           binary (IEC), aka powers of 1024.
     * @param dp Number of decimal places to display.
     *
     * @return Formatted string.
     */

    static fileSize( bytes, si = false, dp = 1 )
    {
        const thresh = si ? 1000 : 1024;

        if( Math.abs( bytes ) < thresh )
        {
            return bytes + ' B';
        }

        const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

        let u   = -1;
        const r = 10 ** dp;

        do
        {
            bytes /= thresh; ++u;
        }
        while( Math.round(Math.abs( bytes ) * r ) / r >= thresh && u < units.length - 1 );


        return bytes.toFixed( dp ) + ' ' + units[u];
    }

    static number( number, format )
    {
        return Intl.NumberFormat( format || 'en-GB' ).format( number );
    }

    static url( url )
    {
        return url?.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/g)?.[0]?.replace(/https?:\/\//g,'')?.replace('www.','')?.toLowerCase();
    }

    static currency( value, currency, digits = 2 )
    {
        let language = 'en-GB'; //( typeof navigator !== 'undefined' ?  navigator.language : 'en-GB' );

        if( currency?.length > 3 )
        {
            currency = currency.substring( 0, 3 );
        }

        // Convert the value to a string to check if it has a fractional part
        const [integerPart, fractionalPart] = value.toString().split('.');

        // If the fractional part exists and is non-zero, use the provided digits, else strip it (set maxFractionDigits to 0)
        const maxDigits = fractionalPart && parseInt( fractionalPart ) > 0 ? digits : 0;

        if( currency )
        {
            return new Intl.NumberFormat( language, { style: 'currency', currency: currency, maximumFractionDigits: maxDigits }).format( value );
        }
        else
        {
            return new Intl.NumberFormat( language, { minimumFractionDigits: 0, maximumFractionDigits: maxDigits }).format( value );
        }
    }

    static percent( value, options )
    {
        return new Intl.NumberFormat( 'en-GB', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2, ...options }).format( value );
    }

    static stringToDate( _date, _format, _delimiter )
    {
        if( !_date )
        {
            return null;

            //_date = this.dateToString( new Date( Date.now() ) );
        }

        var formatLowerCase = _format.toLowerCase();

        var formatItems = formatLowerCase.split( _delimiter ),
            dateItems   = _date.split( _delimiter );

        var monthIndex = formatItems.indexOf("mm"),
            dayIndex   = formatItems.indexOf("dd"),
            yearIndex  = formatItems.indexOf("yyyy");

        var month = parseInt( dateItems[ monthIndex ] );

        month-=1;

        var formatedDate = new Date( dateItems[ yearIndex ], month, dateItems[ dayIndex ] );

        return formatedDate;
    }

    static dateToString( date )
    {
        if( typeof date === 'string' )
        {
            date = new Date( date );
        }

        return date.toLocaleString( 'en-GB', { year: 'numeric', month: 'numeric', day: 'numeric' } );
    }

    static dateToUserString( date, options = {} )
    {
        const pad = v => v.toString().padStart( 2, '0' );

        const timezoneOffset = date.getTimezoneOffset();

        if( options.hasOwnProperty('format') )
        {
            if( options.format === 'yyyy-mm-dd' )
            {
                return date.getFullYear() + '-' + pad( date.getMonth() + 1 ) + '-' + pad( date.getDate() );
            }
        }
        else
        {
            return date.getFullYear() + '-' + pad( date.getMonth() + 1 ) + '-' + pad( date.getDate() ) + 'T' + pad( date.getHours() ) + ':' + pad( date.getMinutes() ) + ':' + pad( date.getSeconds() ) + '.' + date.getMilliseconds() + (timezoneOffset > 0 ? '-' : '+') + pad( Math.floor( Math.abs(timezoneOffset) / 60 ) ) + pad( Math.abs(timezoneOffset) % 60 );
        }
    }

    static relativeDate( value, options = {} )
    {
        if( !value || typeof value === 'undefined' || value === '' ){ return ''; }

        if( !Object.keys( options ).length )
        {
            options =
            {
                localeMatcher : "best fit", // other values: "lookup"
                numeric       : "always", // other values: "auto"
                style         : "long", // other values: "short" or "narrow"
            }
        }

        const dateToTime = ( timestamp ) =>
        {
            return timestamp;

            /*
            var time_split = timestamp.split(/[^0-9]/);
            return ( new Date (time_split[0], time_split[1]-1, time_split[2], ( typeof time_split[3] != 'undefined' ? time_split[3] : '' ), ( typeof time_split[4] != 'undefined' ? time_split[4] : '' ), ( typeof time_split[5] != 'undefined' ? time_split[5] : '' ), ( typeof time_split[6] != 'undefined' ? time_split[6] : '' ) ) ).getTime();
            */
        }

        value = ( typeof value == 'string' ? dateToTime( value ) : (  value.toString().length >= 13 ? value : ( value * 1000 ) ) );

        const timestamp = new Date( value );
        const language = ( typeof navigator !== 'undefined' ?  navigator.language : 'en-GB' );

        const now = Date.now();

        var diff = Math.round(Math.abs(now - timestamp.getTime() ) / 1000 );

        var days    = Math.floor(diff/(24*60*60));  diff -= days * 24*60*60;
        var hours   = Math.floor(diff/(60*60));     diff -= hours * 60*60;
        var minutes = Math.floor(diff/(60));        diff -= minutes * 60;
        var seconds = diff;
        var weeks   = Math.floor( days / 7 );

        let multiplier = 1;

        if( now > timestamp.getTime() )
        {
            multiplier = -1;
        }

        if( weeks > 0 )         { return new Intl.RelativeTimeFormat( language, { timeZone: 'Europe/London', ...options } ).format( weeks * multiplier, 'week' ); }
        else if( days > 0 )     { return new Intl.RelativeTimeFormat( language, { timeZone: 'Europe/London', ...options } ).format( days * multiplier, 'day' ); }
        else if( hours > 0 )    { return new Intl.RelativeTimeFormat( language, { timeZone: 'Europe/London', ...options } ).format( hours * multiplier, 'hour' ); }
        else if( minutes > 0 )  { return new Intl.RelativeTimeFormat( language, { timeZone: 'Europe/London', ...options } ).format( minutes * multiplier, 'minute' ); }
        else if( seconds > 0 )  { return new Intl.RelativeTimeFormat( language, { timeZone: 'Europe/London', ...options } ).format( seconds * multiplier, 'second' ); }
    }

    static date( value, options )
    {
        const isDay = typeof value == 'string' ? value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/) : false;

        if( !value || typeof value === 'undefined' || value === '' ){ return ''; }

        const dateToTime = ( timestamp ) =>
        {
            return timestamp;

            /* TODO MOZNO DAKEDY BUDE TREBA ANGLICKY CAS

            var time_split = timestamp.split(/[^0-9]/);
            return ( new Date (time_split[0], time_split[1]-1, time_split[2], ( typeof time_split[3] != 'undefined' ? time_split[3] : '' ), ( typeof time_split[4] != 'undefined' ? time_split[4] : '' ), ( typeof time_split[5] != 'undefined' ? time_split[5] : '' ), ( typeof time_split[6] != 'undefined' ? time_split[6] : '' ) ) ).getTime();
            */
        }

        value = ( typeof value == 'string' ? dateToTime( value ) : (  value.toString().length >= 13 ? value : ( value * 1000 ) ) );

        const timestamp = new Date( value );
        const language = ( typeof navigator !== 'undefined' ?  navigator.language : 'en-GB' );

        return options ? new Intl.DateTimeFormat( language, { timeZone: !isDay ? 'Europe/London' : undefined, ...options }).format( timestamp ) : timestamp;
    }
}

export class Helpers
{
    static Storage( type, method, key, value = undefined )
    {
        let $_prefix = '', $_value;

        if( !['X-UserID', 'X-AccountID', 'X-OrganizationID'].includes( key ) )
        {
            let $_accountID = sessionStorage.getItem('X-AccountID') || localStorage.getItem('X-AccountID');

            if( $_accountID )
            {
                $_prefix += $_accountID + '_';
            }

            if( key !== 'permission' )
            {
                let $_permission = sessionStorage.getItem( $_prefix + 'permission' ) || localStorage.getItem( $_prefix + 'permission' ) || 'admin';

                if( $_permission )
                {
                    $_prefix += $_permission + '_';
                }
            }
        }

        if( !type || type === 'session' )
        {
            if( method === 'set' )
            {
                sessionStorage.setItem(`${$_prefix}${key}`, value);
            }
            else if( method === 'get' )
            {
                $_value = sessionStorage.getItem(`${$_prefix}${key}`);
            }
            else if( method === 'remove' )
            {
                sessionStorage.removeItem(`${$_prefix}${key}`);
            }
            else if( method === 'clear' )
            {
                const keepKeys = ["redirectAfterAccountSelected"], backup = {};

                keepKeys.forEach( ( key ) => { backup[key] = sessionStorage.getItem( key ); });

                sessionStorage.clear();

                Object.keys( backup ).forEach( ( key ) =>
                {
                    if( backup[key] !== null ) { sessionStorage.setItem( key, backup[key] ); }
                });
            }
        }

        if( !type || type === 'local' )
        {
            if( method === 'set' )
            {
                localStorage.setItem(`${$_prefix}${key}`, value);
            }
            else if( method === 'get' && !$_value )
            {
                $_value = localStorage.getItem(`${$_prefix}${key}`);
            }
            else if( method === 'remove' )
            {
                localStorage.removeItem(`${$_prefix}${key}`);
            }
            else if( method === 'clear' )
            {
                const keepKeys = ["redirectAfterAccountSelected"], backup = {};

                keepKeys.forEach( ( key ) => { backup[key] = localStorage.getItem( key ); });

                localStorage.clear();

                Object.keys( backup ).forEach( ( key ) =>
                {
                    if( backup[key] !== null ) { localStorage.setItem( key, backup[key] ); }
                });
            }
        }

        return $_value || value;
    }

    static Sleep( ms )
    {
        return new Promise( resolve => setTimeout( resolve, ms ) );
    }

    static toArray( item )
    {
        return ( Array.isArray( item ) ? item : [ item ] ).filter( i => i !== undefined && i !== null && i !== '' );
    }

    static ArrayPush( defaultArr, arrToPush, cursor )
    {
        if( cursor === 'prev' )
        {
            arrToPush = [ ...arrToPush, ...defaultArr ];
        }
        else
        {
            arrToPush = [ ...defaultArr, ...arrToPush ];
        }

        return arrToPush;
    }

    static ObjectToArray( obj )
    {
        return Object.values( obj );
    }

    static ArrayToObject( arr )
    {
        let $_obj = {};

        for( let [ key, item ] of arr.entries() ) { $_obj[key] = item }

        return $_obj;
    }

    static ObjectClear( obj )
    {
        for( let key of Object.keys( obj ) )
        {
            delete obj[key];
        }

        return obj;
    }

    static ObjectClean( obj )
    {
        if (typeof obj !== "object" || obj === null) return obj; // Return if not an object

        Object.keys( obj ).forEach( key =>
        {
            const value = obj[key];

            if( value === undefined || value === null || ( typeof value === "string" && value.length === 0 ) )
            {
                delete obj[key];
            }
            else if (typeof value === "object")
            {
                this.ObjectClean( value );

                if( Object.keys(value).length === 0 )
                {
                    delete obj[key];
                }
            }
        });

        return obj;
    }

    static ObjectGet( obj, path )
    {
        if( obj && path )
        {
            let keys = path.split('.');

            return keys.length === 1 ? obj[keys[0]] : this.ObjectGet( obj[keys[0]], keys.slice(1).join('.') );
        }
    }

    static ObjectSet( obj, path, value )
    {
        let keys = path.split('.');

        if( keys.length === 1 ) { obj[keys[0]] = value; return true; }

        if( !obj[keys[0]] ){ obj[keys[0]] = {} }

        return this.ObjectSet( obj[keys[0]], keys.slice(1).join('.'), value );
    }

    static ObjectMerge( target, ...sources ) // NEW
    {
        if( !sources.length ){ return target; }

        const isObject = ( item ) =>
        {
            return (item && typeof item === 'object' && !Array.isArray(item));
        }

        const source = sources.shift();

        if( isObject( target ) && isObject( source ) )
        {
            for( const key in source )
            {
                if( isObject( source[ key ] ) )
                {
                    if( !target[ key ] )
                    {
                        Object.assign( target, { [ key ] : {} } );
                    }

                    this.ObjectMerge( target[ key ], source[ key ] );
                }
                else
                {
                    Object.assign( target, { [ key ]: source[ key ] });
                }
            }
        }

        return this.ObjectMerge( target, ...sources );
    }

    static ArrayDiff( arr1, arr2 )
    {
        const diff = [];

        for( const item of arr1 )
        {
            if( !arr2.includes( item ) )
            {
                diff.push(item);
            }
        }

        for( const item of arr2 )
        {
            if( !arr1.includes( item ) && !diff.includes( item ) )
            {
                diff.push( item );
            }
        }

        return diff;
    }

    static ObjectDiff( a, b )
    {
        a = JSON.parse( JSON.stringify( a ) );
        b = JSON.parse( JSON.stringify( b ) );

        let diff = {};

        for( let key in a )
        {
            if( a.hasOwnProperty( key ) && b.hasOwnProperty( key ) )
            {
                if( typeof a[key] === "string" && typeof b[key] === "string" /*&& a[key].length > 0 && b[key].length > 0*/ ) //TODO NEVIEM CI TO MA BYT CI NE
                {
                    if( a[key] !== b[key] )
                    {
                        diff[key] = a[key]; //MUCHO IMPORTANTE => if different then use the old value
                    }
                }
                else if( typeof a[key] === "number" && typeof b[key] === "number" )
                {
                    if( a[key] !== b[key] )
                    {
                        diff[key] = a[key]; //MUCHO IMPORTANTE => if different then use the old value
                    }
                }
                else if( typeof a[key] === "boolean" && typeof b[key] === "boolean" )
                {
                    if( a[key] !== b[key] )
                    {
                        diff[key] = a[key]; //MUCHO IMPORTANTE => if different then use the old value
                    }
                }
                else if( Array.isArray( a[key] ) && Array.isArray( b[key] ) )
                {
                    let arrayDiff = this.ArrayDiff( a[key], b[key] );

                    if( arrayDiff.length > 0 )
                    {
                        diff[key] = a[key]; //MUCHO IMPORTANTE => if different then use the old value => TODO SORT FILTER SHOULD BE SPLITTED BY _ AND THEN COMPARED
                    }
                }
            }
            else
            {
                diff[key] = a[key];
            }
        }

        for( let key in b )
        {
            if( b.hasOwnProperty(key) && !a.hasOwnProperty(key) )
            {
                diff[key] = b[key];
            }
        }

        return diff;
    }

    static RAG( value, max, min = 0, colors = [ '#B71C1C', '#F9A825', '#31A24B'/*'#2E7D32'*/ ] )
    {
        if( isNaN( value ) ) { value = min; }
        if( isNaN( max ) )   { max = min; }

        if( value < min ){ value = min }
        if( value > max ){ value = max }

        if( min === 1 && max === 1 ){ return colors[0] }

        const rgb   = colors.map( c => [ parseInt( c.substring(1,3), 16 ), parseInt( c.substring(3,5), 16 ), parseInt( c.substring(5,7), 16 )]);
        let index = ( value - min ) / ( max - min ) * ( colors.length - 1 );
        
        if( isNaN( index ) ){ index = 0; }

        if( Math.round( index ) === index ){ return 'rgb('+rgb[index].join()+')' }

        let i = Math.floor(index), a = index - i, b = Math.ceil(index) - index;
        return 'rgb('+[0,1,2].map( v => Math.round(rgb[i][v]*b+rgb[i+1][v]*a) ).join()+')';
    }

    static stringToColour( str )
    {
        if( !str || str === 'All Team Members' ){ return '#000000' }

        var hash = 0;

        for( var i = 0; i < str.length; i++ )
        {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        var colour = '#';

        for( var i = 0; i < 3; i++ )
        {
            var value = ( hash >> ( i * 8 ) ) & 0xFF;

            colour += ('00' + value.toString(16)).substr(-2);
        }

        return colour;
    }

    static containsWords = ( haystack, words ) =>
    {
        words    = words.trim().toLowerCase();
        haystack = haystack.trim().toLowerCase();

        if( words.length )
        {
            if( typeof words == 'string' ){ words = words.split(/\s+/g); }

            for( var i = 0; i < words.length; ++i )
            {
                var position = -1, found = false;

                while( ( position = haystack.indexOf( words[i], position + 1 ) ) !== -1 )
                {
                    if( position != 0 && ' \t\n'.indexOf(haystack[position-1]) === -1 )
                    {
                        continue;
                    }
                    else if( i < words.length - 1 && haystack.length > words[i].length + position && ' \t\n'.indexOf(haystack[position+words[i].length]) === -1 )
                    {
                        continue;
                    }
                    else
                    {
                        found = true; break;
                    }
                }

                if( !found ){ return false; }
            }
        }

        return true;
    }

    static equals( a, b, precision = 3 )
    {
        return Math.abs( a - b ) /* / ( Math.max( Math.abs( a ), Math.abs( b )) ) */ < Math.pow( 10, -precision );
    }

    static getInitials( text )
    {
        if( !text ){ return '-'; }

        const words = text.trim().split(' ').filter( Boolean );

        const initials = words.map( word => word[0].toUpperCase() );

        return initials.slice(0, 2).join('');
    }
}

export class ObjectLib
{
    static isFunction( obj )
    {
        return Object.prototype.toString.call( obj ) === '[object Function]';
    }

    static isPlainObject( obj )
    {
        return Object.prototype.toString.call( obj ) === '[object Object]';
    }

    static isArray( obj )
    {
        return Object.prototype.toString.call( obj ) === '[object Array]';
    }

    static clone()
    {
        var src, copyIsArray, copy, name, options, clone, target = arguments[0] || {}, i = 1, length = arguments.length, deep = false;

        if( typeof target === "boolean" )
        {
            deep = target;

            target = arguments[1] || {};

            i = 2;
        }

        if( typeof target !== "object" && !this.isFunction( target ) )
        {
            target = {};
        }

        if( length === i )
        {
            target = this;

            --i;
        }

        for( ; i < length; i++ )
        {
            if( ( options = arguments[ i ] ) != null )
            {
                for( name in options )
                {
                    src  = target[ name ];
                    copy = options[ name ];

                    if( target === copy )
                    {
                        continue;
                    }

                    if( deep && copy && ( this.isPlainObject( copy ) || ( copyIsArray = this.isArray( copy ) ) ) )
                    {
                        if( copyIsArray )
                        {
                            copyIsArray = false;

                            clone = src && this.isArray(src) ? src : [];
                        }
                        else
                        {
                            clone = src && this.isPlainObject(src) ? src : {};
                        }

                        target[ name ] = this.clone( deep, clone, copy );
                    }
                    else if( copy !== undefined )
                    {
                        target[ name ] = copy;
                    }
                }
            }
        }

        return target;
    }

    static mergeByKey( target_raw, key_raw, sources_raw, clone = true )
    {
        return ObjectLib.merge( target_raw, { [key_raw] : sources_raw }, clone );
    }

    static merge( target_raw, sources_raw, clone = true )
    {
        var target  = ( clone ? this.clone( true, {}, target_raw ) : target_raw ),
            sources = ( clone ? this.clone( true, {}, sources_raw ) : sources_raw );

        var len = arguments.length;
        var idx = 0;

        while( ++idx < len )
        {
            var source = arguments[idx];

            if( this.isPlainObject( source ) || this.isArray( source ) )
            {
                this.#deepMerge( target, source );
            }
        }

        return target;
    }

    static #deepMerge = ( target, value ) =>
    {
        for( var key in value )
        {
            if( key === '__proto__' || !value.hasOwnProperty( key ) ) { continue; }

            var oldVal = value[key];
            var newVal = target[key];

            if( this.isPlainObject( newVal ) && this.isPlainObject( oldVal ) )
            {
                target[ key ] = this.#deepMerge( newVal, oldVal );
            }
            else if( this.isArray( newVal ) )
            {
                target[ key ] = oldVal; // ?? merge array oldVal, newVal ??
            }
            else
            {
                target[ key ] = oldVal;
            }
        }

        return target;
    }
}