import SwaggerService from "@/services/swagger.service";

import { Helpers } from "@/core/utils";
import { Filter }  from "@/core/filter";

const serviceRoute =
{
    role: 'iam'
}

const nameTransform =
{
    role           : 'AccountRole',

    countries           : 'location',
    areas               : 'location',
    economicAreas       : 'location',
    administrativeAreas : 'location',
    anyArea             : 'location',

    country        : 'location',

    employer       : 'account',
    recruiter      : 'account',
    accountManager : 'account',
    messageAccount : 'account',

    employerOrganization: 'organization',

    //custom  : 'CustomSuggest' => NEVIEM CO TO BOLO
};

const requestFilters =
{
    country         : { include: { type: ['country'] } },

    countries           : { include: { precision: 'countries' } },          // JE MOZNY AJ RESOLVE BEZ QUERY
    areas               : { include: { precision: 'areas' } },              // JE MOZNY AJ RESOLVE BEZ QUERY
    economicAreas       : { include: { precision: 'economic-areas' } },     // JE MOZNY AJ RESOLVE BEZ QUERY
    administrativeAreas : { include: { precision: 'administrative-areas' } },
    anyArea             : { include: { precision: 'any' } },

    employer        : { include: { type: ['employer'] } },
    recruiter       : { include: { type: ['recruiter'] } },
    accountManager  : { include: { type: ['admin'] } },
    messageAccount  : { include: { type: ['admin', 'recruiter', 'employer'] } },

    employerOrganization: { include: { type: ['employer'] } },
}

export default
{
    namespaced: true,
    state:
    {
        customFilter                    : { id: undefined, fields: [], data: undefined },

        //personOPT                       : [], TODO ASI ZMAZAT

        programmeOPT                    : [],
        programmeLocationOPT            : [],
        programmeTypeOPT                : [],
        feeTypeOPT                      : [],
        originTypeOPT                   : [],
        payerTypeOPT                    : [],
        agencyFeeCategoryOPT            : [],
        recruiterFeeCategoryOPT         : [],
        bonusTypeOPT                    : [],
        tierReleaseTypeOPT              : [],
        nationalityOPT                  : [],
        engagementTypeOPT               : [],

        personTitleOPT                  : [],
        experienceOPT                   : [],
        categoryOPT                     : [],
        categoryHierarchyOPT            : [],
        skillOPT                        : [],
        tagOPT                          : [],

        organizationOPT                 : [],
        jobOPT                          : [],
        applicationOPT                  : [], // TODO
        placementOPT                    : [], // TODO
        contractOPT                     : [], // TODO

        accountOPT                      : [],
        accountManagerOPT               : [],
        employerOPT                     : [],
        recruiterOPT                    : [],

        jobStatusOPT                    : [],
        jobStageStatusOPT               : [],
        invitationStatusOPT             : [],
        applicationStatusOPT            : [],
        placementStatusOPT              : [],
        contractStatusOPT               : [],

        messageAccountOPT               : [],
        roleOPT                         : [],
        currencyOPT                     : [],
        contractTypeOPT                 : [],
        durationOPT                     : [],
        hoursRateOPT                    : [],
        timeRateOPT                     : [],
        mailTimeRateOPT                 : [],
        genderOPT                       : [],

        locationOPT                     : [],
        countryOPT                      : [],
        countryCodeOPT                  : [],

        countriesOPT                    : [],
        areasOPT                        : [],
        economicAreasOPT                : [],
        administrativeAreasOPT          : [],
        anyAreaOPT                      : [],

        languageOPT                     : [],

        industryOPT                     : [],
        organizationSizeOPT             : [],
        organizationPositionLevelOPT    : [],
        organizationPositionFunctionOPT : [],

        employerOrganizationOPT         : [],

        insightsJobsOPT                 : [],

        applicationRejectionReasonOPT   : [],
        applicationWithdrawalReasonOPT  : [],
        rejectionReasonOPT              : [],

        onHoldReasonOPT                 : [],
        closeReasonOPT                  : [],

        emailPreferencesOPT             : [],
    },
    mutations:
    {
        customFilter( state, { id, fields, data })
        {
            state.customFilter.id     = id;
            state.customFilter.fields = fields;
            state.customFilter.data   = data;

            for( let field of fields )
            {
                if( !state[field + 'OPT'] )
                {
                    state[field + 'OPT'] = []
                }
            }
        }
    },
    actions:
    {
        //THIS METHOD SHOULD RESOLVE ONLY STATIC OPTIONS => NO FILTER USED => RESOLVER FOR PRELOADING OPTIONS
        async resolve({ commit, dispatch, state }, options )
        {
            //console.log('SELECT SUGGEST RESOLVE =>', { options });

            let $_promises = [], $_result = {};

            for( let option of options )
            {
                $_promises.push( ( async () =>
                {
                    let isCustomFilter = state.customFilter.fields.includes( option );

                    let $_state = state[option + 'OPT'];

                    if( !$_state ){ throw { code: 404, message: `Type ${option} not found` } }

                    if( !$_state.length ) //TODO MOZNO NAPICU
                    {
                        let requestType = isCustomFilter ? 'custom' : ( nameTransform?.[option] || option );

                        let requestFilter = {};

                        if( requestFilters[option] )
                        {
                            requestFilter = { query: requestFilters[option].include };
                        }

                        if( requestType === 'custom' )
                        {
                            requestFilter = { query: { property: [ option ] } };
                        }

                        if( serviceRoute[option] )
                        {
                            try
                            {
                                for( let $_option of await SwaggerService.iam_select_options( requestType, requestFilter ) )
                                {
                                    if( !$_state.find( o => o.id === $_option.id ) )
                                    {
                                        $_state.push( $_option );
                                    }
                                }
                            }
                            catch( e )
                            {
                                console.warn( 'Failed to fetch: iam_select_options =>', e );
                            }
                        }
                        else
                        {
                            try
                            {
                                for( let $_option of await SwaggerService.select_options( requestType, requestFilter ) )
                                {
                                    if( !$_state.find( o => o.id === $_option.id ) )
                                    {
                                        $_state.push( $_option );
                                    }
                                }
                            }
                            catch( e )
                            {
                                console.warn( 'Failed to fetch: select_options =>', e );
                            }
                        }
                    }

                    $_result[option] = JSON.parse( JSON.stringify( $_state ) );

                })());
            }

            await Promise.all( $_promises );

            return $_result;
        },

        //THIS METHOD SHOULD RESOLVE DYNAMIC FILTERED OPTIONS => FILTER USED => RESOLVER FOR SEARCHING OPTIONS
        async options({ commit, dispatch, state }, { type, filter })
        {
            //console.log('SELECT SUGGEST OPTIONS =>', JSON.parse(JSON.stringify({ type, filter })));

            let isCustomFilter = state.customFilter.fields.includes( type );

            let requestType = isCustomFilter ? 'custom' : ( nameTransform?.[type] || type );

            let $_state = state[type + 'OPT'], $_options = [], $_fetchOptions;

            if( !$_state ){ throw { code: 404, message: `Type ${type} not found` } }

            if( $_state.length )
            {
                if( filter?.include?.ids )
                {
                    for( let id of filter.include.ids )
                    {
                        let $_option = $_state.find( item => item.id === id );

                        if( $_option )
                        {
                            filter.include.ids = filter.include.ids.filter( item => item !== id );

                            $_options.push( $_option );
                        }
                    }
                }
            }

            if( filter?.include )
            {
                if( filter?.include?.ids )
                {
                    filter.include.ids = filter.include.ids.filter( Boolean ).map( id => isNaN( id ) ? encodeURIComponent( id ) : id );
                }

                if( !filter?.include?.ids?.length )
                {
                    delete filter.include.ids;
                }
            }

            if( requestFilters[type] )
            {
                //Object.assign( filter?.include || {}, requestFilters[type].include );

                Helpers.ObjectMerge( filter?.include || {}, requestFilters[type].include );
            }

            //FETCH REQUEST NA OPTIONY IBA KED JE SEARCH QUERY ALEBO IDCKA KTORE NIESU V STORE
            if( filter?.forceFetch || filter?.include?.query?.length || filter?.include?.ids?.length || filter?.include?.parentID )
            {
                if( serviceRoute[ type ] )
                {
                    try
                    {
                        $_fetchOptions = await SwaggerService.iam_select_options( requestType, { query: filter?.include || {} });
                    }
                    catch( e )
                    {
                        console.warn( 'Failed to fetch: iam_select_options =>', e );
                    }
                }
                else
                {
                    try
                    {
                        $_fetchOptions = await SwaggerService.select_options( requestType, { query: filter?.include || {} });
                    }
                    catch( e )
                    {
                        console.warn( 'Failed to fetch: select_options =>', e );
                    }
                }

                if( $_fetchOptions )
                {
                    for( let option of $_fetchOptions )
                    {
                        ( !$_state.find( item => item.id === option.id ) )   && $_state.push( option );
                        ( !$_options.find( item => item.id === option.id ) ) && $_options.push( option );
                    }
                }
            }

            return JSON.parse( JSON.stringify( $_options ) );
        },

        async customFilter({ commit, dispatch, state }, { currentAccount })
        {
            try
            {
                let custom_filters = await SwaggerService.custom_filters();

                if( custom_filters.length )
                {
                    Filter.extendFilterObject( custom_filters );
                }

                commit( 'customFilter', { id: currentAccount.organization.id, fields: custom_filters.map( f => f.id ), data: custom_filters });
            }
            catch( e )
            {
                console.warn( 'Failed to fetch: customFilter =>', e );
            }
        }
    },
    getters:
    {
        customFilter( state )
        {
            return state.customFilter;
        }
    }
};
