import UserModule from "@/store/modules/auth_modules/user";

export default
{
    namespaced : true,
    state      : {},
    mutations  : {},
    actions    : {},
    getters    : {},
    modules    :
    {
        user: UserModule
    }
};